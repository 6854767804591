import React, { useEffect } from "react";
import { useState } from "react";
import $ from 'jquery';
import { Chart } from "react-google-charts";

import { countriesData } from './first-data'
import { countries, regionsData, regions, years } from './second-data'

export const option_line = {
    //title: 'Company Performance',//
    curveType: 'none',
    legend: {
        position: 'bottom',
        textStyle: {
            color: 'darkgray',
            fontSize: 16
        }
    },
    backgroundColor: {
        fill: 'transparent'
    },
    chartArea: {
        top: '5%',
        width: '80%',
        height: '80%'
    },

    hAxis: {
        format: '0000',
        gridlines: {
            color: 'transparent'
        },
        textStyle: {
            color: 'darkgray'
        },
    },

    vAxis: {
        minValue: '5',
        gridLines: [0, 1.25, 2.5, 3.75, 5],
        gridlines: {
            color: 'transparent'
        },
        textStyle: {
            color: 'darkgray',
            textalign: 'left',
        },
        ticks: [{
            v: 0,
            f: 'Very Low'
        }, {
            v: 1.25,
            f: 'Low'
        }, {
            v: 2.5,
            f: 'Medium'
        }, {
            v: 3.75,
            f: 'High'
        }, {
            v: 5,
            f: 'Very High'
        }],
    },
    lineWidth: 10,
    margin: '4',
    colors: ['red', 'green', 'blue'],
};
// console.log(option_line)

function Compare() {

    let idnVal = [];
    let compCtryVal = []
    let compRegVal = []
    let colors = ['lightgray', '#802000', '#ff9f80', '#ffff80', '#99e699', '#1e7b1e'];
    let scores = ["..", 0, 1.25, 2.5, 3.75, 5]
    let rankings = ['No Data', 'Very Low', 'Low', 'Medium', 'High', 'Very High']

    const [selectedOption, setSelectedOption] = useState("Select an Option");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [compCountry, setcompCountry] = useState("Afghanistan")
    const [compRegion, setcompRegion] = useState('World')
    const [indValue, setIndValue] = useState("SEPX")

    const [indonesiaData, setIndonesiadata] = useState()
    const [countryData, setCountrydata] = useState()
    const [regionData, setRegiondata] = useState()

    const [backgroundColor, setBackgroundColor] = useState()
    const [visualType, setVisualType] = useState('Table')
    const [linechartData, setLinechartData] = useState(
        [
            ['Year', 'Indonesia', compCountry, compRegion]
        ]
    )

    useEffect(() => {
        tableMaker();
    }, [compCountry, compRegion, visualType, indValue])

    const ChangeVisType = ((e) => {
        setVisualType(e.target.value)

        tableMaker()
        console.log(e.target.value)
        // console.log('Ogi')
    })

    const ShowVisual = () => {
        // console.log(ChangeVisType)
        if (visualType === 'Table') {
            // console.log(table)
            return (
                <div className="overflow-x-auto" id="compTable">
                    <table className="table-auto w-full">
                        <tr className="firstRow">
                            <th className="firstCol"></th>
                            <th>1990</th>
                            <th>1991</th>
                            <th>1992</th>
                            <th>1993</th>
                            <th>1994</th>
                            <th>1995</th>
                            <th>1996</th>
                            <th>1997</th>
                            <th>1998</th>
                            <th>1999</th>
                            <th>2000</th>
                            <th>2001</th>
                            <th>2002</th>
                            <th>2003</th>
                            <th>2004</th>
                            <th>2005</th>
                            <th>2006</th>
                            <th>2007</th>
                            <th>2008</th>
                            <th>2009</th>
                            <th>2010</th>
                            <th>2011</th>
                            <th>2012</th>
                            <th>2013</th>
                            <th>2014</th>
                            <th>2015</th>
                            <th>2016</th>
                            <th>2017</th>
                            <th>2018</th>
                            <th>2019</th>
                            <th>2020</th>
                            <th>2021</th>
                            <th>2022</th>
                        </tr>

                        <tr className="subRow">
                            <td className="firstCol w-56 h-24">Indonesia</td>
                            <td id="IDN1990" style={{ 'backgroundColor': backgroundColor }} className="tblToolTipCell bg-yellow-500">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN1991" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN1992" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN1993" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN1994" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN1995" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN1996" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN1997" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN1998" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN1999" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2000" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2001" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2002" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2003" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2004" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2005" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2006" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2007" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2008" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2009" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2010" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2011" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2012" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2013" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2014" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2015" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2016" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2017" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2018" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2019" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2020" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2021" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="IDN2022" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                        </tr>
                        <tr className="subRow">
                            <td className="firstCol" id="compCountry">{compCountry}</td>
                            <td id="compCtry1990" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry1991" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry1992" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry1993" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry1994" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry1995" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry1996" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry1997" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry1998" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry1999" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2000" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2001" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2002" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2003" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2004" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2005" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2006" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2007" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2008" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2009" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2010" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2011" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2012" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2013" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2014" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2015" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2016" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2017" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2018" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2019" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2020" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2021" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compCtry2022" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                        </tr>
                        <tr className="subRow">
                            <td className="firstCol" id="compRegion">{compRegion}</td>
                            <td id="compReg1990" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg1991" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg1992" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg1993" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg1994" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg1995" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg1996" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg1997" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg1998" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg1999" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2000" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2001" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2002" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2003" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2004" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2005" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2006" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2007" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2008" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2009" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2010" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2011" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2012" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2013" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2014" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2015" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2016" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2017" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2018" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2019" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2020" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2021" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                            <td id="compReg2022" className="tblToolTipCell">
                                <span className="tblToolTipComment">Here is a comment</span>
                            </td>
                        </tr>
                    </table>
                </div>
            )
        }
        else if (visualType === "Line") {
            console.log(linechartData)
            return (
                <Chart
                    chartType="LineChart"
                    width="100%"
                    height="500px"
                    data={linechartData}
                    options={option_line}
                />
            )
        }
    }

    // console.log(ShowVisual)

    const getIndicatorValue = event => {
        setIndValue(event.currentTarget.id)
        // console.log(indValue)

        tableMaker()

    };

    const getCompCountry = (e) => {
        const countryValue = e.target.value
        setcompCountry(countryValue)
        console.log(countryValue)

        tableMaker()

    }


    const getCompRegion = (e) => {
        const regionValue = e.target.value
        setcompRegion(regionValue)

        tableMaker()
    }


    const tableMaker = () => {
        let indonesiaObj = countriesData.filter(element => element["Country Name"] === 'Indonesia');
        // console.log(indonesiaObj)

        for (let i = 0; i < indonesiaObj.length; i++) {
            for (let j = 0; j < years.length; j++) {
                if (indonesiaObj[i]["Time"] === years[j]) {
                    idnVal[i] = indonesiaObj[j][indValue];
                }
            }
        };
        // setIndonesiadata(idnVal)
        // console.log(indonesiaData)


        let compCtryObj = countriesData.filter(element => element["Country Name"] === compCountry);
        console.log('halo', compCtryObj)

        for (let i = 0; i < compCtryObj.length; i++) {
            for (let j = 0; j < years.length; j++) {
                if (compCtryObj[i]["Time"] === years[j]) {
                    compCtryVal[i] = compCtryObj[j][indValue];
                }
            }
        };
        // setCountrydata(compCtryVal)
        // console.log(countryData)

        let compRegObj = regionsData.filter(element => element["Country Name"] === compRegion);
        console.log('halo 2', compRegObj)

        for (let i = 0; i < compRegObj.length; i++) {
            for (let j = 0; j < years.length; j++) {
                if (compRegObj[i]["Time"] === years[j]) {
                    compRegVal[i] = compRegObj[j][indValue];
                }
            }
        };

        // setRegiondata(compRegVal)
        // console.log(regionData)

        console.log(indonesiaObj)
        console.log(compCtryObj)
        console.log(compRegObj)

        console.log(idnVal)
        console.log(compCtryVal)
        console.log(compRegVal)

        for (let i = 0; i < years.length; i++) {
            if (idnVal[i] === "..") {
                idnVal[i] = null;
            } else {
                idnVal[i] = idnVal[i]
            }
            if (compCtryVal[i] === "..") {
                compCtryVal[i] = null;
            } else {
                compCtryVal[i] = compCtryVal[i]
            }
            if (compRegVal[i] === "..") {
                compRegVal[i] = null;
            } else {
                compRegVal[i] = compRegVal[i]
            }
        }

        setIndonesiadata(idnVal)
        setCountrydata(compCtryVal)
        setRegiondata(compRegVal)

        setLinechartData([
            ['Year', 'Indonesia', compCountry, compRegion]
        ])

        for (let i = 0; i < years.length; i++) {
            if (idnVal[i] === null) {
                idnVal[i] = NaN;
            };
            if (compCtryVal[i] === null) {
                compCtryVal[i] = NaN;
            };
            if (compRegVal[i] === null) {
                compRegVal[i] = NaN;
            };

            setLinechartData((current) => [...current, [years[i], idnVal[i], compCtryVal[i], compRegVal[i]]])

        };

        for (let i = 0; i < years.length; i++) {
            for (let j = 0; j < colors.length; j++) {
                if (idnVal[i] === scores[j]) {
                    $('#IDN' + years[i]).css("background-color", "");
                    $('#IDN' + years[i]).css("background-color", colors[j]);
                    $('#IDN' + years[i]).children().empty().append(rankings[j]);
                }
                if (compCtryVal[i] === scores[j]) {
                    $('#compCtry' + years[i]).css("background-color", "");
                    $('#compCtry' + years[i]).css("background-color", colors[j]);
                    $('#compCtry' + years[i]).children().empty().append(rankings[j]);
                }
                if (compRegVal[i] === scores[j]) {
                    $('#compReg' + years[i]).css("background-color", "");
                    $('#compReg' + years[i]).css("background-color", colors[j]);
                    $('#compReg' + years[i]).children().empty().append(rankings[j]);
                }
            }
        };

    }

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);
    };
    return (
        <>
            <div className="mb-16">
                <div className="container mx-auto p-4">
                    <div className="flex flex-row items-center space-x-4">
                        <a href="/project">Projects</a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                            <path d="M8.275 7.54748L1.30875 0.909985C1.19251 0.799179 1.03808 0.737366 0.877496 0.737366C0.716908 0.737366 0.562482 0.799179 0.446247 0.909985L0.438746 0.917485C0.382203 0.971202 0.337178 1.03586 0.306411 1.10753C0.275644 1.17919 0.259778 1.25637 0.259778 1.33436C0.259778 1.41235 0.275644 1.48953 0.306411 1.56119C0.337178 1.63286 0.382203 1.69752 0.438746 1.75123L6.99875 8.00124L0.438746 14.2487C0.382203 14.3025 0.337178 14.3671 0.306411 14.4388C0.275644 14.5104 0.259778 14.5876 0.259778 14.6656C0.259778 14.7436 0.275644 14.8208 0.306411 14.8924C0.337178 14.9641 0.382203 15.0288 0.438746 15.0825L0.446247 15.09C0.562482 15.2008 0.716908 15.2626 0.877496 15.2626C1.03808 15.2626 1.19251 15.2008 1.30875 15.09L8.275 8.45249C8.33626 8.39411 8.38504 8.32391 8.41836 8.24613C8.45169 8.16834 8.46887 8.08461 8.46887 7.99998C8.46887 7.91536 8.45169 7.83162 8.41836 7.75384C8.38504 7.67606 8.33626 7.60586 8.275 7.54748Z" fill="black" />
                        </svg>
                        <a href="/project">Indonesia Socioeconomic Development</a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                            <path d="M8.275 7.54748L1.30875 0.909985C1.19251 0.799179 1.03808 0.737366 0.877496 0.737366C0.716908 0.737366 0.562482 0.799179 0.446247 0.909985L0.438746 0.917485C0.382203 0.971202 0.337178 1.03586 0.306411 1.10753C0.275644 1.17919 0.259778 1.25637 0.259778 1.33436C0.259778 1.41235 0.275644 1.48953 0.306411 1.56119C0.337178 1.63286 0.382203 1.69752 0.438746 1.75123L6.99875 8.00124L0.438746 14.2487C0.382203 14.3025 0.337178 14.3671 0.306411 14.4388C0.275644 14.5104 0.259778 14.5876 0.259778 14.6656C0.259778 14.7436 0.275644 14.8208 0.306411 14.8924C0.337178 14.9641 0.382203 15.0288 0.438746 15.0825L0.446247 15.09C0.562482 15.2008 0.716908 15.2626 0.877496 15.2626C1.03808 15.2626 1.19251 15.2008 1.30875 15.09L8.275 8.45249C8.33626 8.39411 8.38504 8.32391 8.41836 8.24613C8.45169 8.16834 8.46887 8.08461 8.46887 7.99998C8.46887 7.91536 8.45169 7.83162 8.41836 7.75384C8.38504 7.67606 8.33626 7.60586 8.275 7.54748Z" fill="black" />
                        </svg>
                        <a className='text-primary-400 ' href='compare'>Compare</a>
                    </div>
                </div>

                <div className="mx-auto p-2 text-lg md:text-xl font-semibold">Indonesia Global Socioeconomic Position Research Center</div>

                <div className="row mx-auto p-4">
                    {/* ===mobile screen=== */}
                    <div className={`bg-gray-200 dropdown ${isDropdownOpen ? "" : "sm:hidden"} rounded-lg `}>
                        <button
                            className="text-black btn btn-secondary p-2 rounded-xl bg-gray-300 dropdown-toggle w-full font-semibold"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                            {selectedOption}
                        </button>
                        <div
                            className={`text-black m-4 dropdown-menu max-h-[50vh] overflow-y-auto ${isDropdownOpen ? "block" : "hidden"
                                } rounded-lg`}
                            aria-labelledby="dropdownMenuButton"
                        >
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Socioeconomic Performance Index")}>
                                <label htmlFor="SEPX">Socioeconomic Performance Index</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Macroeconomic Stability")}>
                                <label htmlFor="macroStab">Macroeconomic Stability</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Price Stability")}>
                                <label htmlFor="priceStab">Price Stability</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Level of Employment")}>
                                <label htmlFor="empLev">Level of Employment</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Fiscal Health")}>
                                <label htmlFor="fiscHealth">Fiscal Health</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Favorable Trade Balance")}>
                                <label htmlFor="favTradeBal">Favorable Trade Balance</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Microeconomic Competitiveness")}>
                                <label htmlFor="microComp">Microeconomic Competitiveness</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Trade Competitiveness")}>
                                <label htmlFor="tradeComp">Trade Competitiveness</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Openness to Imports")}>
                                <label htmlFor="openToImp">Openness to Imports</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Political Responsiveness")}>
                                <label htmlFor="polResp">Political Responsiveness</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Total Factor Productivity")}>
                                <label htmlFor="tfp">Total Factor Productivity</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Social Cohesion")}>
                                <label htmlFor="socCoh">Social Cohesion</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Social Security")}>
                                <label htmlFor="socSec">Social Security</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Personal Safety")}>
                                <label htmlFor="persSafe">Personal Safety</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Gender Equality")}>
                                <label htmlFor="genderEqual">Gender Equality</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Equitable Social Development")}>
                                <label htmlFor="equitSocDev">Equitable Social Development</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Human Empowerment")}>
                                <label htmlFor="humEmp">Human Empowerment</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Scientific Output")}>
                                <label htmlFor="scientificOut">Scientific Output</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("School Enrollment")}>
                                <label htmlFor="schoolEnr">School Enrollment</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Infant Survivability")}>
                                <label htmlFor="infSurv">Infant Survivability</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Public Health")}>
                                <label htmlFor="pubHealth">Public Health</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Environmental Sustainability")}>
                                <label htmlFor="envSust">Environmental Sustainability</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Nature Conservation")}>
                                <label htmlFor="natureCons">Nature Conservation</label>
                            </div>
                            <div className="dropdown-item" onClick={() => handleOptionSelect("Access to Safe Water")}>
                                <label htmlFor="safeWatAcc">Access to Safe Water</label>
                            </div>
                            <div className="dropdown-item mb-4" onClick={() => handleOptionSelect("Natural Disaster Resilience")}>
                                <label htmlFor="natDisRes">Natural Disaster Resilience</label>
                            </div>
                        </div>
                    </div>

                    {/* ===web screen=== */}
                    {/* <div className="container mx-auto p-4" onChange={worldMapMaker}> */}
                    <div className="container mx-auto p-4">
                        <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4 items-left text-left">

                            <div className="col-span-1">

                                {/* Comparison to Indonesia */}
                                <div className="col">
                                    <label htmlFor="compCountrySelected" className="block">Select a comparison country to Indonesia</label>
                                    <select onChange={getCompCountry} id="compCountrySelected" className="w-full mt-1 border border-gray-300 rounded-md text-black p-1">
                                        {countries.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>

                                    <br />

                                    <label htmlFor="compRegionSelected" className="block">Select a comparison region to Indonesia</label>
                                    <select onChange={getCompRegion} id="compRegionSelected" className="w-full mt-1 border border-gray-300 rounded-md text-black p-1">
                                        {regions.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>

                                    <br />

                                    <label htmlFor="vizSelected" className="block">Select a visualization type</label>
                                    <select value={visualType} onChange={ChangeVisType} id="vizSelected" className="w-full mt-1 border border-gray-300 rounded-md text-black p-1">
                                        <option value='Table'>Table</option>
                                        <option value='Line'>Line Chart</option>
                                    </select>

                                    <br />
                                </div>


                                {/* Compare checkmark */}
                                <div className="hidden sm:block">

                                    <div className="grid grid-cols-4 gap-2 items-left text-left mt-4">
                                        <div className="col-span-3">
                                            <label className="container concept font-semibold">Socioeconomic Performance Index</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="SEPX" defaultChecked />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left mt-2">
                                        <div className="col-span-3">
                                            <label className="container concept font-semibold">Macroeconomic Stability</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="macroStab" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Price Stability</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="priceStab" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Level of Employment</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="empLev" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Fiscal Health</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="fiscHealth" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Favorable Trade Balance</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="favTradeBal" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left mt-2">
                                        <div className="col-span-3">
                                            <label className="container concept font-semibold">Microeconomic Competitiveness</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="microComp" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Trade Competitiveness</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="tradeComp" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Openess to Imports</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="openToImp" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Political Responsiveness</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="polResp" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Total Factor Productivity</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="tfp" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left mt-2">
                                        <div className="col-span-3">
                                            <label className="container concept font-semibold">Social Cohesion</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="socCoh" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Social Security</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="socSec" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Personal Safety</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="persSafe" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Gender Equality</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="genderEqual" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Equitable Social Development</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="equitSocDev" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left mt-2">
                                        <div className="col-span-3">
                                            <label className="container concept font-semibold">Human Empowerment</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="humEmp" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Scientific Output</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="scientificOut" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">School Enrollment</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="schoolEnr" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Infant Survivability</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="infSurv" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Public Health</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="pubHealth" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left mt-2">
                                        <div className="col-span-3">
                                            <label className="container concept font-semibold">Environmental Sustainability</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="envSust" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Nature Conservation</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="natureCons" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Access to Safe Water</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="safeWatAcc" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Access to Clean Air</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="cleanAirAcc" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-2 items-left text-left">
                                        <div className="col-span-3 md:pl-4 pl-2">
                                            <label className="container subConcept">Natural Disaster Resilience</label>
                                        </div>

                                        <div className="col-span-1 items-right text-right md:text-center">
                                            <input className="indListForWrldMap" onClick={getIndicatorValue} type="radio" name="radio" id="natDisRes" />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Table Curve Chart */}
                            <div className="col-span-3 mt-4 md:mt-0 md:ml-8">
                                {/* <div onClick={tableMaker} >tempat</div>
                                <div>country : {compCountry}</div>
                                <div>Region : {compRegion}</div>
                                <div>Indicator : {indValue}</div> */}
                                {ShowVisual()}


                            </div>

                            {/* Table Line Chart */}
                            <div className="w-full h-auto" id="curve_chart">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Compare