import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeScreen from './pages/home';
import ProjectScreen from './pages/project';
import AboutUsScreen from './pages/about-us';
import Explore from './pages/explore';
import Compare from './pages/compare';
import GlobalBusiness from './pages/global-busines';
import useMode from './hooks/useMode';
import Testing from './Testing';
import HeaderNavigation from "./component/header";
import FooterSection from './component/footer';
import ContactUsScreen from './pages/contactus';
import Collaboration from './pages/collab';
import DownloadData from './pages/download';
import SimulationPage from './pages/simulation';

function App() {
  const { darkMode } = useMode()

  return (  
    <BrowserRouter>
      <div className={`App ${darkMode && 'dark-mode'}`}>
          <HeaderNavigation/>
          <Routes>
            <Route path='/' element={<HomeScreen />} />
            <Route path='/testing' element={<Testing />} />
            <Route path='/project' element={<ProjectScreen />} />
            <Route path='/about' element={<AboutUsScreen />} />
            <Route path='/contact' element={<ContactUsScreen />} />
            <Route path='/explore' element={<Explore />} />
            <Route path='/compare' element={<Compare />} />
            <Route path='/collaboration' element={<Collaboration />} />
            <Route path='/globalbusiness' element={<GlobalBusiness />} />
            <Route path='/datacenter' element={<DownloadData />} />
            <Route path='/simulation' element={<SimulationPage />} />
          </Routes>
          <FooterSection />
      </div>
    </BrowserRouter>
  );
}

export default App;
