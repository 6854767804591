const regionsData = [
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1990,
        "ID": "Wrld1990",
        "yCap": 14362.21,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1991,
        "ID": "Wrld1991",
        "yCap": 14356.82,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1992,
        "ID": "Wrld1992",
        "yCap": 14405.05,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1993,
        "ID": "Wrld1993",
        "yCap": 14525.02,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1994,
        "ID": "Wrld1994",
        "yCap": 14726.85,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1995,
        "ID": "Wrld1995",
        "yCap": 14975.42,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1996,
        "ID": "Wrld1996",
        "yCap": 15241.13,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1997,
        "ID": "Wrld1997",
        "yCap": 15619.29,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1998,
        "ID": "Wrld1998",
        "yCap": 15846.45,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 1999,
        "ID": "Wrld1999",
        "yCap": 16124.39,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2000,
        "ID": "Wrld2000",
        "yCap": 16672.45,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2001,
        "ID": "Wrld2001",
        "yCap": 16884.54,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2002,
        "ID": "Wrld2002",
        "yCap": 17090.08,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2003,
        "ID": "Wrld2003",
        "yCap": 17492.64,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2004,
        "ID": "Wrld2004",
        "yCap": 18239.02,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2005,
        "ID": "Wrld2005",
        "yCap": 18717.8,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2006,
        "ID": "Wrld2006",
        "yCap": 19395.31,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2007,
        "ID": "Wrld2007",
        "yCap": 20008.67,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2008,
        "ID": "Wrld2008",
        "yCap": 20107.44,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2009,
        "ID": "Wrld2009",
        "yCap": 19276.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2010,
        "ID": "Wrld2010",
        "yCap": 19711.19,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2011,
        "ID": "Wrld2011",
        "yCap": 20107.12,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2012,
        "ID": "Wrld2012",
        "yCap": 20332.51,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2013,
        "ID": "Wrld2013",
        "yCap": 20536.44,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2014,
        "ID": "Wrld2014",
        "yCap": 20726.81,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2015,
        "ID": "Wrld2015",
        "yCap": 20851.54,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2016,
        "ID": "Wrld2016",
        "yCap": 21100.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2017,
        "ID": "Wrld2017",
        "yCap": 21506.62,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2018,
        "ID": "Wrld2018",
        "yCap": 21902.17,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2019,
        "ID": "Wrld2019",
        "yCap": 22143.38,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2020,
        "ID": "Wrld2020",
        "yCap": 20530.55,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2021,
        "ID": "Wrld2021",
        "yCap": 21608.58,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "World",
        "Alpha-3": "Wrld",
        "Alpha-2": "Wrld",
        "Region": "Wrld",
        "IncGroup": "Wrld",
        "RegIncGrp": "Wrld",
        "Time": 2022,
        "ID": "Wrld2022",
        "yCap": 21608.58,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1990,
        "ID": "EAP1990",
        "yCap": 12367.59,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1991,
        "ID": "EAP1991",
        "yCap": 12505.46,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1992,
        "ID": "EAP1992",
        "yCap": 12903.06,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1993,
        "ID": "EAP1993",
        "yCap": 13250.15,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1994,
        "ID": "EAP1994",
        "yCap": 13652.2,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1995,
        "ID": "EAP1995",
        "yCap": 14030.41,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1996,
        "ID": "EAP1996",
        "yCap": 14250.36,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1997,
        "ID": "EAP1997",
        "yCap": 14345.62,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1998,
        "ID": "EAP1998",
        "yCap": 13907.32,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 1999,
        "ID": "EAP1999",
        "yCap": 14177.28,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2000,
        "ID": "EAP2000",
        "yCap": 14657.18,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2001,
        "ID": "EAP2001",
        "yCap": 14767.58,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2002,
        "ID": "EAP2002",
        "yCap": 15202.57,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2003,
        "ID": "EAP2003",
        "yCap": 15690.84,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2004,
        "ID": "EAP2004",
        "yCap": 16635.31,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2005,
        "ID": "EAP2005",
        "yCap": 17160.26,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2006,
        "ID": "EAP2006",
        "yCap": 17930.46,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2007,
        "ID": "EAP2007",
        "yCap": 18645.08,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2008,
        "ID": "EAP2008",
        "yCap": 18659.79,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2009,
        "ID": "EAP2009",
        "yCap": 18352.43,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2010,
        "ID": "EAP2010",
        "yCap": 19761.77,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2011,
        "ID": "EAP2011",
        "yCap": 20971.66,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2012,
        "ID": "EAP2012",
        "yCap": 21618,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2013,
        "ID": "EAP2013",
        "yCap": 22402.01,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2014,
        "ID": "EAP2014",
        "yCap": 22596.64,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2015,
        "ID": "EAP2015",
        "yCap": 21826.58,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2016,
        "ID": "EAP2016",
        "yCap": 22024.82,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2017,
        "ID": "EAP2017",
        "yCap": 22747.82,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2018,
        "ID": "EAP2018",
        "yCap": 23336.48,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2019,
        "ID": "EAP2019",
        "yCap": 23421.25,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2020,
        "ID": "EAP2020",
        "yCap": 20593.57,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2021,
        "ID": "EAP2021",
        "yCap": 21482.05,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific",
        "Alpha-3": "EAP",
        "Alpha-2": "EAP",
        "Region": "EAP",
        "IncGroup": "EAP",
        "RegIncGrp": "EAP",
        "Time": 2022,
        "ID": "EAP2022",
        "yCap": 21482.05,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1990,
        "ID": "ECA1990",
        "yCap": 21546.26,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1991,
        "ID": "ECA1991",
        "yCap": 21372.8,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1992,
        "ID": "ECA1992",
        "yCap": 21081.84,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1993,
        "ID": "ECA1993",
        "yCap": 20893.97,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1994,
        "ID": "ECA1994",
        "yCap": 21098.63,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1995,
        "ID": "ECA1995",
        "yCap": 21483.99,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1996,
        "ID": "ECA1996",
        "yCap": 21981.24,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1997,
        "ID": "ECA1997",
        "yCap": 22717.27,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1998,
        "ID": "ECA1998",
        "yCap": 23548.9,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 1999,
        "ID": "ECA1999",
        "yCap": 24300.48,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2000,
        "ID": "ECA2000",
        "yCap": 25310.46,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2001,
        "ID": "ECA2001",
        "yCap": 25961.38,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2002,
        "ID": "ECA2002",
        "yCap": 26468.77,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2003,
        "ID": "ECA2003",
        "yCap": 27049.29,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2004,
        "ID": "ECA2004",
        "yCap": 28109.83,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2005,
        "ID": "ECA2005",
        "yCap": 29015.44,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2006,
        "ID": "ECA2006",
        "yCap": 30295.56,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2007,
        "ID": "ECA2007",
        "yCap": 31705.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2008,
        "ID": "ECA2008",
        "yCap": 31930.82,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2009,
        "ID": "ECA2009",
        "yCap": 30195.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2010,
        "ID": "ECA2010",
        "yCap": 30589.07,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2011,
        "ID": "ECA2011",
        "yCap": 30918.19,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2012,
        "ID": "ECA2012",
        "yCap": 30801.86,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2013,
        "ID": "ECA2013",
        "yCap": 31011.79,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2014,
        "ID": "ECA2014",
        "yCap": 31496.58,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2015,
        "ID": "ECA2015",
        "yCap": 32309.12,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2016,
        "ID": "ECA2016",
        "yCap": 32968.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2017,
        "ID": "ECA2017",
        "yCap": 33882.6,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2018,
        "ID": "ECA2018",
        "yCap": 34759.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2019,
        "ID": "ECA2019",
        "yCap": 35523.11,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2020,
        "ID": "ECA2020",
        "yCap": 34038.75,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2021,
        "ID": "ECA2021",
        "yCap": 36006.99,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia",
        "Alpha-3": "ECA",
        "Alpha-2": "ECA",
        "Region": "ECA",
        "IncGroup": "ECA",
        "RegIncGrp": "ECA",
        "Time": 2022,
        "ID": "ECA2022",
        "yCap": 36006.99,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1990,
        "ID": "LAC1990",
        "yCap": 14458,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1991,
        "ID": "LAC1991",
        "yCap": 14562.97,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1992,
        "ID": "LAC1992",
        "yCap": 14756.28,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1993,
        "ID": "LAC1993",
        "yCap": 14965.32,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1994,
        "ID": "LAC1994",
        "yCap": 15230.22,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1995,
        "ID": "LAC1995",
        "yCap": 15335.78,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1996,
        "ID": "LAC1996",
        "yCap": 15545.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1997,
        "ID": "LAC1997",
        "yCap": 15961.58,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1998,
        "ID": "LAC1998",
        "yCap": 16132.18,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 1999,
        "ID": "LAC1999",
        "yCap": 16282.35,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2000,
        "ID": "LAC2000",
        "yCap": 16566.14,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2001,
        "ID": "LAC2001",
        "yCap": 16630.31,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2002,
        "ID": "LAC2002",
        "yCap": 16649.59,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2003,
        "ID": "LAC2003",
        "yCap": 16884.42,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2004,
        "ID": "LAC2004",
        "yCap": 17359.15,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2005,
        "ID": "LAC2005",
        "yCap": 17714.1,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2006,
        "ID": "LAC2006",
        "yCap": 18214.89,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2007,
        "ID": "LAC2007",
        "yCap": 18690.28,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2008,
        "ID": "LAC2008",
        "yCap": 18915.13,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2009,
        "ID": "LAC2009",
        "yCap": 18152.51,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2010,
        "ID": "LAC2010",
        "yCap": 18305.2,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2011,
        "ID": "LAC2011",
        "yCap": 18677.9,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2012,
        "ID": "LAC2012",
        "yCap": 18833.91,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2013,
        "ID": "LAC2013",
        "yCap": 19012.88,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2014,
        "ID": "LAC2014",
        "yCap": 19274.6,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2015,
        "ID": "LAC2015",
        "yCap": 19451.33,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2016,
        "ID": "LAC2016",
        "yCap": 19564.52,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2017,
        "ID": "LAC2017",
        "yCap": 19627.35,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2018,
        "ID": "LAC2018",
        "yCap": 19828.33,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2019,
        "ID": "LAC2019",
        "yCap": 19983.88,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2020,
        "ID": "LAC2020",
        "yCap": 17800.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2021,
        "ID": "LAC2021",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean",
        "Alpha-3": "LAC",
        "Alpha-2": "LAC",
        "Region": "LAC",
        "IncGroup": "LAC",
        "RegIncGrp": "LAC",
        "Time": 2022,
        "ID": "LAC2022",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1990,
        "ID": "MENA1990",
        "yCap": 24642.33,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1991,
        "ID": "MENA1991",
        "yCap": 24723.38,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1992,
        "ID": "MENA1992",
        "yCap": 24934.53,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1993,
        "ID": "MENA1993",
        "yCap": 25624.77,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1994,
        "ID": "MENA1994",
        "yCap": 25949.74,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1995,
        "ID": "MENA1995",
        "yCap": 26291.17,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1996,
        "ID": "MENA1996",
        "yCap": 26484.79,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1997,
        "ID": "MENA1997",
        "yCap": 26708.7,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1998,
        "ID": "MENA1998",
        "yCap": 26735.63,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 1999,
        "ID": "MENA1999",
        "yCap": 26463.14,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2000,
        "ID": "MENA2000",
        "yCap": 27399.98,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2001,
        "ID": "MENA2001",
        "yCap": 26975.73,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2002,
        "ID": "MENA2002",
        "yCap": 26770.58,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2003,
        "ID": "MENA2003",
        "yCap": 27640.96,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2004,
        "ID": "MENA2004",
        "yCap": 29212.73,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2005,
        "ID": "MENA2005",
        "yCap": 29624.75,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2006,
        "ID": "MENA2006",
        "yCap": 30012.29,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2007,
        "ID": "MENA2007",
        "yCap": 29623.53,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2008,
        "ID": "MENA2008",
        "yCap": 29336.44,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2009,
        "ID": "MENA2009",
        "yCap": 28125.51,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2010,
        "ID": "MENA2010",
        "yCap": 28673.1,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2011,
        "ID": "MENA2011",
        "yCap": 28706.29,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2012,
        "ID": "MENA2012",
        "yCap": 29787.76,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2013,
        "ID": "MENA2013",
        "yCap": 29408.97,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2014,
        "ID": "MENA2014",
        "yCap": 29010.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2015,
        "ID": "MENA2015",
        "yCap": 29022.2,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2016,
        "ID": "MENA2016",
        "yCap": 29159.08,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2017,
        "ID": "MENA2017",
        "yCap": 29138.23,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2018,
        "ID": "MENA2018",
        "yCap": 29259.79,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2019,
        "ID": "MENA2019",
        "yCap": 29025.99,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2020,
        "ID": "MENA2020",
        "yCap": 27251.16,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2021,
        "ID": "MENA2021",
        "yCap": 28473.86,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa",
        "Alpha-3": "MENA",
        "Alpha-2": "MENA",
        "Region": "MENA",
        "IncGroup": "MENA",
        "RegIncGrp": "MENA",
        "Time": 2022,
        "ID": "MENA2022",
        "yCap": 28473.86,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1990,
        "ID": "NRTHAM1990",
        "yCap": 47776.04,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1991,
        "ID": "NRTHAM1991",
        "yCap": 46915.13,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1992,
        "ID": "NRTHAM1992",
        "yCap": 47335.98,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1993,
        "ID": "NRTHAM1993",
        "yCap": 48054.23,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1994,
        "ID": "NRTHAM1994",
        "yCap": 48413.12,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1995,
        "ID": "NRTHAM1995",
        "yCap": 49423.71,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1996,
        "ID": "NRTHAM1996",
        "yCap": 50236.14,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1997,
        "ID": "NRTHAM1997",
        "yCap": 51626.87,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1998,
        "ID": "NRTHAM1998",
        "yCap": 53255.08,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 1999,
        "ID": "NRTHAM1999",
        "yCap": 55064.44,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2000,
        "ID": "NRTHAM2000",
        "yCap": 58231.97,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2001,
        "ID": "NRTHAM2001",
        "yCap": 60003.81,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2002,
        "ID": "NRTHAM2002",
        "yCap": 59771.95,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2003,
        "ID": "NRTHAM2003",
        "yCap": 61008.05,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2004,
        "ID": "NRTHAM2004",
        "yCap": 62298.49,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2005,
        "ID": "NRTHAM2005",
        "yCap": 63377.63,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2006,
        "ID": "NRTHAM2006",
        "yCap": 65442.13,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2007,
        "ID": "NRTHAM2007",
        "yCap": 66679.56,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2008,
        "ID": "NRTHAM2008",
        "yCap": 65713.7,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2009,
        "ID": "NRTHAM2009",
        "yCap": 62495.6,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2010,
        "ID": "NRTHAM2010",
        "yCap": 62592.77,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2011,
        "ID": "NRTHAM2011",
        "yCap": 62204.29,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2012,
        "ID": "NRTHAM2012",
        "yCap": 60970.8,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2013,
        "ID": "NRTHAM2013",
        "yCap": 61215.79,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2014,
        "ID": "NRTHAM2014",
        "yCap": 60744.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2015,
        "ID": "NRTHAM2015",
        "yCap": 61264.15,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2016,
        "ID": "NRTHAM2016",
        "yCap": 61526.61,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2017,
        "ID": "NRTHAM2017",
        "yCap": 63355.64,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2018,
        "ID": "NRTHAM2018",
        "yCap": 63913.85,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2019,
        "ID": "NRTHAM2019",
        "yCap": 64443.83,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2020,
        "ID": "NRTHAM2020",
        "yCap": 60783.31,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2021,
        "ID": "NRTHAM2021",
        "yCap": 63944.6,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America",
        "Alpha-3": "NRTHAM",
        "Alpha-2": "NRTHAM",
        "Region": "NRTHAM",
        "IncGroup": "NRTHAM",
        "RegIncGrp": "NRTHAM",
        "Time": 2022,
        "ID": "NRTHAM2022",
        "yCap": 63944.6,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1990,
        "ID": "SA1990",
        "yCap": 3328.3,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1991,
        "ID": "SA1991",
        "yCap": 3352.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1992,
        "ID": "SA1992",
        "yCap": 3435.56,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1993,
        "ID": "SA1993",
        "yCap": 3502.86,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1994,
        "ID": "SA1994",
        "yCap": 3577.84,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1995,
        "ID": "SA1995",
        "yCap": 3660.38,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1996,
        "ID": "SA1996",
        "yCap": 3802.58,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1997,
        "ID": "SA1997",
        "yCap": 3953.4,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1998,
        "ID": "SA1998",
        "yCap": 4103.41,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 1999,
        "ID": "SA1999",
        "yCap": 4262.03,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2000,
        "ID": "SA2000",
        "yCap": 4370.2,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2001,
        "ID": "SA2001",
        "yCap": 4317.03,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2002,
        "ID": "SA2002",
        "yCap": 4478.05,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2003,
        "ID": "SA2003",
        "yCap": 4794.15,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2004,
        "ID": "SA2004",
        "yCap": 4992.26,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2005,
        "ID": "SA2005",
        "yCap": 4868.96,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2006,
        "ID": "SA2006",
        "yCap": 5416.6,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2007,
        "ID": "SA2007",
        "yCap": 5764.97,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2008,
        "ID": "SA2008",
        "yCap": 6009.65,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2009,
        "ID": "SA2009",
        "yCap": 5948.48,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2010,
        "ID": "SA2010",
        "yCap": 6283.38,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2011,
        "ID": "SA2011",
        "yCap": 6591.57,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2012,
        "ID": "SA2012",
        "yCap": 6810.95,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2013,
        "ID": "SA2013",
        "yCap": 7029.87,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2014,
        "ID": "SA2014",
        "yCap": 7325.95,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2015,
        "ID": "SA2015",
        "yCap": 7502.44,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2016,
        "ID": "SA2016",
        "yCap": 7796.28,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2017,
        "ID": "SA2017",
        "yCap": 8121.35,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2018,
        "ID": "SA2018",
        "yCap": 8403.8,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2019,
        "ID": "SA2019",
        "yCap": 8655.76,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2020,
        "ID": "SA2020",
        "yCap": 7437.76,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2021,
        "ID": "SA2021",
        "yCap": 8267.9,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia",
        "Alpha-3": "SA",
        "Alpha-2": "SA",
        "Region": "SA",
        "IncGroup": "SA",
        "RegIncGrp": "SA",
        "Time": 2022,
        "ID": "SA2022",
        "yCap": 8267.9,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1990,
        "ID": "SSA1990",
        "yCap": 3314.23,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1991,
        "ID": "SSA1991",
        "yCap": 3321.14,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1992,
        "ID": "SSA1992",
        "yCap": 3288.3,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1993,
        "ID": "SSA1993",
        "yCap": 3252.75,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1994,
        "ID": "SSA1994",
        "yCap": 3226.81,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1995,
        "ID": "SSA1995",
        "yCap": 3295.19,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1996,
        "ID": "SSA1996",
        "yCap": 3400.43,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1997,
        "ID": "SSA1997",
        "yCap": 3587.25,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1998,
        "ID": "SSA1998",
        "yCap": 3656.06,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 1999,
        "ID": "SSA1999",
        "yCap": 3675.57,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2000,
        "ID": "SSA2000",
        "yCap": 3725.11,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2001,
        "ID": "SSA2001",
        "yCap": 3854.99,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2002,
        "ID": "SSA2002",
        "yCap": 3933.49,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2003,
        "ID": "SSA2003",
        "yCap": 3987.22,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2004,
        "ID": "SSA2004",
        "yCap": 4196.07,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2005,
        "ID": "SSA2005",
        "yCap": 4372.53,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2006,
        "ID": "SSA2006",
        "yCap": 4526.64,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2007,
        "ID": "SSA2007",
        "yCap": 4735.79,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2008,
        "ID": "SSA2008",
        "yCap": 4856.07,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2009,
        "ID": "SSA2009",
        "yCap": 4779.56,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2010,
        "ID": "SSA2010",
        "yCap": 4821.24,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2011,
        "ID": "SSA2011",
        "yCap": 4976.13,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2012,
        "ID": "SSA2012",
        "yCap": 5087.71,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2013,
        "ID": "SSA2013",
        "yCap": 5149.66,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2014,
        "ID": "SSA2014",
        "yCap": 5227.59,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2015,
        "ID": "SSA2015",
        "yCap": 5177.97,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2016,
        "ID": "SSA2016",
        "yCap": 5161.35,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2017,
        "ID": "SSA2017",
        "yCap": 5176,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2018,
        "ID": "SSA2018",
        "yCap": 5196.74,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2019,
        "ID": "SSA2019",
        "yCap": 5220.15,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2020,
        "ID": "SSA2020",
        "yCap": 4876.83,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2021,
        "ID": "SSA2021",
        "yCap": 5008.87,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa",
        "Alpha-3": "SSA",
        "Alpha-2": "SSA",
        "Region": "SSA",
        "IncGroup": "SSA",
        "RegIncGrp": "SSA",
        "Time": 2022,
        "ID": "SSA2022",
        "yCap": 5008.87,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1990,
        "ID": "Low1990",
        "yCap": 1394.67,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1991,
        "ID": "Low1991",
        "yCap": 1388.24,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1992,
        "ID": "Low1992",
        "yCap": 1345.15,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1993,
        "ID": "Low1993",
        "yCap": 1326.16,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1994,
        "ID": "Low1994",
        "yCap": 1296.97,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1995,
        "ID": "Low1995",
        "yCap": 1315.28,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1996,
        "ID": "Low1996",
        "yCap": 1339.66,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1997,
        "ID": "Low1997",
        "yCap": 1380.01,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1998,
        "ID": "Low1998",
        "yCap": 1362.28,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 1999,
        "ID": "Low1999",
        "yCap": 1371.97,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2000,
        "ID": "Low2000",
        "yCap": 1372.98,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2001,
        "ID": "Low2001",
        "yCap": 1398.89,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2002,
        "ID": "Low2002",
        "yCap": 1412.64,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2003,
        "ID": "Low2003",
        "yCap": 1420.16,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2004,
        "ID": "Low2004",
        "yCap": 1457.19,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2005,
        "ID": "Low2005",
        "yCap": 1488.69,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2006,
        "ID": "Low2006",
        "yCap": 1518.65,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2007,
        "ID": "Low2007",
        "yCap": 1561.09,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2008,
        "ID": "Low2008",
        "yCap": 1600.59,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2009,
        "ID": "Low2009",
        "yCap": 1620.22,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2010,
        "ID": "Low2010",
        "yCap": 1678.36,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2011,
        "ID": "Low2011",
        "yCap": 1711.56,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2012,
        "ID": "Low2012",
        "yCap": 1742.96,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2013,
        "ID": "Low2013",
        "yCap": 1763.58,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2014,
        "ID": "Low2014",
        "yCap": 1793.31,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2015,
        "ID": "Low2015",
        "yCap": 1797.45,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2016,
        "ID": "Low2016",
        "yCap": 1819.9,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2017,
        "ID": "Low2017",
        "yCap": 1837.84,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2018,
        "ID": "Low2018",
        "yCap": 1856.25,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2019,
        "ID": "Low2019",
        "yCap": 1878.51,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2020,
        "ID": "Low2020",
        "yCap": 1821.14,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2021,
        "ID": "Low2021",
        "yCap": 1818.11,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Low Income",
        "Alpha-3": "Low",
        "Alpha-2": "Low",
        "Region": "Low",
        "IncGroup": "Low",
        "RegIncGrp": "Low",
        "Time": 2022,
        "ID": "Low2022",
        "yCap": 1818.11,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1990,
        "ID": "LowMid1990",
        "yCap": 3793.64,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1991,
        "ID": "LowMid1991",
        "yCap": 3795.54,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1992,
        "ID": "LowMid1992",
        "yCap": 3755.51,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1993,
        "ID": "LowMid1993",
        "yCap": 3695.89,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1994,
        "ID": "LowMid1994",
        "yCap": 3636.41,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1995,
        "ID": "LowMid1995",
        "yCap": 3703.04,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1996,
        "ID": "LowMid1996",
        "yCap": 3799.96,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1997,
        "ID": "LowMid1997",
        "yCap": 3846.29,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1998,
        "ID": "LowMid1998",
        "yCap": 3897.23,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 1999,
        "ID": "LowMid1999",
        "yCap": 3941.01,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2000,
        "ID": "LowMid2000",
        "yCap": 4005.76,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2001,
        "ID": "LowMid2001",
        "yCap": 4060.47,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2002,
        "ID": "LowMid2002",
        "yCap": 4131.59,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2003,
        "ID": "LowMid2003",
        "yCap": 4263.09,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2004,
        "ID": "LowMid2004",
        "yCap": 4434.24,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2005,
        "ID": "LowMid2005",
        "yCap": 4593.1,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2006,
        "ID": "LowMid2006",
        "yCap": 4768.87,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2007,
        "ID": "LowMid2007",
        "yCap": 4981.73,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2008,
        "ID": "LowMid2008",
        "yCap": 5136.31,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2009,
        "ID": "LowMid2009",
        "yCap": 5188.65,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2010,
        "ID": "LowMid2010",
        "yCap": 5386.36,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2011,
        "ID": "LowMid2011",
        "yCap": 5546.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2012,
        "ID": "LowMid2012",
        "yCap": 5686.37,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2013,
        "ID": "LowMid2013",
        "yCap": 5794.35,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2014,
        "ID": "LowMid2014",
        "yCap": 5927,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2015,
        "ID": "LowMid2015",
        "yCap": 6011.65,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2016,
        "ID": "LowMid2016",
        "yCap": 6165.44,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2017,
        "ID": "LowMid2017",
        "yCap": 6318.36,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2018,
        "ID": "LowMid2018",
        "yCap": 6437.63,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2019,
        "ID": "LowMid2019",
        "yCap": 6546.76,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2020,
        "ID": "LowMid2020",
        "yCap": 6225.55,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2021,
        "ID": "LowMid2021",
        "yCap": 6368.24,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lower Middle Income",
        "Alpha-3": "LowMid",
        "Alpha-2": "LowMid",
        "Region": "LowMid",
        "IncGroup": "LowMid",
        "RegIncGrp": "LowMid",
        "Time": 2022,
        "ID": "LowMid2022",
        "yCap": 6368.24,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1990,
        "ID": "UpMid1990",
        "yCap": 8963.04,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1991,
        "ID": "UpMid1991",
        "yCap": 8786.61,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1992,
        "ID": "UpMid1992",
        "yCap": 8653.72,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1993,
        "ID": "UpMid1993",
        "yCap": 8668.84,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1994,
        "ID": "UpMid1994",
        "yCap": 8684.32,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1995,
        "ID": "UpMid1995",
        "yCap": 8771.83,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1996,
        "ID": "UpMid1996",
        "yCap": 8997.5,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1997,
        "ID": "UpMid1997",
        "yCap": 9280.11,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1998,
        "ID": "UpMid1998",
        "yCap": 9460.2,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 1999,
        "ID": "UpMid1999",
        "yCap": 9536.57,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2000,
        "ID": "UpMid2000",
        "yCap": 9821.72,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2001,
        "ID": "UpMid2001",
        "yCap": 9997.35,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2002,
        "ID": "UpMid2002",
        "yCap": 10226.4,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2003,
        "ID": "UpMid2003",
        "yCap": 10604.87,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2004,
        "ID": "UpMid2004",
        "yCap": 11223.91,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2005,
        "ID": "UpMid2005",
        "yCap": 11715.58,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2006,
        "ID": "UpMid2006",
        "yCap": 12347.9,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2007,
        "ID": "UpMid2007",
        "yCap": 13032.63,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2008,
        "ID": "UpMid2008",
        "yCap": 13486.34,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2009,
        "ID": "UpMid2009",
        "yCap": 13098.93,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2010,
        "ID": "UpMid2010",
        "yCap": 13499.55,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2011,
        "ID": "UpMid2011",
        "yCap": 13694.29,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2012,
        "ID": "UpMid2012",
        "yCap": 14261.01,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2013,
        "ID": "UpMid2013",
        "yCap": 14441.47,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2014,
        "ID": "UpMid2014",
        "yCap": 14573.24,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2015,
        "ID": "UpMid2015",
        "yCap": 14690.67,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2016,
        "ID": "UpMid2016",
        "yCap": 14849.14,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2017,
        "ID": "UpMid2017",
        "yCap": 15236.83,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2018,
        "ID": "UpMid2018",
        "yCap": 15599.41,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2019,
        "ID": "UpMid2019",
        "yCap": 15822.26,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2020,
        "ID": "UpMid2020",
        "yCap": 14642.31,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2021,
        "ID": "UpMid2021",
        "yCap": 15603.8,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Upper Middle Income",
        "Alpha-3": "UpMid",
        "Alpha-2": "UpMid",
        "Region": "UpMid",
        "IncGroup": "UpMid",
        "RegIncGrp": "UpMid",
        "Time": 2022,
        "ID": "UpMid2022",
        "yCap": 15603.8,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1990,
        "ID": "High1990",
        "yCap": 32050.09,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1991,
        "ID": "High1991",
        "yCap": 32175.84,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1992,
        "ID": "High1992",
        "yCap": 32474.61,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1993,
        "ID": "High1993",
        "yCap": 32876.83,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1994,
        "ID": "High1994",
        "yCap": 33526.58,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1995,
        "ID": "High1995",
        "yCap": 34136.63,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1996,
        "ID": "High1996",
        "yCap": 34660,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1997,
        "ID": "High1997",
        "yCap": 35509.8,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1998,
        "ID": "High1998",
        "yCap": 36007.66,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 1999,
        "ID": "High1999",
        "yCap": 36736.31,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2000,
        "ID": "High2000",
        "yCap": 38089.77,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2001,
        "ID": "High2001",
        "yCap": 38527.68,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2002,
        "ID": "High2002",
        "yCap": 38893.94,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2003,
        "ID": "High2003",
        "yCap": 39680.75,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2004,
        "ID": "High2004",
        "yCap": 41257.87,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2005,
        "ID": "High2005",
        "yCap": 42150.4,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2006,
        "ID": "High2006",
        "yCap": 43510.03,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2007,
        "ID": "High2007",
        "yCap": 44601.03,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2008,
        "ID": "High2008",
        "yCap": 44390.46,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2009,
        "ID": "High2009",
        "yCap": 42170.45,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2010,
        "ID": "High2010",
        "yCap": 42961.98,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2011,
        "ID": "High2011",
        "yCap": 43843.11,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2012,
        "ID": "High2012",
        "yCap": 43935.12,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2013,
        "ID": "High2013",
        "yCap": 44302.4,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2014,
        "ID": "High2014",
        "yCap": 44644.43,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2015,
        "ID": "High2015",
        "yCap": 44850.98,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2016,
        "ID": "High2016",
        "yCap": 45331.44,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2017,
        "ID": "High2017",
        "yCap": 46100.1,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2018,
        "ID": "High2018",
        "yCap": 46885.02,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2019,
        "ID": "High2019",
        "yCap": 47328.11,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2020,
        "ID": "High2020",
        "yCap": 43745.56,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2021,
        "ID": "High2021",
        "yCap": 46076.39,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "High Income",
        "Alpha-3": "High",
        "Alpha-2": "High",
        "Region": "High",
        "IncGroup": "High",
        "RegIncGrp": "High",
        "Time": 2022,
        "ID": "High2022",
        "yCap": 46076.39,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1990,
        "ID": "EAP-Low1990",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1991,
        "ID": "EAP-Low1991",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1992,
        "ID": "EAP-Low1992",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1993,
        "ID": "EAP-Low1993",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1994,
        "ID": "EAP-Low1994",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1995,
        "ID": "EAP-Low1995",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1996,
        "ID": "EAP-Low1996",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1997,
        "ID": "EAP-Low1997",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1998,
        "ID": "EAP-Low1998",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1999,
        "ID": "EAP-Low1999",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2000,
        "ID": "EAP-Low2000",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2001,
        "ID": "EAP-Low2001",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2002,
        "ID": "EAP-Low2002",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2003,
        "ID": "EAP-Low2003",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": "..",
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2004,
        "ID": "EAP-Low2004",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": "..",
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2005,
        "ID": "EAP-Low2005",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": "..",
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2006,
        "ID": "EAP-Low2006",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": "..",
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2007,
        "ID": "EAP-Low2007",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": "..",
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2008,
        "ID": "EAP-Low2008",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2009,
        "ID": "EAP-Low2009",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2010,
        "ID": "EAP-Low2010",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2011,
        "ID": "EAP-Low2011",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2012,
        "ID": "EAP-Low2012",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2013,
        "ID": "EAP-Low2013",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2014,
        "ID": "EAP-Low2014",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2015,
        "ID": "EAP-Low2015",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2016,
        "ID": "EAP-Low2016",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2017,
        "ID": "EAP-Low2017",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2018,
        "ID": "EAP-Low2018",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2019,
        "ID": "EAP-Low2019",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2020,
        "ID": "EAP-Low2020",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2021,
        "ID": "EAP-Low2021",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Low Income",
        "Alpha-3": "EAP-Low",
        "Alpha-2": "EAP-Low",
        "Region": "EAP-Low",
        "IncGroup": "EAP-Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2022,
        "ID": "EAP-Low2022",
        "yCap": "..",
        "priceStab": "..",
        "empLev": 5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": "..",
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": "..",
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "EAP-LowMid1990",
        "yCap": 2781,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "EAP-LowMid1991",
        "yCap": 2779.12,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "EAP-LowMid1992",
        "yCap": 2806.87,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "EAP-LowMid1993",
        "yCap": 2877.01,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "EAP-LowMid1994",
        "yCap": 2894.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "EAP-LowMid1995",
        "yCap": 2985.4,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "EAP-LowMid1996",
        "yCap": 3067.33,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "EAP-LowMid1997",
        "yCap": 3085.44,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "EAP-LowMid1998",
        "yCap": 3027.68,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "EAP-LowMid1999",
        "yCap": 3053.66,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "EAP-LowMid2000",
        "yCap": 3102.77,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "EAP-LowMid2001",
        "yCap": 3164.7,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "EAP-LowMid2002",
        "yCap": 3206.14,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "EAP-LowMid2003",
        "yCap": 3314.48,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "EAP-LowMid2004",
        "yCap": 3437.37,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "EAP-LowMid2005",
        "yCap": 3607.61,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "EAP-LowMid2006",
        "yCap": 3750.45,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "EAP-LowMid2007",
        "yCap": 3922.16,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "EAP-LowMid2008",
        "yCap": 4074.9,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "EAP-LowMid2009",
        "yCap": 4149.27,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "EAP-LowMid2010",
        "yCap": 4354.73,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "EAP-LowMid2011",
        "yCap": 4577.99,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "EAP-LowMid2012",
        "yCap": 4747.2,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "EAP-LowMid2013",
        "yCap": 4933.94,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "EAP-LowMid2014",
        "yCap": 5126.53,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "EAP-LowMid2015",
        "yCap": 5293.61,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "EAP-LowMid2016",
        "yCap": 5490.23,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "EAP-LowMid2017",
        "yCap": 5671.99,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "EAP-LowMid2018",
        "yCap": 5862.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "EAP-LowMid2019",
        "yCap": 6120.28,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "EAP-LowMid2020",
        "yCap": 5982.9,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "EAP-LowMid2021",
        "yCap": 5940.65,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Lower Middle Income",
        "Alpha-3": "EAP-LowMid",
        "Alpha-2": "EAP-LowMid",
        "Region": "EAP-LowMid",
        "IncGroup": "EAP-LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "EAP-LowMid2022",
        "yCap": 5940.65,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "EAP-UpMid1990",
        "yCap": 6281.34,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "EAP-UpMid1991",
        "yCap": 6436.54,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "EAP-UpMid1992",
        "yCap": 6689.38,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "EAP-UpMid1993",
        "yCap": 6953.85,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "EAP-UpMid1994",
        "yCap": 7280.34,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "EAP-UpMid1995",
        "yCap": 7572.5,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "EAP-UpMid1996",
        "yCap": 7753.07,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "EAP-UpMid1997",
        "yCap": 7781.46,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "EAP-UpMid1998",
        "yCap": 7559.6,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "EAP-UpMid1999",
        "yCap": 7772.47,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "EAP-UpMid2000",
        "yCap": 7926.25,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "EAP-UpMid2001",
        "yCap": 8102.43,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "EAP-UpMid2002",
        "yCap": 8431.17,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "EAP-UpMid2003",
        "yCap": 8545.1,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "EAP-UpMid2004",
        "yCap": 8867.63,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "EAP-UpMid2005",
        "yCap": 9103.32,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "EAP-UpMid2006",
        "yCap": 9347.89,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "EAP-UpMid2007",
        "yCap": 9700.31,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "EAP-UpMid2008",
        "yCap": 9791.02,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "EAP-UpMid2009",
        "yCap": 9588.19,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "EAP-UpMid2010",
        "yCap": 10012.19,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "EAP-UpMid2011",
        "yCap": 10456.86,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "EAP-UpMid2012",
        "yCap": 10830.72,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "EAP-UpMid2013",
        "yCap": 11093.5,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "EAP-UpMid2014",
        "yCap": 11538.94,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "EAP-UpMid2015",
        "yCap": 12090.66,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "EAP-UpMid2016",
        "yCap": 12467.12,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "EAP-UpMid2017",
        "yCap": 12838.03,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "EAP-UpMid2018",
        "yCap": 13256.04,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "EAP-UpMid2019",
        "yCap": 13663.83,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "EAP-UpMid2020",
        "yCap": 12850.38,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "EAP-UpMid2021",
        "yCap": 12773.27,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - Upper Middle Income",
        "Alpha-3": "EAP-UpMid",
        "Alpha-2": "EAP-UpMid",
        "Region": "EAP-UpMid",
        "IncGroup": "EAP-UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "EAP-UpMid2022",
        "yCap": 12773.27,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "EAP-High1990",
        "yCap": 32690.08,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "EAP-High1991",
        "yCap": 33029.9,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "EAP-High1992",
        "yCap": 34131.5,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "EAP-High1993",
        "yCap": 34982.87,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "EAP-High1994",
        "yCap": 36050.51,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "EAP-High1995",
        "yCap": 36951.89,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "EAP-High1996",
        "yCap": 37421.56,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "EAP-High1997",
        "yCap": 37696.29,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "EAP-High1998",
        "yCap": 36473.63,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "EAP-High1999",
        "yCap": 37173.84,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "EAP-High2000",
        "yCap": 38613.76,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "EAP-High2001",
        "yCap": 38741.08,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "EAP-High2002",
        "yCap": 39882.72,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "EAP-High2003",
        "yCap": 41294.73,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "EAP-High2004",
        "yCap": 44070.05,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "EAP-High2005",
        "yCap": 45403.87,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "EAP-High2006",
        "yCap": 47617.2,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "EAP-High2007",
        "yCap": 49498.31,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "EAP-High2008",
        "yCap": 49230.74,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "EAP-High2009",
        "yCap": 48236.64,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "EAP-High2010",
        "yCap": 52394.56,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "EAP-High2011",
        "yCap": 55819.43,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "EAP-High2012",
        "yCap": 57450.15,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "EAP-High2013",
        "yCap": 59626.57,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "EAP-High2014",
        "yCap": 59601.45,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "EAP-High2015",
        "yCap": 56198.69,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "EAP-High2016",
        "yCap": 56241.02,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "EAP-High2017",
        "yCap": 58118.91,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "EAP-High2018",
        "yCap": 59478.86,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "EAP-High2019",
        "yCap": 59007.15,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "EAP-High2020",
        "yCap": 50204.1,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "EAP-High2021",
        "yCap": 53398.69,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "East Asia and the Pacific - High Income",
        "Alpha-3": "EAP-High",
        "Alpha-2": "EAP-High",
        "Region": "EAP-High",
        "IncGroup": "EAP-High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "EAP-High2022",
        "yCap": 53398.69,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1990,
        "ID": "ECA-Low1990",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1991,
        "ID": "ECA-Low1991",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1992,
        "ID": "ECA-Low1992",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1993,
        "ID": "ECA-Low1993",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1994,
        "ID": "ECA-Low1994",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1995,
        "ID": "ECA-Low1995",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1996,
        "ID": "ECA-Low1996",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1997,
        "ID": "ECA-Low1997",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1998,
        "ID": "ECA-Low1998",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 1999,
        "ID": "ECA-Low1999",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2000,
        "ID": "ECA-Low2000",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2001,
        "ID": "ECA-Low2001",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2002,
        "ID": "ECA-Low2002",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2003,
        "ID": "ECA-Low2003",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2004,
        "ID": "ECA-Low2004",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2005,
        "ID": "ECA-Low2005",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2006,
        "ID": "ECA-Low2006",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2007,
        "ID": "ECA-Low2007",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2008,
        "ID": "ECA-Low2008",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2009,
        "ID": "ECA-Low2009",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2010,
        "ID": "ECA-Low2010",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2011,
        "ID": "ECA-Low2011",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2012,
        "ID": "ECA-Low2012",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2013,
        "ID": "ECA-Low2013",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2014,
        "ID": "ECA-Low2014",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2015,
        "ID": "ECA-Low2015",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2016,
        "ID": "ECA-Low2016",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2017,
        "ID": "ECA-Low2017",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2018,
        "ID": "ECA-Low2018",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2019,
        "ID": "ECA-Low2019",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2020,
        "ID": "ECA-Low2020",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2021,
        "ID": "ECA-Low2021",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Low Income",
        "Alpha-3": "ECA-Low",
        "Alpha-2": "ECA-Low",
        "Region": "ECA-Low",
        "IncGroup": "ECA-Low",
        "RegIncGrp": "ECA-Low",
        "Time": 2022,
        "ID": "ECA-Low2022",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1990,
        "ID": "ECA-LowMid1990",
        "yCap": 7304.14,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1991,
        "ID": "ECA-LowMid1991",
        "yCap": 6699.75,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1992,
        "ID": "ECA-LowMid1992",
        "yCap": 5755.54,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1993,
        "ID": "ECA-LowMid1993",
        "yCap": 4978.95,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1994,
        "ID": "ECA-LowMid1994",
        "yCap": 3992.36,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1995,
        "ID": "ECA-LowMid1995",
        "yCap": 3617.5,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1996,
        "ID": "ECA-LowMid1996",
        "yCap": 3405.13,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1997,
        "ID": "ECA-LowMid1997",
        "yCap": 3444.86,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1998,
        "ID": "ECA-LowMid1998",
        "yCap": 3462.23,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1999,
        "ID": "ECA-LowMid1999",
        "yCap": 3522.3,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2000,
        "ID": "ECA-LowMid2000",
        "yCap": 3714.01,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2001,
        "ID": "ECA-LowMid2001",
        "yCap": 3978.78,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2002,
        "ID": "ECA-LowMid2002",
        "yCap": 4152.81,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2003,
        "ID": "ECA-LowMid2003",
        "yCap": 4485.4,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2004,
        "ID": "ECA-LowMid2004",
        "yCap": 4924.09,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2005,
        "ID": "ECA-LowMid2005",
        "yCap": 5087.2,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2006,
        "ID": "ECA-LowMid2006",
        "yCap": 5419.27,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2007,
        "ID": "ECA-LowMid2007",
        "yCap": 5863.49,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2008,
        "ID": "ECA-LowMid2008",
        "yCap": 6134.63,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2009,
        "ID": "ECA-LowMid2009",
        "yCap": 5725.62,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2010,
        "ID": "ECA-LowMid2010",
        "yCap": 5919.12,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2011,
        "ID": "ECA-LowMid2011",
        "yCap": 6233.82,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2012,
        "ID": "ECA-LowMid2012",
        "yCap": 6331.85,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2013,
        "ID": "ECA-LowMid2013",
        "yCap": 6543.6,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2014,
        "ID": "ECA-LowMid2014",
        "yCap": 6510.33,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2015,
        "ID": "ECA-LowMid2015",
        "yCap": 6345.59,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2016,
        "ID": "ECA-LowMid2016",
        "yCap": 6550.97,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2017,
        "ID": "ECA-LowMid2017",
        "yCap": 6746.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2018,
        "ID": "ECA-LowMid2018",
        "yCap": 6989.52,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2019,
        "ID": "ECA-LowMid2019",
        "yCap": 7246.69,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2020,
        "ID": "ECA-LowMid2020",
        "yCap": 7032.54,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2021,
        "ID": "ECA-LowMid2021",
        "yCap": 7349.18,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Lower Middle Income",
        "Alpha-3": "ECA-LowMid",
        "Alpha-2": "ECA-LowMid",
        "Region": "ECA-LowMid",
        "IncGroup": "ECA-LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2022,
        "ID": "ECA-LowMid2022",
        "yCap": 7349.18,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "ECA-UpMid1990",
        "yCap": 9416.7,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "ECA-UpMid1991",
        "yCap": 8815.28,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "ECA-UpMid1992",
        "yCap": 7920.24,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "ECA-UpMid1993",
        "yCap": 7559.22,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "ECA-UpMid1994",
        "yCap": 7156.35,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "ECA-UpMid1995",
        "yCap": 7139.41,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "ECA-UpMid1996",
        "yCap": 7423.68,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "ECA-UpMid1997",
        "yCap": 7557.5,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "ECA-UpMid1998",
        "yCap": 7774.64,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "ECA-UpMid1999",
        "yCap": 7795.26,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "ECA-UpMid2000",
        "yCap": 8275.14,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "ECA-UpMid2001",
        "yCap": 8573.73,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "ECA-UpMid2002",
        "yCap": 9038.39,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "ECA-UpMid2003",
        "yCap": 9566.19,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "ECA-UpMid2004",
        "yCap": 10257.42,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "ECA-UpMid2005",
        "yCap": 11058.67,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "ECA-UpMid2006",
        "yCap": 12006.97,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "ECA-UpMid2007",
        "yCap": 12980.18,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "ECA-UpMid2008",
        "yCap": 13711.06,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "ECA-UpMid2009",
        "yCap": 13365.04,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "ECA-UpMid2010",
        "yCap": 13970.21,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "ECA-UpMid2011",
        "yCap": 14629.62,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "ECA-UpMid2012",
        "yCap": 14951.81,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "ECA-UpMid2013",
        "yCap": 15491.13,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "ECA-UpMid2014",
        "yCap": 15844.49,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "ECA-UpMid2015",
        "yCap": 16136.18,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "ECA-UpMid2016",
        "yCap": 16425.99,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "ECA-UpMid2017",
        "yCap": 17023.8,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "ECA-UpMid2018",
        "yCap": 17646.53,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "ECA-UpMid2019",
        "yCap": 18268.19,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "ECA-UpMid2020",
        "yCap": 17561.68,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "ECA-UpMid2021",
        "yCap": 18859.41,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - Upper Middle Income",
        "Alpha-3": "ECA-UpMid",
        "Alpha-2": "ECA-UpMid",
        "Region": "ECA-UpMid",
        "IncGroup": "ECA-UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "ECA-UpMid2022",
        "yCap": 18859.41,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "ECA-High1990",
        "yCap": 29914.32,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "ECA-High1991",
        "yCap": 30026.55,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "ECA-High1992",
        "yCap": 30144.86,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "ECA-High1993",
        "yCap": 30127.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "ECA-High1994",
        "yCap": 30815.35,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "ECA-High1995",
        "yCap": 31516.64,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "ECA-High1996",
        "yCap": 32222.09,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "ECA-High1997",
        "yCap": 33372.13,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "ECA-High1998",
        "yCap": 34640.06,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "ECA-High1999",
        "yCap": 35873.69,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "ECA-High2000",
        "yCap": 37275.48,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "ECA-High2001",
        "yCap": 38165.81,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "ECA-High2002",
        "yCap": 38740.43,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "ECA-High2003",
        "yCap": 39382.14,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "ECA-High2004",
        "yCap": 40722.54,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "ECA-High2005",
        "yCap": 41782.81,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "ECA-High2006",
        "yCap": 43366.31,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "ECA-High2007",
        "yCap": 45138.52,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "ECA-High2008",
        "yCap": 45087.51,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "ECA-High2009",
        "yCap": 42434.84,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "ECA-High2010",
        "yCap": 42741.78,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "ECA-High2011",
        "yCap": 42896.68,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "ECA-High2012",
        "yCap": 42517.88,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "ECA-High2013",
        "yCap": 42551.89,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "ECA-High2014",
        "yCap": 43175.86,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "ECA-High2015",
        "yCap": 44396.49,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "ECA-High2016",
        "yCap": 45313.96,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "ECA-High2017",
        "yCap": 46492.15,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "ECA-High2018",
        "yCap": 47589.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "ECA-High2019",
        "yCap": 48495.92,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "ECA-High2020",
        "yCap": 46427.34,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "ECA-High2021",
        "yCap": 48973.4,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Europe and Central Asia - High Income",
        "Alpha-3": "ECA-High",
        "Alpha-2": "ECA-High",
        "Region": "ECA-High",
        "IncGroup": "ECA-High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "ECA-High2022",
        "yCap": 48973.4,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1990,
        "ID": "LAC-Low1990",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1991,
        "ID": "LAC-Low1991",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1992,
        "ID": "LAC-Low1992",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1993,
        "ID": "LAC-Low1993",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1994,
        "ID": "LAC-Low1994",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1995,
        "ID": "LAC-Low1995",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1996,
        "ID": "LAC-Low1996",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1997,
        "ID": "LAC-Low1997",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1998,
        "ID": "LAC-Low1998",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 1999,
        "ID": "LAC-Low1999",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2000,
        "ID": "LAC-Low2000",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2001,
        "ID": "LAC-Low2001",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2002,
        "ID": "LAC-Low2002",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2003,
        "ID": "LAC-Low2003",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2004,
        "ID": "LAC-Low2004",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2005,
        "ID": "LAC-Low2005",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2006,
        "ID": "LAC-Low2006",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2007,
        "ID": "LAC-Low2007",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2008,
        "ID": "LAC-Low2008",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2009,
        "ID": "LAC-Low2009",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2010,
        "ID": "LAC-Low2010",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2011,
        "ID": "LAC-Low2011",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2012,
        "ID": "LAC-Low2012",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2013,
        "ID": "LAC-Low2013",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2014,
        "ID": "LAC-Low2014",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2015,
        "ID": "LAC-Low2015",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2016,
        "ID": "LAC-Low2016",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2017,
        "ID": "LAC-Low2017",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2018,
        "ID": "LAC-Low2018",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2019,
        "ID": "LAC-Low2019",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2020,
        "ID": "LAC-Low2020",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2021,
        "ID": "LAC-Low2021",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Low Income",
        "Alpha-3": "LAC-Low",
        "Alpha-2": "LAC-Low",
        "Region": "LAC-Low",
        "IncGroup": "LAC-Low",
        "RegIncGrp": "LAC-Low",
        "Time": 2022,
        "ID": "LAC-Low2022",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1990,
        "ID": "LAC-LowMid1990",
        "yCap": 4110.26,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1991,
        "ID": "LAC-LowMid1991",
        "yCap": 4070.33,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1992,
        "ID": "LAC-LowMid1992",
        "yCap": 4080.36,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1993,
        "ID": "LAC-LowMid1993",
        "yCap": 4109.49,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1994,
        "ID": "LAC-LowMid1994",
        "yCap": 4077.4,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1995,
        "ID": "LAC-LowMid1995",
        "yCap": 4238.34,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1996,
        "ID": "LAC-LowMid1996",
        "yCap": 4296.23,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1997,
        "ID": "LAC-LowMid1997",
        "yCap": 4389.16,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1998,
        "ID": "LAC-LowMid1998",
        "yCap": 4469.4,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1999,
        "ID": "LAC-LowMid1999",
        "yCap": 4491.52,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2000,
        "ID": "LAC-LowMid2000",
        "yCap": 4554.07,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2001,
        "ID": "LAC-LowMid2001",
        "yCap": 4557.78,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2002,
        "ID": "LAC-LowMid2002",
        "yCap": 4581.15,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2003,
        "ID": "LAC-LowMid2003",
        "yCap": 4644.07,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2004,
        "ID": "LAC-LowMid2004",
        "yCap": 4725.21,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2005,
        "ID": "LAC-LowMid2005",
        "yCap": 4853.27,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2006,
        "ID": "LAC-LowMid2006",
        "yCap": 5008.61,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2007,
        "ID": "LAC-LowMid2007",
        "yCap": 5149.88,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2008,
        "ID": "LAC-LowMid2008",
        "yCap": 5270.11,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2009,
        "ID": "LAC-LowMid2009",
        "yCap": 5190.51,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2010,
        "ID": "LAC-LowMid2010",
        "yCap": 5244.41,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2011,
        "ID": "LAC-LowMid2011",
        "yCap": 5423.56,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2012,
        "ID": "LAC-LowMid2012",
        "yCap": 5568.24,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2013,
        "ID": "LAC-LowMid2013",
        "yCap": 5727.16,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2014,
        "ID": "LAC-LowMid2014",
        "yCap": 5850.95,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2015,
        "ID": "LAC-LowMid2015",
        "yCap": 5995.35,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2016,
        "ID": "LAC-LowMid2016",
        "yCap": 6131.84,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2017,
        "ID": "LAC-LowMid2017",
        "yCap": 6282.74,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2018,
        "ID": "LAC-LowMid2018",
        "yCap": 6334.71,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2019,
        "ID": "LAC-LowMid2019",
        "yCap": 6322.88,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2020,
        "ID": "LAC-LowMid2020",
        "yCap": 5823.23,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2021,
        "ID": "LAC-LowMid2021",
        "yCap": 6243.86,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Lower Middle Income",
        "Alpha-3": "LAC-LowMid",
        "Alpha-2": "LAC-LowMid",
        "Region": "LAC-LowMid",
        "IncGroup": "LAC-LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2022,
        "ID": "LAC-LowMid2022",
        "yCap": 6243.86,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "LAC-UpMid1990",
        "yCap": 8887.46,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "LAC-UpMid1991",
        "yCap": 9063.19,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "LAC-UpMid1992",
        "yCap": 9310.66,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "LAC-UpMid1993",
        "yCap": 9506.91,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "LAC-UpMid1994",
        "yCap": 9728.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "LAC-UpMid1995",
        "yCap": 9776.24,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "LAC-UpMid1996",
        "yCap": 9929.5,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "LAC-UpMid1997",
        "yCap": 10230.44,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "LAC-UpMid1998",
        "yCap": 10436.46,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "LAC-UpMid1999",
        "yCap": 10426.31,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "LAC-UpMid2000",
        "yCap": 10556.86,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "LAC-UpMid2001",
        "yCap": 10498.98,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "LAC-UpMid2002",
        "yCap": 10493.68,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "LAC-UpMid2003",
        "yCap": 10833.46,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "LAC-UpMid2004",
        "yCap": 11221.9,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "LAC-UpMid2005",
        "yCap": 11562.08,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "LAC-UpMid2006",
        "yCap": 12041.36,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "LAC-UpMid2007",
        "yCap": 12565.51,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "LAC-UpMid2008",
        "yCap": 12881.74,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "LAC-UpMid2009",
        "yCap": 12575.41,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "LAC-UpMid2010",
        "yCap": 13013.72,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "LAC-UpMid2011",
        "yCap": 13406.08,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "LAC-UpMid2012",
        "yCap": 13549.94,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "LAC-UpMid2013",
        "yCap": 13799.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "LAC-UpMid2014",
        "yCap": 14018.2,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "LAC-UpMid2015",
        "yCap": 14121.02,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "LAC-UpMid2016",
        "yCap": 14226.72,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "LAC-UpMid2017",
        "yCap": 14402.82,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "LAC-UpMid2018",
        "yCap": 14633.99,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "LAC-UpMid2019",
        "yCap": 14712,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "LAC-UpMid2020",
        "yCap": 13581.89,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "LAC-UpMid2021",
        "yCap": 14546.43,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - Upper Middle Income",
        "Alpha-3": "LAC-UpMid",
        "Alpha-2": "LAC-UpMid",
        "Region": "LAC-UpMid",
        "IncGroup": "LAC-UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "LAC-UpMid2022",
        "yCap": 14546.43,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "LAC-High1990",
        "yCap": 25315.74,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "LAC-High1991",
        "yCap": 25381.48,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "LAC-High1992",
        "yCap": 25570.61,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "LAC-High1993",
        "yCap": 25860.35,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "LAC-High1994",
        "yCap": 26287.52,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "LAC-High1995",
        "yCap": 26447.13,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "LAC-High1996",
        "yCap": 26784.58,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "LAC-High1997",
        "yCap": 27463.19,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "LAC-High1998",
        "yCap": 27620.54,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "LAC-High1999",
        "yCap": 28022.55,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "LAC-High2000",
        "yCap": 28582.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "LAC-High2001",
        "yCap": 28825.07,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "LAC-High2002",
        "yCap": 28874.48,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "LAC-High2003",
        "yCap": 29035.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "LAC-High2004",
        "yCap": 29762.03,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "LAC-High2005",
        "yCap": 30217,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "LAC-High2006",
        "yCap": 30868.81,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "LAC-High2007",
        "yCap": 31400.84,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "LAC-High2008",
        "yCap": 31545.57,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "LAC-High2009",
        "yCap": 29952.34,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "LAC-High2010",
        "yCap": 29773.09,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "LAC-High2011",
        "yCap": 30189.64,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "LAC-High2012",
        "yCap": 30365.33,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "LAC-High2013",
        "yCap": 30460.25,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "LAC-High2014",
        "yCap": 30826.99,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "LAC-High2015",
        "yCap": 31110.28,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "LAC-High2016",
        "yCap": 31224.78,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "LAC-High2017",
        "yCap": 31110.52,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "LAC-High2018",
        "yCap": 31325.93,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "LAC-High2019",
        "yCap": 31640.93,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "LAC-High2020",
        "yCap": 27502.94,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "LAC-High2021",
        "yCap": 28809.2,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latin America and the Caribbean - High Income",
        "Alpha-3": "LAC-High",
        "Alpha-2": "LAC-High",
        "Region": "LAC-High",
        "IncGroup": "LAC-High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "LAC-High2022",
        "yCap": 28809.2,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1990,
        "ID": "MENA-Low1990",
        "yCap": "..",
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1991,
        "ID": "MENA-Low1991",
        "yCap": "..",
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1992,
        "ID": "MENA-Low1992",
        "yCap": "..",
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1993,
        "ID": "MENA-Low1993",
        "yCap": "..",
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1994,
        "ID": "MENA-Low1994",
        "yCap": "..",
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1995,
        "ID": "MENA-Low1995",
        "yCap": "..",
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1996,
        "ID": "MENA-Low1996",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1997,
        "ID": "MENA-Low1997",
        "yCap": "..",
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1998,
        "ID": "MENA-Low1998",
        "yCap": "..",
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1999,
        "ID": "MENA-Low1999",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2000,
        "ID": "MENA-Low2000",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2001,
        "ID": "MENA-Low2001",
        "yCap": "..",
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2002,
        "ID": "MENA-Low2002",
        "yCap": "..",
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2003,
        "ID": "MENA-Low2003",
        "yCap": "..",
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2004,
        "ID": "MENA-Low2004",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2005,
        "ID": "MENA-Low2005",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2006,
        "ID": "MENA-Low2006",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2007,
        "ID": "MENA-Low2007",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2008,
        "ID": "MENA-Low2008",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2009,
        "ID": "MENA-Low2009",
        "yCap": "..",
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2010,
        "ID": "MENA-Low2010",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2011,
        "ID": "MENA-Low2011",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2012,
        "ID": "MENA-Low2012",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2013,
        "ID": "MENA-Low2013",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2014,
        "ID": "MENA-Low2014",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2015,
        "ID": "MENA-Low2015",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2016,
        "ID": "MENA-Low2016",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2017,
        "ID": "MENA-Low2017",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2018,
        "ID": "MENA-Low2018",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2019,
        "ID": "MENA-Low2019",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2020,
        "ID": "MENA-Low2020",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2021,
        "ID": "MENA-Low2021",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Low Income",
        "Alpha-3": "MENA-Low",
        "Alpha-2": "MENA-Low",
        "Region": "MENA-Low",
        "IncGroup": "MENA-Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2022,
        "ID": "MENA-Low2022",
        "yCap": "..",
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1990,
        "ID": "MENA-LowMid1990",
        "yCap": 5872.06,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1991,
        "ID": "MENA-LowMid1991",
        "yCap": 6300.15,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1992,
        "ID": "MENA-LowMid1992",
        "yCap": 6494.16,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1993,
        "ID": "MENA-LowMid1993",
        "yCap": 6522.72,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1994,
        "ID": "MENA-LowMid1994",
        "yCap": 6610.77,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1995,
        "ID": "MENA-LowMid1995",
        "yCap": 6707.87,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1996,
        "ID": "MENA-LowMid1996",
        "yCap": 7026.04,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1997,
        "ID": "MENA-LowMid1997",
        "yCap": 7098.35,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1998,
        "ID": "MENA-LowMid1998",
        "yCap": 7317.9,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1999,
        "ID": "MENA-LowMid1999",
        "yCap": 7413.5,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2000,
        "ID": "MENA-LowMid2000",
        "yCap": 7493.21,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2001,
        "ID": "MENA-LowMid2001",
        "yCap": 7559.57,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2002,
        "ID": "MENA-LowMid2002",
        "yCap": 7680.03,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2003,
        "ID": "MENA-LowMid2003",
        "yCap": 8011.25,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2004,
        "ID": "MENA-LowMid2004",
        "yCap": 8355.75,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2005,
        "ID": "MENA-LowMid2005",
        "yCap": 8568.34,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2006,
        "ID": "MENA-LowMid2006",
        "yCap": 8737.84,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2007,
        "ID": "MENA-LowMid2007",
        "yCap": 9144.58,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2008,
        "ID": "MENA-LowMid2008",
        "yCap": 9426.8,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2009,
        "ID": "MENA-LowMid2009",
        "yCap": 9711.65,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2010,
        "ID": "MENA-LowMid2010",
        "yCap": 10068.64,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2011,
        "ID": "MENA-LowMid2011",
        "yCap": 10130.31,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2012,
        "ID": "MENA-LowMid2012",
        "yCap": 10126.43,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2013,
        "ID": "MENA-LowMid2013",
        "yCap": 10011.78,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2014,
        "ID": "MENA-LowMid2014",
        "yCap": 10100.92,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2015,
        "ID": "MENA-LowMid2015",
        "yCap": 10116.65,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2016,
        "ID": "MENA-LowMid2016",
        "yCap": 10429.58,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2017,
        "ID": "MENA-LowMid2017",
        "yCap": 10608.35,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2018,
        "ID": "MENA-LowMid2018",
        "yCap": 10641.52,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2019,
        "ID": "MENA-LowMid2019",
        "yCap": 10549.56,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2020,
        "ID": "MENA-LowMid2020",
        "yCap": 9773.93,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2021,
        "ID": "MENA-LowMid2021",
        "yCap": 9950.05,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Lower Middle Income",
        "Alpha-3": "MENA-LowMid",
        "Alpha-2": "MENA-LowMid",
        "Region": "MENA-LowMid",
        "IncGroup": "MENA-LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2022,
        "ID": "MENA-LowMid2022",
        "yCap": 9950.05,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1990,
        "ID": "MENA-UpMid1990",
        "yCap": 14081.85,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1991,
        "ID": "MENA-UpMid1991",
        "yCap": 12386.13,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1992,
        "ID": "MENA-UpMid1992",
        "yCap": 12859.74,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1993,
        "ID": "MENA-UpMid1993",
        "yCap": 13109.65,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1994,
        "ID": "MENA-UpMid1994",
        "yCap": 13095.26,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1995,
        "ID": "MENA-UpMid1995",
        "yCap": 13130.87,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1996,
        "ID": "MENA-UpMid1996",
        "yCap": 13198.3,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1997,
        "ID": "MENA-UpMid1997",
        "yCap": 13473.81,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1998,
        "ID": "MENA-UpMid1998",
        "yCap": 14025.76,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1999,
        "ID": "MENA-UpMid1999",
        "yCap": 14372.92,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2000,
        "ID": "MENA-UpMid2000",
        "yCap": 14925.58,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2001,
        "ID": "MENA-UpMid2001",
        "yCap": 14611.61,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2002,
        "ID": "MENA-UpMid2002",
        "yCap": 14110.02,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2003,
        "ID": "MENA-UpMid2003",
        "yCap": 14036.15,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2004,
        "ID": "MENA-UpMid2004",
        "yCap": 15164.45,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2005,
        "ID": "MENA-UpMid2005",
        "yCap": 16162.53,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2006,
        "ID": "MENA-UpMid2006",
        "yCap": 16732.1,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2007,
        "ID": "MENA-UpMid2007",
        "yCap": 17289.37,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2008,
        "ID": "MENA-UpMid2008",
        "yCap": 17362.25,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2009,
        "ID": "MENA-UpMid2009",
        "yCap": 16766.99,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2010,
        "ID": "MENA-UpMid2010",
        "yCap": 17146.45,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2011,
        "ID": "MENA-UpMid2011",
        "yCap": 12244.89,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2012,
        "ID": "MENA-UpMid2012",
        "yCap": 17822.96,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2013,
        "ID": "MENA-UpMid2013",
        "yCap": 15651.31,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2014,
        "ID": "MENA-UpMid2014",
        "yCap": 13123.37,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2015,
        "ID": "MENA-UpMid2015",
        "yCap": 12797.62,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2016,
        "ID": "MENA-UpMid2016",
        "yCap": 12863.93,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2017,
        "ID": "MENA-UpMid2017",
        "yCap": 14606.29,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2018,
        "ID": "MENA-UpMid2018",
        "yCap": 15104.58,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2019,
        "ID": "MENA-UpMid2019",
        "yCap": 14128.64,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2020,
        "ID": "MENA-UpMid2020",
        "yCap": 11682.44,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2021,
        "ID": "MENA-UpMid2021",
        "yCap": 13383.57,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - Upper Middle Income",
        "Alpha-3": "MENA-UpMid",
        "Alpha-2": "MENA-UpMid",
        "Region": "MENA-UpMid",
        "IncGroup": "MENA-UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2022,
        "ID": "MENA-UpMid2022",
        "yCap": 13383.57,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1990,
        "ID": "MENA-High1990",
        "yCap": 47372.78,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1991,
        "ID": "MENA-High1991",
        "yCap": 47773.08,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1992,
        "ID": "MENA-High1992",
        "yCap": 47902.96,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1993,
        "ID": "MENA-High1993",
        "yCap": 49420,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1994,
        "ID": "MENA-High1994",
        "yCap": 50109.14,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1995,
        "ID": "MENA-High1995",
        "yCap": 50809.58,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1996,
        "ID": "MENA-High1996",
        "yCap": 50925.97,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1997,
        "ID": "MENA-High1997",
        "yCap": 51282.14,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1998,
        "ID": "MENA-High1998",
        "yCap": 50919.55,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 1999,
        "ID": "MENA-High1999",
        "yCap": 50046.61,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2000,
        "ID": "MENA-High2000",
        "yCap": 51984.64,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2001,
        "ID": "MENA-High2001",
        "yCap": 51028.42,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2002,
        "ID": "MENA-High2002",
        "yCap": 50608.84,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2003,
        "ID": "MENA-High2003",
        "yCap": 52372.47,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2004,
        "ID": "MENA-High2004",
        "yCap": 55337.82,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2005,
        "ID": "MENA-High2005",
        "yCap": 55729.5,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2006,
        "ID": "MENA-High2006",
        "yCap": 56266.81,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2007,
        "ID": "MENA-High2007",
        "yCap": 54727.79,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2008,
        "ID": "MENA-High2008",
        "yCap": 53736.4,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2009,
        "ID": "MENA-High2009",
        "yCap": 50798.82,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2010,
        "ID": "MENA-High2010",
        "yCap": 51600.05,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2011,
        "ID": "MENA-High2011",
        "yCap": 53455.28,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2012,
        "ID": "MENA-High2012",
        "yCap": 53935.88,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2013,
        "ID": "MENA-High2013",
        "yCap": 53965.29,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2014,
        "ID": "MENA-High2014",
        "yCap": 53878.4,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2015,
        "ID": "MENA-High2015",
        "yCap": 54011.96,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2016,
        "ID": "MENA-High2016",
        "yCap": 53999.27,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2017,
        "ID": "MENA-High2017",
        "yCap": 53117.59,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2018,
        "ID": "MENA-High2018",
        "yCap": 53186.27,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2019,
        "ID": "MENA-High2019",
        "yCap": 53088.92,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2020,
        "ID": "MENA-High2020",
        "yCap": 50566.66,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2021,
        "ID": "MENA-High2021",
        "yCap": 52656.53,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Middle East and North Africa - High Income",
        "Alpha-3": "MENA-High",
        "Alpha-2": "MENA-High",
        "Region": "MENA-High",
        "IncGroup": "MENA-High",
        "RegIncGrp": "MENA-High",
        "Time": 2022,
        "ID": "MENA-High2022",
        "yCap": 52656.53,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1990,
        "ID": "NRTHAM-Low1990",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1991,
        "ID": "NRTHAM-Low1991",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1992,
        "ID": "NRTHAM-Low1992",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1993,
        "ID": "NRTHAM-Low1993",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1994,
        "ID": "NRTHAM-Low1994",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1995,
        "ID": "NRTHAM-Low1995",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1996,
        "ID": "NRTHAM-Low1996",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1997,
        "ID": "NRTHAM-Low1997",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1998,
        "ID": "NRTHAM-Low1998",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 1999,
        "ID": "NRTHAM-Low1999",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2000,
        "ID": "NRTHAM-Low2000",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2001,
        "ID": "NRTHAM-Low2001",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2002,
        "ID": "NRTHAM-Low2002",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2003,
        "ID": "NRTHAM-Low2003",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2004,
        "ID": "NRTHAM-Low2004",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2005,
        "ID": "NRTHAM-Low2005",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2006,
        "ID": "NRTHAM-Low2006",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2007,
        "ID": "NRTHAM-Low2007",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2008,
        "ID": "NRTHAM-Low2008",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2009,
        "ID": "NRTHAM-Low2009",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2010,
        "ID": "NRTHAM-Low2010",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2011,
        "ID": "NRTHAM-Low2011",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2012,
        "ID": "NRTHAM-Low2012",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2013,
        "ID": "NRTHAM-Low2013",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2014,
        "ID": "NRTHAM-Low2014",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2015,
        "ID": "NRTHAM-Low2015",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2016,
        "ID": "NRTHAM-Low2016",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2017,
        "ID": "NRTHAM-Low2017",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2018,
        "ID": "NRTHAM-Low2018",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2019,
        "ID": "NRTHAM-Low2019",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2020,
        "ID": "NRTHAM-Low2020",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2021,
        "ID": "NRTHAM-Low2021",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Low Income",
        "Alpha-3": "NRTHAM-Low",
        "Alpha-2": "NRTHAM-Low",
        "Region": "NRTHAM-Low",
        "IncGroup": "NRTHAM-Low",
        "RegIncGrp": "NRTHAM-Low",
        "Time": 2022,
        "ID": "NRTHAM-Low2022",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1990,
        "ID": "NRTHAM-LowMid1990",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1991,
        "ID": "NRTHAM-LowMid1991",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1992,
        "ID": "NRTHAM-LowMid1992",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1993,
        "ID": "NRTHAM-LowMid1993",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1994,
        "ID": "NRTHAM-LowMid1994",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1995,
        "ID": "NRTHAM-LowMid1995",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1996,
        "ID": "NRTHAM-LowMid1996",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1997,
        "ID": "NRTHAM-LowMid1997",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1998,
        "ID": "NRTHAM-LowMid1998",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 1999,
        "ID": "NRTHAM-LowMid1999",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2000,
        "ID": "NRTHAM-LowMid2000",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2001,
        "ID": "NRTHAM-LowMid2001",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2002,
        "ID": "NRTHAM-LowMid2002",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2003,
        "ID": "NRTHAM-LowMid2003",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2004,
        "ID": "NRTHAM-LowMid2004",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2005,
        "ID": "NRTHAM-LowMid2005",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2006,
        "ID": "NRTHAM-LowMid2006",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2007,
        "ID": "NRTHAM-LowMid2007",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2008,
        "ID": "NRTHAM-LowMid2008",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2009,
        "ID": "NRTHAM-LowMid2009",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2010,
        "ID": "NRTHAM-LowMid2010",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2011,
        "ID": "NRTHAM-LowMid2011",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2012,
        "ID": "NRTHAM-LowMid2012",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2013,
        "ID": "NRTHAM-LowMid2013",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2014,
        "ID": "NRTHAM-LowMid2014",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2015,
        "ID": "NRTHAM-LowMid2015",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2016,
        "ID": "NRTHAM-LowMid2016",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2017,
        "ID": "NRTHAM-LowMid2017",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2018,
        "ID": "NRTHAM-LowMid2018",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2019,
        "ID": "NRTHAM-LowMid2019",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2020,
        "ID": "NRTHAM-LowMid2020",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2021,
        "ID": "NRTHAM-LowMid2021",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Lower Middle Income",
        "Alpha-3": "NRTHAM-LowMid",
        "Alpha-2": "NRTHAM-LowMid",
        "Region": "NRTHAM-LowMid",
        "IncGroup": "NRTHAM-LowMid",
        "RegIncGrp": "NRTHAM-LowMid",
        "Time": 2022,
        "ID": "NRTHAM-LowMid2022",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1990,
        "ID": "NRTHAM-UpMid1990",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1991,
        "ID": "NRTHAM-UpMid1991",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1992,
        "ID": "NRTHAM-UpMid1992",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1993,
        "ID": "NRTHAM-UpMid1993",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1994,
        "ID": "NRTHAM-UpMid1994",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1995,
        "ID": "NRTHAM-UpMid1995",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1996,
        "ID": "NRTHAM-UpMid1996",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1997,
        "ID": "NRTHAM-UpMid1997",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1998,
        "ID": "NRTHAM-UpMid1998",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 1999,
        "ID": "NRTHAM-UpMid1999",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2000,
        "ID": "NRTHAM-UpMid2000",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2001,
        "ID": "NRTHAM-UpMid2001",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2002,
        "ID": "NRTHAM-UpMid2002",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2003,
        "ID": "NRTHAM-UpMid2003",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2004,
        "ID": "NRTHAM-UpMid2004",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2005,
        "ID": "NRTHAM-UpMid2005",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2006,
        "ID": "NRTHAM-UpMid2006",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2007,
        "ID": "NRTHAM-UpMid2007",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2008,
        "ID": "NRTHAM-UpMid2008",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2009,
        "ID": "NRTHAM-UpMid2009",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2010,
        "ID": "NRTHAM-UpMid2010",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2011,
        "ID": "NRTHAM-UpMid2011",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2012,
        "ID": "NRTHAM-UpMid2012",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2013,
        "ID": "NRTHAM-UpMid2013",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2014,
        "ID": "NRTHAM-UpMid2014",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2015,
        "ID": "NRTHAM-UpMid2015",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2016,
        "ID": "NRTHAM-UpMid2016",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2017,
        "ID": "NRTHAM-UpMid2017",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2018,
        "ID": "NRTHAM-UpMid2018",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2019,
        "ID": "NRTHAM-UpMid2019",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2020,
        "ID": "NRTHAM-UpMid2020",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2021,
        "ID": "NRTHAM-UpMid2021",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - Upper Middle Income",
        "Alpha-3": "NRTHAM-UpMid",
        "Alpha-2": "NRTHAM-UpMid",
        "Region": "NRTHAM-UpMid",
        "IncGroup": "NRTHAM-UpMid",
        "RegIncGrp": "NRTHAM-UpMid",
        "Time": 2022,
        "ID": "NRTHAM-UpMid2022",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1990,
        "ID": "NRTHAM-High1990",
        "yCap": 47776.04,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1991,
        "ID": "NRTHAM-High1991",
        "yCap": 46915.13,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1992,
        "ID": "NRTHAM-High1992",
        "yCap": 47335.98,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1993,
        "ID": "NRTHAM-High1993",
        "yCap": 48054.23,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1994,
        "ID": "NRTHAM-High1994",
        "yCap": 48413.12,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1995,
        "ID": "NRTHAM-High1995",
        "yCap": 49423.71,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1996,
        "ID": "NRTHAM-High1996",
        "yCap": 50236.14,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1997,
        "ID": "NRTHAM-High1997",
        "yCap": 51626.87,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1998,
        "ID": "NRTHAM-High1998",
        "yCap": 53255.08,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1999,
        "ID": "NRTHAM-High1999",
        "yCap": 55064.44,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2000,
        "ID": "NRTHAM-High2000",
        "yCap": 58231.97,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2001,
        "ID": "NRTHAM-High2001",
        "yCap": 60003.81,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2002,
        "ID": "NRTHAM-High2002",
        "yCap": 59771.95,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2003,
        "ID": "NRTHAM-High2003",
        "yCap": 61008.05,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2004,
        "ID": "NRTHAM-High2004",
        "yCap": 62298.49,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2005,
        "ID": "NRTHAM-High2005",
        "yCap": 63377.63,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2006,
        "ID": "NRTHAM-High2006",
        "yCap": 65442.13,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2007,
        "ID": "NRTHAM-High2007",
        "yCap": 66679.56,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2008,
        "ID": "NRTHAM-High2008",
        "yCap": 65713.7,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2009,
        "ID": "NRTHAM-High2009",
        "yCap": 62495.6,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2010,
        "ID": "NRTHAM-High2010",
        "yCap": 62592.77,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2011,
        "ID": "NRTHAM-High2011",
        "yCap": 62204.29,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2012,
        "ID": "NRTHAM-High2012",
        "yCap": 60970.8,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2013,
        "ID": "NRTHAM-High2013",
        "yCap": 61215.79,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2014,
        "ID": "NRTHAM-High2014",
        "yCap": 60744.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2015,
        "ID": "NRTHAM-High2015",
        "yCap": 61264.15,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2016,
        "ID": "NRTHAM-High2016",
        "yCap": 61526.61,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2017,
        "ID": "NRTHAM-High2017",
        "yCap": 63355.64,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2018,
        "ID": "NRTHAM-High2018",
        "yCap": 63913.85,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2019,
        "ID": "NRTHAM-High2019",
        "yCap": 64443.83,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2020,
        "ID": "NRTHAM-High2020",
        "yCap": 60783.31,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2021,
        "ID": "NRTHAM-High2021",
        "yCap": 63944.6,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "North America - High Income",
        "Alpha-3": "NRTHAM-High",
        "Alpha-2": "NRTHAM-High",
        "Region": "NRTHAM-High",
        "IncGroup": "NRTHAM-High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2022,
        "ID": "NRTHAM-High2022",
        "yCap": 63944.6,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1990,
        "ID": "SA-Low1990",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1991,
        "ID": "SA-Low1991",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1992,
        "ID": "SA-Low1992",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1993,
        "ID": "SA-Low1993",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1994,
        "ID": "SA-Low1994",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1995,
        "ID": "SA-Low1995",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1996,
        "ID": "SA-Low1996",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1997,
        "ID": "SA-Low1997",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1998,
        "ID": "SA-Low1998",
        "yCap": 1280.46,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 1999,
        "ID": "SA-Low1999",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2000,
        "ID": "SA-Low2000",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2001,
        "ID": "SA-Low2001",
        "yCap": 1280.46,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2002,
        "ID": "SA-Low2002",
        "yCap": 1280.46,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2003,
        "ID": "SA-Low2003",
        "yCap": 1292.33,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2004,
        "ID": "SA-Low2004",
        "yCap": 1260.06,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2005,
        "ID": "SA-Low2005",
        "yCap": 1352.32,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2006,
        "ID": "SA-Low2006",
        "yCap": 1366.99,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2007,
        "ID": "SA-Low2007",
        "yCap": 1528.34,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2008,
        "ID": "SA-Low2008",
        "yCap": 1556.84,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2009,
        "ID": "SA-Low2009",
        "yCap": 1823.74,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2010,
        "ID": "SA-Low2010",
        "yCap": 2026.16,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2011,
        "ID": "SA-Low2011",
        "yCap": 1961.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2012,
        "ID": "SA-Low2012",
        "yCap": 2122.83,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2013,
        "ID": "SA-Low2013",
        "yCap": 2165.34,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2014,
        "ID": "SA-Low2014",
        "yCap": 2144.45,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2015,
        "ID": "SA-Low2015",
        "yCap": 2108.71,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2016,
        "ID": "SA-Low2016",
        "yCap": 2101.42,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2017,
        "ID": "SA-Low2017",
        "yCap": 2096.09,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2018,
        "ID": "SA-Low2018",
        "yCap": 2060.7,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2019,
        "ID": "SA-Low2019",
        "yCap": 2079.92,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2020,
        "ID": "SA-Low2020",
        "yCap": 1968.34,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2021,
        "ID": "SA-Low2021",
        "yCap": 1516.31,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Low Income",
        "Alpha-3": "SA-Low",
        "Alpha-2": "SA-Low",
        "Region": "SA-Low",
        "IncGroup": "SA-Low",
        "RegIncGrp": "SA-Low",
        "Time": 2022,
        "ID": "SA-Low2022",
        "yCap": 1516.31,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": "..",
        "socSec": "..",
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1990,
        "ID": "SA-LowMid1990",
        "yCap": 2485.86,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1991,
        "ID": "SA-LowMid1991",
        "yCap": 2518.37,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1992,
        "ID": "SA-LowMid1992",
        "yCap": 2628.88,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1993,
        "ID": "SA-LowMid1993",
        "yCap": 2718.61,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1994,
        "ID": "SA-LowMid1994",
        "yCap": 2818.58,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1995,
        "ID": "SA-LowMid1995",
        "yCap": 2928.63,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1996,
        "ID": "SA-LowMid1996",
        "yCap": 3021.64,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1997,
        "ID": "SA-LowMid1997",
        "yCap": 3107.07,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1998,
        "ID": "SA-LowMid1998",
        "yCap": 3197.31,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1999,
        "ID": "SA-LowMid1999",
        "yCap": 3315.49,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2000,
        "ID": "SA-LowMid2000",
        "yCap": 3411.69,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2001,
        "ID": "SA-LowMid2001",
        "yCap": 3463.09,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2002,
        "ID": "SA-LowMid2002",
        "yCap": 3564.5,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2003,
        "ID": "SA-LowMid2003",
        "yCap": 3723.94,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2004,
        "ID": "SA-LowMid2004",
        "yCap": 3887.73,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2005,
        "ID": "SA-LowMid2005",
        "yCap": 4079.21,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2006,
        "ID": "SA-LowMid2006",
        "yCap": 4300.59,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2007,
        "ID": "SA-LowMid2007",
        "yCap": 4626.11,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2008,
        "ID": "SA-LowMid2008",
        "yCap": 4788.67,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2009,
        "ID": "SA-LowMid2009",
        "yCap": 4972.72,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2010,
        "ID": "SA-LowMid2010",
        "yCap": 5291.39,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2011,
        "ID": "SA-LowMid2011",
        "yCap": 5580.21,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2012,
        "ID": "SA-LowMid2012",
        "yCap": 5873.07,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2013,
        "ID": "SA-LowMid2013",
        "yCap": 6050.84,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2014,
        "ID": "SA-LowMid2014",
        "yCap": 6354.14,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2015,
        "ID": "SA-LowMid2015",
        "yCap": 6643.28,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2016,
        "ID": "SA-LowMid2016",
        "yCap": 6977.1,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2017,
        "ID": "SA-LowMid2017",
        "yCap": 7316.86,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2018,
        "ID": "SA-LowMid2018",
        "yCap": 7563.37,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2019,
        "ID": "SA-LowMid2019",
        "yCap": 7765.29,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2020,
        "ID": "SA-LowMid2020",
        "yCap": 7352.4,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2021,
        "ID": "SA-LowMid2021",
        "yCap": 7643.61,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Lower Middle Income",
        "Alpha-3": "SA-LowMid",
        "Alpha-2": "SA-LowMid",
        "Region": "SA-LowMid",
        "IncGroup": "SA-LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2022,
        "ID": "SA-LowMid2022",
        "yCap": 7643.61,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1990,
        "ID": "SA-UpMid1990",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1991,
        "ID": "SA-UpMid1991",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1992,
        "ID": "SA-UpMid1992",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1993,
        "ID": "SA-UpMid1993",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1994,
        "ID": "SA-UpMid1994",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1995,
        "ID": "SA-UpMid1995",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1996,
        "ID": "SA-UpMid1996",
        "yCap": 11010.31,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1997,
        "ID": "SA-UpMid1997",
        "yCap": 11704.31,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1998,
        "ID": "SA-UpMid1998",
        "yCap": 12362.97,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1999,
        "ID": "SA-UpMid1999",
        "yCap": 12922.8,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2000,
        "ID": "SA-UpMid2000",
        "yCap": 13210.96,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2001,
        "ID": "SA-UpMid2001",
        "yCap": 12477.22,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2002,
        "ID": "SA-UpMid2002",
        "yCap": 13156.99,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2003,
        "ID": "SA-UpMid2003",
        "yCap": 14717.24,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2004,
        "ID": "SA-UpMid2004",
        "yCap": 15351.7,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2005,
        "ID": "SA-UpMid2005",
        "yCap": 13124.06,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2006,
        "ID": "SA-UpMid2006",
        "yCap": 16162.28,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2007,
        "ID": "SA-UpMid2007",
        "yCap": 16834.74,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2008,
        "ID": "SA-UpMid2008",
        "yCap": 17788.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2009,
        "ID": "SA-UpMid2009",
        "yCap": 15927.8,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2010,
        "ID": "SA-UpMid2010",
        "yCap": 16492.53,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2011,
        "ID": "SA-UpMid2011",
        "yCap": 17290.21,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2012,
        "ID": "SA-UpMid2012",
        "yCap": 17126.34,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2013,
        "ID": "SA-UpMid2013",
        "yCap": 17768.61,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2014,
        "ID": "SA-UpMid2014",
        "yCap": 18338.32,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2015,
        "ID": "SA-UpMid2015",
        "yCap": 18051.07,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2016,
        "ID": "SA-UpMid2016",
        "yCap": 18406.27,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2017,
        "ID": "SA-UpMid2017",
        "yCap": 18973.57,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2018,
        "ID": "SA-UpMid2018",
        "yCap": 19789.49,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2019,
        "ID": "SA-UpMid2019",
        "yCap": 20574.4,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2020,
        "ID": "SA-UpMid2020",
        "yCap": 13419.33,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2021,
        "ID": "SA-UpMid2021",
        "yCap": 18765.22,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - Upper Middle Income",
        "Alpha-3": "SA-UpMid",
        "Alpha-2": "SA-UpMid",
        "Region": "SA-UpMid",
        "IncGroup": "SA-UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2022,
        "ID": "SA-UpMid2022",
        "yCap": 18765.22,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1990,
        "ID": "SA-High1990",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1991,
        "ID": "SA-High1991",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1992,
        "ID": "SA-High1992",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1993,
        "ID": "SA-High1993",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1994,
        "ID": "SA-High1994",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1995,
        "ID": "SA-High1995",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1996,
        "ID": "SA-High1996",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1997,
        "ID": "SA-High1997",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1998,
        "ID": "SA-High1998",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 1999,
        "ID": "SA-High1999",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2000,
        "ID": "SA-High2000",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2001,
        "ID": "SA-High2001",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2002,
        "ID": "SA-High2002",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2003,
        "ID": "SA-High2003",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2004,
        "ID": "SA-High2004",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2005,
        "ID": "SA-High2005",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2006,
        "ID": "SA-High2006",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2007,
        "ID": "SA-High2007",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2008,
        "ID": "SA-High2008",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2009,
        "ID": "SA-High2009",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2010,
        "ID": "SA-High2010",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2011,
        "ID": "SA-High2011",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2012,
        "ID": "SA-High2012",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2013,
        "ID": "SA-High2013",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2014,
        "ID": "SA-High2014",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2015,
        "ID": "SA-High2015",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2016,
        "ID": "SA-High2016",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2017,
        "ID": "SA-High2017",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2018,
        "ID": "SA-High2018",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2019,
        "ID": "SA-High2019",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2020,
        "ID": "SA-High2020",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2021,
        "ID": "SA-High2021",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Asia - High Income",
        "Alpha-3": "SA-High",
        "Alpha-2": "SA-High",
        "Region": "SA-High",
        "IncGroup": "SA-High",
        "RegIncGrp": "SA-High",
        "Time": 2022,
        "ID": "SA-High2022",
        "yCap": "..",
        "priceStab": "..",
        "empLev": "..",
        "fiscHealth": "..",
        "favTradeBal": "..",
        "tradeComp": "..",
        "openToImp": "..",
        "polResp": "..",
        "tfp": "..",
        "socSec": "..",
        "persSafe": "..",
        "genderEqual": "..",
        "equitSocDev": "..",
        "scientificOut": "..",
        "schoolEnr": "..",
        "infSurv": "..",
        "pubHealth": "..",
        "natureCons": "..",
        "safeWatAcc": "..",
        "cleanAirAcc": "..",
        "natDisRes": "..",
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "SSA-Low1990",
        "yCap": 1399.86,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "SSA-Low1991",
        "yCap": 1393.14,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "SSA-Low1992",
        "yCap": 1348.09,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "SSA-Low1993",
        "yCap": 1328.23,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "SSA-Low1994",
        "yCap": 1297.72,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "SSA-Low1995",
        "yCap": 1316.87,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "SSA-Low1996",
        "yCap": 1342.35,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "SSA-Low1997",
        "yCap": 1384.54,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "SSA-Low1998",
        "yCap": 1366,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "SSA-Low1999",
        "yCap": 1376.13,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "SSA-Low2000",
        "yCap": 1377.18,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "SSA-Low2001",
        "yCap": 1404.27,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "SSA-Low2002",
        "yCap": 1418.65,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "SSA-Low2003",
        "yCap": 1425.97,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "SSA-Low2004",
        "yCap": 1466.15,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "SSA-Low2005",
        "yCap": 1494.88,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "SSA-Low2006",
        "yCap": 1525.54,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "SSA-Low2007",
        "yCap": 1562.58,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "SSA-Low2008",
        "yCap": 1602.58,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "SSA-Low2009",
        "yCap": 1610.97,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "SSA-Low2010",
        "yCap": 1662.55,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "SSA-Low2011",
        "yCap": 1700.22,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "SSA-Low2012",
        "yCap": 1725.69,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "SSA-Low2013",
        "yCap": 1745.32,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "SSA-Low2014",
        "yCap": 1777.35,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "SSA-Low2015",
        "yCap": 1783.31,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "SSA-Low2016",
        "yCap": 1807.11,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "SSA-Low2017",
        "yCap": 1826.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "SSA-Low2018",
        "yCap": 1846.96,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "SSA-Low2019",
        "yCap": 1869.36,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "SSA-Low2020",
        "yCap": 1814.45,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "SSA-Low2021",
        "yCap": 1831.83,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Low Income",
        "Alpha-3": "SSA-Low",
        "Alpha-2": "SSA-Low",
        "Region": "SSA-Low",
        "IncGroup": "SSA-Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "SSA-Low2022",
        "yCap": 1831.83,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "SSA-LowMid1990",
        "yCap": 3191.95,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "SSA-LowMid1991",
        "yCap": 3140.56,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "SSA-LowMid1992",
        "yCap": 3079.48,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "SSA-LowMid1993",
        "yCap": 2961.36,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "SSA-LowMid1994",
        "yCap": 2923,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "SSA-LowMid1995",
        "yCap": 3016,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "SSA-LowMid1996",
        "yCap": 3106.78,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "SSA-LowMid1997",
        "yCap": 3138.19,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "SSA-LowMid1998",
        "yCap": 3184.7,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "SSA-LowMid1999",
        "yCap": 3195.03,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "SSA-LowMid2000",
        "yCap": 3225.31,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "SSA-LowMid2001",
        "yCap": 3235.33,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "SSA-LowMid2002",
        "yCap": 3286.8,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "SSA-LowMid2003",
        "yCap": 3306.37,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "SSA-LowMid2004",
        "yCap": 3401.83,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "SSA-LowMid2005",
        "yCap": 3522.58,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "SSA-LowMid2006",
        "yCap": 3681.56,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "SSA-LowMid2007",
        "yCap": 3763.91,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "SSA-LowMid2008",
        "yCap": 3839.81,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "SSA-LowMid2009",
        "yCap": 3865.45,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "SSA-LowMid2010",
        "yCap": 3982.44,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "SSA-LowMid2011",
        "yCap": 4049.43,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "SSA-LowMid2012",
        "yCap": 4187.33,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "SSA-LowMid2013",
        "yCap": 4271.19,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "SSA-LowMid2014",
        "yCap": 4356.37,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "SSA-LowMid2015",
        "yCap": 4374.52,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "SSA-LowMid2016",
        "yCap": 4347.53,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "SSA-LowMid2017",
        "yCap": 4389.28,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "SSA-LowMid2018",
        "yCap": 4436.17,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "SSA-LowMid2019",
        "yCap": 4485.39,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "SSA-LowMid2020",
        "yCap": 4286.29,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "SSA-LowMid2021",
        "yCap": 4390.47,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Lower Middle Income",
        "Alpha-3": "SSA-LowMid",
        "Alpha-2": "SSA-LowMid",
        "Region": "SSA-LowMid",
        "IncGroup": "SSA-LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "SSA-LowMid2022",
        "yCap": 4390.47,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1990,
        "ID": "SSA-UpMid1990",
        "yCap": 8751.6,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1991,
        "ID": "SSA-UpMid1991",
        "yCap": 8940.08,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1992,
        "ID": "SSA-UpMid1992",
        "yCap": 8858.76,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1993,
        "ID": "SSA-UpMid1993",
        "yCap": 8886.18,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1994,
        "ID": "SSA-UpMid1994",
        "yCap": 9002.97,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1995,
        "ID": "SSA-UpMid1995",
        "yCap": 9254.83,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1996,
        "ID": "SSA-UpMid1996",
        "yCap": 9621.7,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1997,
        "ID": "SSA-UpMid1997",
        "yCap": 10520.05,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1998,
        "ID": "SSA-UpMid1998",
        "yCap": 10793.79,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1999,
        "ID": "SSA-UpMid1999",
        "yCap": 10880.43,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2000,
        "ID": "SSA-UpMid2000",
        "yCap": 11151.02,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2001,
        "ID": "SSA-UpMid2001",
        "yCap": 12094.93,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2002,
        "ID": "SSA-UpMid2002",
        "yCap": 12555.94,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2003,
        "ID": "SSA-UpMid2003",
        "yCap": 13034.23,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2004,
        "ID": "SSA-UpMid2004",
        "yCap": 14290.72,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2005,
        "ID": "SSA-UpMid2005",
        "yCap": 14952.58,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2006,
        "ID": "SSA-UpMid2006",
        "yCap": 15348.88,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2007,
        "ID": "SSA-UpMid2007",
        "yCap": 16254.91,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2008,
        "ID": "SSA-UpMid2008",
        "yCap": 16972.98,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2009,
        "ID": "SSA-UpMid2009",
        "yCap": 16335.32,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2010,
        "ID": "SSA-UpMid2010",
        "yCap": 16029.5,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2011,
        "ID": "SSA-UpMid2011",
        "yCap": 16506.65,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2012,
        "ID": "SSA-UpMid2012",
        "yCap": 16867.24,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2013,
        "ID": "SSA-UpMid2013",
        "yCap": 16871.69,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2014,
        "ID": "SSA-UpMid2014",
        "yCap": 16991.53,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2015,
        "ID": "SSA-UpMid2015",
        "yCap": 16398.05,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2016,
        "ID": "SSA-UpMid2016",
        "yCap": 16087.21,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2017,
        "ID": "SSA-UpMid2017",
        "yCap": 15864.52,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2018,
        "ID": "SSA-UpMid2018",
        "yCap": 15710.25,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2019,
        "ID": "SSA-UpMid2019",
        "yCap": 15563.29,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2020,
        "ID": "SSA-UpMid2020",
        "yCap": 14111.61,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2021,
        "ID": "SSA-UpMid2021",
        "yCap": 14451.55,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - Upper Middle Income",
        "Alpha-3": "SSA-UpMid",
        "Alpha-2": "SSA-UpMid",
        "Region": "SSA-UpMid",
        "IncGroup": "SSA-UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2022,
        "ID": "SSA-UpMid2022",
        "yCap": 14451.55,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": "..",
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1990,
        "ID": "SSA-High1990",
        "yCap": 14884.89,
        "priceStab": 3.75,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1991,
        "ID": "SSA-High1991",
        "yCap": 15093.39,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1992,
        "ID": "SSA-High1992",
        "yCap": 16100.24,
        "priceStab": 3.75,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1993,
        "ID": "SSA-High1993",
        "yCap": 16745.33,
        "priceStab": 3.75,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1994,
        "ID": "SSA-High1994",
        "yCap": 16174.6,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1995,
        "ID": "SSA-High1995",
        "yCap": 15806.95,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1996,
        "ID": "SSA-High1996",
        "yCap": 16342.72,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1997,
        "ID": "SSA-High1997",
        "yCap": 18084.1,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1998,
        "ID": "SSA-High1998",
        "yCap": 19224.12,
        "priceStab": 2.5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 1999,
        "ID": "SSA-High1999",
        "yCap": 19203.08,
        "priceStab": 3.75,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2000,
        "ID": "SSA-High2000",
        "yCap": 19320.64,
        "priceStab": 2.5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2001,
        "ID": "SSA-High2001",
        "yCap": 18865.33,
        "priceStab": 1.25,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2002,
        "ID": "SSA-High2002",
        "yCap": 18519.19,
        "priceStab": 2.5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2003,
        "ID": "SSA-High2003",
        "yCap": 17627.27,
        "priceStab": 1.25,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2004,
        "ID": "SSA-High2004",
        "yCap": 17188.35,
        "priceStab": 0,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2005,
        "ID": "SSA-High2005",
        "yCap": 18649.68,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2006,
        "ID": "SSA-High2006",
        "yCap": 19983.76,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2007,
        "ID": "SSA-High2007",
        "yCap": 21953.99,
        "priceStab": 0,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2008,
        "ID": "SSA-High2008",
        "yCap": 21007.58,
        "priceStab": 0,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2009,
        "ID": "SSA-High2009",
        "yCap": 20694,
        "priceStab": 0,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2010,
        "ID": "SSA-High2010",
        "yCap": 21322.54,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2011,
        "ID": "SSA-High2011",
        "yCap": 23617.03,
        "priceStab": 2.5,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2012,
        "ID": "SSA-High2012",
        "yCap": 23681.35,
        "priceStab": 0,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2013,
        "ID": "SSA-High2013",
        "yCap": 24647.08,
        "priceStab": 1.25,
        "empLev": "..",
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2014,
        "ID": "SSA-High2014",
        "yCap": 25359.85,
        "priceStab": 1.25,
        "empLev": "..",
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2015,
        "ID": "SSA-High2015",
        "yCap": 26198.72,
        "priceStab": 3.75,
        "empLev": "..",
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2016,
        "ID": "SSA-High2016",
        "yCap": 27234.41,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2017,
        "ID": "SSA-High2017",
        "yCap": 28116.71,
        "priceStab": 2.5,
        "empLev": "..",
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2018,
        "ID": "SSA-High2018",
        "yCap": 28740.55,
        "priceStab": 2.5,
        "empLev": "..",
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2019,
        "ID": "SSA-High2019",
        "yCap": 29369.53,
        "priceStab": 5,
        "empLev": "..",
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2020,
        "ID": "SSA-High2020",
        "yCap": 26879.77,
        "priceStab": 2.5,
        "empLev": "..",
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2021,
        "ID": "SSA-High2021",
        "yCap": 28760.52,
        "priceStab": 3.75,
        "empLev": "..",
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sub Saharan Africa - High Income",
        "Alpha-3": "SSA-High",
        "Alpha-2": "SSA-High",
        "Region": "SSA-High",
        "IncGroup": "SSA-High",
        "RegIncGrp": "SSA-High",
        "Time": 2022,
        "ID": "SSA-High2022",
        "yCap": 28760.52,
        "priceStab": 3.75,
        "empLev": "..",
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": "..",
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": "..",
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    },
    {
        "Country Name": "",
        "Alpha-3": "",
        "Alpha-2": "",
        "Region": "",
        "IncGroup": "",
        "RegIncGrp": "",
        "Time": "",
        "ID": "",
        "yCap": "",
        "priceStab": "",
        "empLev": "",
        "fiscHealth": "",
        "favTradeBal": "",
        "tradeComp": "",
        "openToImp": "",
        "polResp": "",
        "tfp": "",
        "socSec": "",
        "persSafe": "",
        "genderEqual": "",
        "equitSocDev": "",
        "scientificOut": "",
        "schoolEnr": "",
        "infSurv": "",
        "pubHealth": "",
        "natureCons": "",
        "safeWatAcc": "",
        "cleanAirAcc": "",
        "natDisRes": "",
        "macroStab": "",
        "microComp": "",
        "socCoh": "",
        "humEmp": "",
        "envSust": "",
        "SEPX": ""
    }
]

const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas, The",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Virgin Islands",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Channel Islands",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Dem. Rep.",
    "Congo, Rep.",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt, Arab Rep.",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "Gabon",
    "Gambia, The",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong SAR, China",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Rep.",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Dem. People's Rep.",
    "Korea, Rep.",
    "Kosovo",
    "Kuwait",
    "Kyrgyz Republic",
    "Lao PDR",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao SAR, China",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia, Fed. Sts.",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovak Republic",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "St. Kitts and Nevis",
    "St. Lucia",
    "St. Martin (French part)",
    "St. Vincent and the Grenadines",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkiye",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela, RB",
    "Vietnam",
    "Virgin Islands (U.S.)",
    "West Bank and Gaza",
    "Yemen, Rep.",
    "Zambia",
    "Zimbabwe"
]

const regions = [
    "World",
    "East Asia and the Pacific",
    "Europe and Central Asia",
    "Latin America and the Caribbean",
    "Middle East and North Africa",
    "North America",
    "South Asia",
    "Sub Saharan Africa",
    "Low Income",
    "Lower Middle Income",
    "Upper Middle Income",
    "High Income",
    "East Asia and the Pacific - Low Income",
    "East Asia and the Pacific - Lower Middle Income",
    "East Asia and the Pacific - Upper Middle Income",
    "East Asia and the Pacific - High Income",
    "Europe and Central Asia - Low Income",
    "Europe and Central Asia - Lower Middle Income",
    "Europe and Central Asia - Upper Middle Income",
    "Europe and Central Asia - High Income",
    "Latin America and the Caribbean - Low Income",
    "Latin America and the Caribbean - Lower Middle Income",
    "Latin America and the Caribbean - Upper Middle Income",
    "Latin America and the Caribbean - High Income",
    "Middle East and North Africa - Low Income",
    "Middle East and North Africa - Lower Middle Income",
    "Middle East and North Africa - Upper Middle Income",
    "Middle East and North Africa - High Income",
    "North America - Low Income",
    "North America - Lower Middle Income",
    "North America - Upper Middle Income",
    "North America - High Income",
    "South Asia - Low Income",
    "South Asia - Lower Middle Income",
    "South Asia - Upper Middle Income",
    "South Asia - High Income",
    "Sub Saharan Africa - Low Income",
    "Sub Saharan Africa - Lower Middle Income",
    "Sub Saharan Africa - Upper Middle Income",
    "Sub Saharan Africa - High Income"
]

let years = [
    1990,
    1991,
    1992,
    1993,
    1994,
    1995,
    1996,
    1997,
    1998,
    1999,
    2000,
    2001,
    2002,
    2003,
    2004,
    2005,
    2006,
    2007,
    2008,
    2009,
    2010,
    2011,
    2012,
    2013,
    2014,
    2015,
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022
]

export { countries, regionsData, regions, years };
