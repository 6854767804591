import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import './project.css'

import { countriesData } from './first-data'
import { countries } from './second-data'

const SimulationPage = () => {

    const [selSimCtry, setSelSimCtry] = useState("Indonesia")
    const [initSimObj, setInitSimObj] = useState({});
    const [sliderBg, setSliderBg] = useState({});
    const [sliderTop, setSliderTop] = useState({});
    const [concept, setConcept] = useState({})

    const keysToExtract = [
        "priceStab", "empLev", "fiscHealth", "favTradeBal",
        "tradeComp", "openToImp", "polResp", "tfp",
        "socSec", "persSafe", "genderEqual", "equitSocDev",
        "scientificOut", "schoolEnr", "infSurv", "pubHealth",
        "natureCons", "safeWatAcc", "cleanAirAcc", "natDisRes"
    ];
    const inactiveSimInd = ["macroStab", "microComp", "socCoh", "humEmp", "envSust"];

    function getCountry(e) {
        const countryValue = e.target.value;
        setSelSimCtry(countryValue);
    }
    console.log(selSimCtry)

    useEffect(() => {
        const x = countriesData.filter((element) => element["Time"] === 2022);
        console.log(x);

        for (let i = 0; i < x.length; i++) {
            if (x[i]["Country Name"] === selSimCtry) {
                setInitSimObj(x[i]);
                setSliderBg(x[i]);
                setSliderTop(x[i]);
            }
        }
        // console.log("Pertama")
        // console.log(initSimObj);
        // console.log("Kedua")
        // console.log(sliderBg);
        console.log("Ketiga")
        console.log(sliderTop);
        // console.log("Hai Ogi Bla bla bla")
        // console.log(sliderTop['tfp']);
    }, [initSimObj, selSimCtry])

    const setSimValue = (e) => {
        const { id, value } = e.target;
        setSliderTop(prev => ({
            ...prev,
            [id]: value * 1
        }));
        console.log('Mengubah', id, value);

        const temp = { ...sliderTop };
        temp[id] = parseFloat(value);
        console.log('iini temp', id, 'jadi', value, temp,);
    }



    const allArray = [
        sliderBg['priceStab'], sliderBg['empLev'], sliderBg['fiscHealth'], sliderBg['favTradeBal'],
        sliderBg['tradeComp'], sliderBg['openToImp'], sliderBg['polResp'], sliderBg['tfp'],
        sliderBg['socSec'], sliderBg['persSafe'], sliderBg['genderEqual'], sliderBg['equitSocDev'],
        sliderBg['scientificOut'], sliderBg['schoolEnr'], sliderBg['infSurv'], sliderBg['pubHealth'],
        sliderBg['natureCons'], sliderBg['safeWatAcc'], sliderBg['cleanAirAcc'], sliderBg['natDisRes']
    ]
    const array1 = [sliderBg['priceStab'], sliderBg['empLev'], sliderBg['fiscHealth'], sliderBg['favTradeBal']]
    const array2 = [sliderBg['tradeComp'], sliderBg['openToImp'], sliderBg['polResp'], sliderBg['tfp']]
    const array3 = [sliderBg['socSec'], sliderBg['persSafe'], sliderBg['genderEqual'], sliderBg['equitSocDev']]
    const array4 = [sliderBg['scientificOut'], sliderBg['schoolEnr'], sliderBg['infSurv'], sliderBg['pubHealth']]
    const array5 = [sliderBg['natureCons'], sliderBg['safeWatAcc'], sliderBg['cleanAirAcc'], sliderBg['natDisRes']]
    // console.log("Ini ALl Arrayy ",allArray)
    // console.log("Ini array 1: ", array1)
    // console.log("Ini array 2: ", array2)
    // console.log("Ini array 3: ", array3)
    // console.log("Ini array 4: ", array4)
    // console.log("Ini array 5: ", array5)
    let macroStabSimBg = array1.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let microCompSimBg = array2.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let socCohSimBg = array3.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let humEmpSimBg = array4.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let envSustSimBg = array5.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    // console.log(macroStabSimBg)
    // console.log(microCompSimBg)
    // console.log(socCohSimBg)
    // console.log(humEmpSimBg)
    // console.log(envSustSimBg)
    const conceptVector = [
        macroStabSimBg,
        microCompSimBg,
        socCohSimBg,
        humEmpSimBg,
        envSustSimBg
    ];
    console.log(conceptVector)
    const [sepCX, setSepCX] = useState(0);
    let sepxConceptBg = conceptVector.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    console.log(sepxConceptBg)
    const [constSlider, setConstSlider] = useState(0)



    useEffect(() => {
        setConcept((prev) => ({
            ...prev,
            macroStab: sliderTop['priceStab'] + sliderTop['empLev'] + sliderTop['fiscHealth'] + sliderTop['favTradeBal'],
            microComp: sliderTop['tradeComp'] + sliderTop['openToImp'] + sliderTop['polResp'] + sliderTop['tfp'],
            socCoh: sliderTop['socSec'] + sliderTop['persSafe'] + sliderTop['genderEqual'] + sliderTop['equitSocDev'],
            humEmp: sliderTop['scientificOut'] + sliderTop['schoolEnr'] + sliderTop['infSurv'] + sliderTop['pubHealth'],
            envSust: sliderTop['natureCons'] + sliderTop['safeWatAcc'] + sliderTop['cleanAirAcc'] + sliderTop['natDisRes'],
            // SEPX: prev.macroStab + prev.microComp + prev.socCoh + prev.humEmp + prev.envSust
        }))
        console.log(concept)

        for (const key in concept) {
            const value = concept[key];
            if (value < 4) {
                setSliderTop(prev => ({
                    ...prev,
                    [key]: 0
                }))
            } else if (value < 8) {
                setSliderTop(prev => ({
                    ...prev,
                    [key]: 1.25
                }))
            } else if (value < 12) {
                setSliderTop(prev => ({
                    ...prev,
                    [key]: 2.5
                }))
            } else if (value < 16) {
                setSliderTop(prev => ({
                    ...prev,
                    [key]: 3.75
                }))
            } else {
                setSliderTop(prev => ({
                    ...prev,
                    [key]: 5
                }))
            }
        }

        for (let x = 0; x < 5; x++) {
            if (concept.SEPX <= 20) {
                setSliderTop((prev) => ({
                    ...prev,
                    SEPX: 0
                }))
            } else if (concept.SEPX <= 40) {
                setSliderTop((prev) => ({
                    ...prev,
                    SEPX: 1.25
                }))
            } else if (concept.SEPX <= 60) {
                setSliderTop((prev) => ({
                    ...prev,
                    SEPX: 2.5
                }))
            } else if (concept.SEPX <= 80) {
                setSliderTop((prev) => ({
                    ...prev,
                    SEPX: 3.75
                }))
            } else {
                setSliderTop((prev) => ({
                    ...prev,
                    SEPX: 5
                }))
            }
        }

        setConcept((prev) => ({
            ...prev,
            SEPX: concept.macroStab + concept.microComp + concept.socCoh + concept.humEmp + concept.envSust
        }))

        console.log('Ini apa kek', concept['macroStab'])

        const conceptValues = [concept['macroStab'], concept['microComp'], concept['socCoh'], concept['humEmp'], concept['envSust']];
        console.log(conceptValues)
        const sumConceptSim = conceptValues.reduce((sum, value) => sum + value, 0);
        setConstSlider(sumConceptSim)
        console.log(sumConceptSim)
        console.log(constSlider)

    }, [
        sliderTop['priceStab'], sliderTop['empLev'], sliderTop['fiscHealth'], sliderTop['favTradeBal'],
        sliderTop['tradeComp'], sliderTop['openToImp'], sliderTop['polResp'], sliderTop['tfp'],
        sliderTop['socSec'], sliderTop['persSafe'], sliderTop['genderEqual'], sliderTop['equitSocDev'],
        sliderTop['scientificOut'], sliderTop['schoolEnr'], sliderTop['infSurv'], sliderTop['pubHealth'],
        sliderTop['natureCons'], sliderTop['safeWatAcc'], sliderTop['cleanAirAcc'], sliderTop['natDisRes'],
        sliderTop['macroStab'], sliderTop['microComp'], sliderTop['socCoh'], sliderTop['humEmp'], sliderTop['envSust'],
        concept['macroStab'], concept['microComp'], concept['socCoh'], concept['humEmp'], concept['envSust'],
    ])

    // ========SIMCHART=========

    const [initGDPcap, setInitGDPcap] = useState(0);
    const simYears = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
    const [simChrtData, setSimChrtData] = useState([['Year', 'Upper Limit', 'Lower Limit', 'Simulated', 'Baseline']]);

    useEffect(() => {
        // console.log(initSimObj)
        const initialGDPFromSimObj = initSimObj['yCap']
        setInitGDPcap(initialGDPFromSimObj)
        console.log('Test Lagi')
        console.log(initGDPcap)

        const newbaseLineVal = Array.from({ length: simYears.length }, () => initGDPcap);
        // setBaseLineVal(newbaseLineVal)
        // console.log(baseLineVal);
        console.log(newbaseLineVal);

        let newchangeSEPX = constSlider - sepxConceptBg
        // setChangeSEPX(newchangeSEPX)
        console.log(newchangeSEPX)
        // console.log(changeSEPX)

        // Step 3: Calculate growth rates
        const growthRateLL = newchangeSEPX * 0.014023716;
        const growthRateM = newchangeSEPX * 0.0157105;
        const growthRateUL = newchangeSEPX * 0.017397284;
        console.log(growthRateLL);
        console.log(growthRateM);
        console.log(growthRateUL);

        // Step 4: Calculate final GDP caps
        let newFinalGDPcapUL = (initGDPcap * (1 + growthRateLL));
        let newFinalGDPcapMean = (initGDPcap * (1 + growthRateM));
        let newFinalGDPcapLL = (initGDPcap * (1 + growthRateUL));
        console.log(newFinalGDPcapUL)
        console.log(newFinalGDPcapMean)
        console.log(newFinalGDPcapLL)

        // Step 5: Create simulated GDP cap over time
        const newSimLineValMean = simYears.map((year, i) => {
            if (year === 0) {
                return initGDPcap;
            } else if (year === 30) {
                return newFinalGDPcapMean;
            } else {
                return initGDPcap + (newFinalGDPcapMean - initGDPcap) / Math.log(31) * Math.log(simYears[i + 1]);
            }
        });
        // console.log(newSimLineValMean)
        // setSimLineValMean(newSimLineValMean);
        // console.log(simLineValMean)

        const newSimLineValUL = simYears.map((year, i) => {
            if (year === 0) {
                return initGDPcap;
            } else if (year === 30) {
                return newFinalGDPcapUL;
            } else {
                return initGDPcap + (newFinalGDPcapUL - initGDPcap) / Math.log(31) * Math.log(simYears[i + 1]);
            }
        });
        // setSimLineValUL(newSimLineValUL);
        // console.log(newSimLineValUL)

        const newSimLineValLL = simYears.map((year, i) => {
            if (year === 0) {
                return initGDPcap;
            } else if (year === 30) {
                return newFinalGDPcapLL;
            } else {
                return initGDPcap + (newFinalGDPcapLL - initGDPcap) / Math.log(31) * Math.log(simYears[i + 1]);
            }
        });
        // setSimLineValLL(newSimLineValLL);
        // console.log(newSimLineValLL)

        // console.log(initGDPcap);
        // console.log(newFinalGDPcapMean)
        // console.log(newSimLineValUL)

        // Step 6: Create line chart data
        const newFormattedSimLineValUL = newSimLineValUL.map((val) => (val === null ? NaN : val));
        const newFormattedSimLineValLL = newSimLineValLL.map((val) => (val === null ? NaN : val));
        const newFormattedSimLineValMean = newSimLineValMean.map((val) => (val === null ? NaN : val));
        const newFormattedBaseLineVal = newbaseLineVal.map((val) => (val === null ? NaN : val));

        // console.log(newFormattedBaseLineVal)
        // console.log(newFormattedSimLineValUL)
        // console.log(newFormattedSimLineValLL)
        // console.log(newFormattedSimLineValMean)

        // Populate chart data
        const newSimChrtData = [
            ['Year', 'Upper Limit', 'Lower Limit', 'Simulated', 'Baseline'],
            ...simYears.map((year, i) => [
                +year,
                +newFormattedSimLineValUL[i],
                +newFormattedSimLineValLL[i],
                +newFormattedSimLineValMean[i],
                +newFormattedBaseLineVal[i],
            ]),
        ];
        console.log(simChrtData);
        setSimChrtData(newSimChrtData);

    }, [selSimCtry,sepCX, initSimObj, initGDPcap, constSlider, sliderTop['SEPX'], sliderBg['SEPX'],
        sliderTop['priceStab'], sliderTop['empLev'], sliderTop['fiscHealth'], sliderTop['favTradeBal'],
        sliderTop['tradeComp'], sliderTop['openToImp'], sliderTop['polResp'], sliderTop['tfp'],
        sliderTop['socSec'], sliderTop['persSafe'], sliderTop['genderEqual'], sliderTop['equitSocDev'],
        sliderTop['scientificOut'], sliderTop['schoolEnr'], sliderTop['infSurv'], sliderTop['pubHealth'],
        sliderTop['natureCons'], sliderTop['safeWatAcc'], sliderTop['cleanAirAcc'], sliderTop['natDisRes'],
        sliderTop['macroStab'], sliderTop['microComp'], sliderTop['socCoh'], sliderTop['humEmp'], sliderTop['envSust'],
        concept['macroStab'], concept['microComp'], concept['socCoh'], concept['humEmp'], concept['envSust'],
    ]);

    // ===========COLORIFY================
    const [simClasses, setSimClasses] = useState(['vl', 'l', 'm', 'h', 'vh']);
    const simScores = ['0', '1.25', '2.5', '3.75', '5'];

    useEffect(() => {
        // Perform the logic when component mounts or when any of the dependencies change
        console.log(allArray.length)
        for (let i = 0; i < allArray.length; i++) {
            for (let j = 0; j < simClasses.length; j++) {
                const elementId = `${keysToExtract[i]}`;
                const element = document.getElementById(elementId);

                if (element) {
                    if (element.classList.contains(simClasses[j])) {
                        element.classList.remove(simClasses[j]);
                    }
                    if (element.value === simScores[j]) {
                        element.classList.add(simClasses[j]);
                    }
                }
            }
        }
        for (let i = 0; i < allArray.length; i++) {
            for (let j = 0; j < simClasses.length; j++) {
                const elementId = `${inactiveSimInd[i]}`;
                const element = document.getElementById(elementId);

                if (element) {
                    if (element.classList.contains(simClasses[j])) {
                        element.classList.remove(simClasses[j]);
                    }
                    if (element.value === simScores[j]) {
                        element.classList.add(simClasses[j]);
                    }
                }
            }
        }
        for (let i = 0; i < simClasses.length; i++) {
            const elementId = 'SEPX';
            const element = document.getElementById(elementId);

            if (element) {
                if (element.classList.contains(simClasses[i])) {
                    element.classList.remove(simClasses[i]);
                }
                if (element.value === simScores[i]) {
                    element.classList.add(simClasses[i]);
                }
            }
        }
    }, [allArray, inactiveSimInd, simClasses, simScores, keysToExtract]);

    const options = {
        //title: 'Simulation Result',
        //titlePosition: 'in',
        curveType: 'function',
        backgroundColor: 'transparent',
        titleTextStyle: {
            color: 'darkgray'
        },
        chartArea: {
            top: '5%',
            // width: '85%',
            // height: '80%'
        },
        legend: {
            position: 'bottom',
            textStyle: {
                color: 'darkgray',
                fontSize: 16, // Reduce font size on small screens
            },
            scrollArrows: true,
            maxLines: 4
        },

        vAxis: {
            // title: 'GDP',
            format: '0,000',
            textStyle: {
                color: 'darkgray'
            },
            gridlines: {
                count: 0,
            },
        },

        hAxis: {
            // title: 'Years',
            textStyle: {
                color: 'darkgray'
            },
            gridlines: {
                color: 'transparent',
                textStyle: {
                    color: 'red'
                },
            },

        },

        series: {
            0: {
                type: 'line',
                lineDashStyle: [10, 10],
                //areaOpacity: 1,
                visibleInLegend: true,
            }, //UL
            1: {
                type: 'line',
                lineDashStyle: [4, 4],
                // areaOpacity: 1,
                visibleInLegend: true,
            }, //LL

            2: {
                type: 'line'
            }, //Mean
            3: {
                type: 'line',
                visibleInLegend: true
            }, //baseline
        },
        responsive: true, //
        colors: ['yellow', 'red', 'blue', 'darkgray', 'darkgray'],
    }

    return (
        <>
            <div className="container mx-auto p-4">
                <div className="flex flex-row items-center space-x-4">
                    <a href="/project">Projects</a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                        <path d="M8.275 7.54748L1.30875 0.909985C1.19251 0.799179 1.03808 0.737366 0.877496 0.737366C0.716908 0.737366 0.562482 0.799179 0.446247 0.909985L0.438746 0.917485C0.382203 0.971202 0.337178 1.03586 0.306411 1.10753C0.275644 1.17919 0.259778 1.25637 0.259778 1.33436C0.259778 1.41235 0.275644 1.48953 0.306411 1.56119C0.337178 1.63286 0.382203 1.69752 0.438746 1.75123L6.99875 8.00124L0.438746 14.2487C0.382203 14.3025 0.337178 14.3671 0.306411 14.4388C0.275644 14.5104 0.259778 14.5876 0.259778 14.6656C0.259778 14.7436 0.275644 14.8208 0.306411 14.8924C0.337178 14.9641 0.382203 15.0288 0.438746 15.0825L0.446247 15.09C0.562482 15.2008 0.716908 15.2626 0.877496 15.2626C1.03808 15.2626 1.19251 15.2008 1.30875 15.09L8.275 8.45249C8.33626 8.39411 8.38504 8.32391 8.41836 8.24613C8.45169 8.16834 8.46887 8.08461 8.46887 7.99998C8.46887 7.91536 8.45169 7.83162 8.41836 7.75384C8.38504 7.67606 8.33626 7.60586 8.275 7.54748Z" fill="black" />
                    </svg>
                    <a href="/project">Indonesia Socioeconomic Development</a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                        <path d="M8.275 7.54748L1.30875 0.909985C1.19251 0.799179 1.03808 0.737366 0.877496 0.737366C0.716908 0.737366 0.562482 0.799179 0.446247 0.909985L0.438746 0.917485C0.382203 0.971202 0.337178 1.03586 0.306411 1.10753C0.275644 1.17919 0.259778 1.25637 0.259778 1.33436C0.259778 1.41235 0.275644 1.48953 0.306411 1.56119C0.337178 1.63286 0.382203 1.69752 0.438746 1.75123L6.99875 8.00124L0.438746 14.2487C0.382203 14.3025 0.337178 14.3671 0.306411 14.4388C0.275644 14.5104 0.259778 14.5876 0.259778 14.6656C0.259778 14.7436 0.275644 14.8208 0.306411 14.8924C0.337178 14.9641 0.382203 15.0288 0.438746 15.0825L0.446247 15.09C0.562482 15.2008 0.716908 15.2626 0.877496 15.2626C1.03808 15.2626 1.19251 15.2008 1.30875 15.09L8.275 8.45249C8.33626 8.39411 8.38504 8.32391 8.41836 8.24613C8.45169 8.16834 8.46887 8.08461 8.46887 7.99998C8.46887 7.91536 8.45169 7.83162 8.41836 7.75384C8.38504 7.67606 8.33626 7.60586 8.275 7.54748Z" fill="black" />
                    </svg>
                    <a className='text-primary-400 ' href='simulate'>Simulate</a>
                </div>
            </div>

            <div className="mx-auto p-4 text-lg md:text-xl font-semibold">Indonesia Global Socioeconomic Position Research Center</div>

            <div className="row mx-auto p-4">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-4 md:gap-2 items-left text-left">
                        {/* Indicators */}
                        <div className="col-span-1">
                            <div className="col">
                                <label htmlFor="simCountrySelected">Select a country</label>
                                {/* <select value={selSimCtry} onChange={getCountry} id="simCountrySelected" className="w-full mt-1 border border-gray-300 rounded-md text-black p-2"> */}
                                <select value={selSimCtry} onChange={getCountry} id="simCountrySelected" className="w-full mt-1 border border-gray-300 rounded-md text-black p-2">
                                    {
                                        countries.map((opt, index) => <option key={index}>{opt}</option>)
                                    }
                                </select>

                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-4">
                                <div className="col-span-2">
                                    <p className="font-semibold">Indicator</p>
                                </div>

                                <div className="col-span-1 md:text-left">
                                    <p className="font-semibold">Simulate</p>
                                </div>
                            </div>
                            <hr className="my-2 border-t border-gray-600" />

                            <div className="mt-4">

                                <div className="grid grid-cols-3 gap-2 items-left text-left mt-4">
                                    <div className="col-span-2">
                                        <label className="container concept font-semibold">Socioeconomic Performance Index</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['SEPX']} disabled />
                                        </div>
                                        <div className="top">
                                            <input disabled value={sliderTop['SEPX']} onChange={setSimValue} id="SEPX" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" title="This is a composite sliderTop['and'] will adjust automatically depending on changes of its sub-indicators." />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2">
                                        <label className="container concept font-semibold">Macroeconomic Stability</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['macroStab']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['macroStab']} onChange={setSimValue} id="macroStab" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" title="This is a composite sliderTop['and'] will adjust automatically depending on changes of its sub-indicators." disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Price Stability</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['priceStab']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['priceStab']} onChange={setSimValue} id="priceStab" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Level of Employment</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['empLev']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['empLev']} onChange={setSimValue} id="empLev" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Fiscal Health</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['fiscHealth']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['fiscHealth']} onChange={setSimValue} id="fiscHealth" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Favorable Trade Balance</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['favTradeBal']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['favTradeBal']} onChange={setSimValue} id="favTradeBal" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2">
                                        <label className="container concept font-semibold">Microeconomic Competitiveness</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['microComp']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['microComp']} onChange={setSimValue} id="microComp" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" title="This is a composite sliderTop['and'] will adjust automatically depending on changes of its sub-indicators." disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Trade Competitiveness</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['tradeComp']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['tradeComp']} onChange={setSimValue} id="tradeComp" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Openess to Imports</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['openToImp']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['openToImp']} onChange={setSimValue} id="openToImp" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Political Responsiveness</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['polResp']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['polResp']} onChange={setSimValue} id="polResp" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Total Factor Productivity</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['tfp']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['tfp']} onChange={setSimValue} id="tfp" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2">
                                        <label className="container concept font-semibold">Social Cohesion</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['socCoh']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['socCoh']} onChange={setSimValue} id="socCoh" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" title="This is a composite sliderTop['and'] will adjust automatically depending on changes of its sub-indicators." disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Social Security</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['socSec']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['socSec']} onChange={setSimValue} id="socSec" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Personal Safety</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['persSafe']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['persSafe']} onChange={setSimValue} id="persSafe" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Gender Equality</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['genderEqual']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['genderEqual']} onChange={setSimValue} id="genderEqual" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Equitable Social Development</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['equitSocDev']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['equitSocDev']} onChange={setSimValue} id="equitSocDev" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2">
                                        <label className="container concept font-semibold">Human Empowerment</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['humEmp']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['humEmp']} onChange={setSimValue} id="humEmp" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" title="This is a composite sliderTop['and'] will adjust automatically depending on changes of its sub-indicators." disabled />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Scientific Output</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['scientificOut']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['scientificOut']} onChange={setSimValue} id="scientificOut" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">School Enrollment</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['schoolEnr']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['schoolEnr']} onChange={setSimValue} id="schoolEnr" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Infant Survivability</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['infSurv']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['infSurv']} onChange={setSimValue} id="infSurv" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Public Health</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['pubHealth']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['pubHealth']} onChange={setSimValue} id="pubHealth" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2">
                                        <label className="container concept font-semibold">Environmental Sustainability</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['envSust']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['envSust']} onChange={setSimValue} id="envSust" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Nature Conservation</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['natureCons']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['natureCons']} onChange={setSimValue} id="natureCons" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Access to Safe Water</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['safeWatAcc']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['safeWatAcc']} onChange={setSimValue} id="safeWatAcc" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Access to Clean Air</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['cleanAirAcc']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['cleanAirAcc']} onChange={setSimValue} id="cleanAirAcc" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-2 items-left text-left">
                                    <div className="col-span-2 ml-4">
                                        <label className="container subConcept">Natural Disaster Resilience</label>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="under">
                                            <input className="slider toolTipCell" type="range" min="0" max="5" step="1.25" value={sliderBg['natDisRes']} disabled />
                                        </div>
                                        <div className="top">
                                            <input value={sliderTop['natDisRes']} onChange={setSimValue} id="natDisRes" className="slider toolTipCell" type="range" min="0" max="5" step="1.25" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        {/* Grafik Map */}
                        <div className="col-span-3">
                            <div id="simChart">
                                <Chart
                                    chartType="ComboChart"
                                    width="100%"
                                    height="800px"
                                    data={simChrtData}
                                    options={options}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
};

export default SimulationPage;
