import React from "react";
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import useMode from '../hooks/useMode'


function FooterSection() {
  const { darkMode, setMode } = useMode();

  return (
    <footer className="bg-[#192F59]">
      <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 w-full mx-auto mt-8 justify-center items-center">
        <div className="col-span-1">
          <div className="md:mt-16 mt-8 flex flex-col items-start md:text-left mx-4 md:mx-16">
            <Link to="/" className="-m-1.5 p-1.5 bg-white rounded-lg">
              <span className="sr-only">IGSP</span>
              {darkMode ? (
                <img className="h-16 w-auto" src="igsp-logo-dark-mode.png" alt="" />
              ) : (
                <img className="h-16 w-auto" src="igsp-logo-light-mode.png" alt="" />
              )}
            </Link>
            <p className="text-lg font-semibold text-white mt-4 md:mt-8">
              Universitas Prasetiya Mulya
            </p>
            <p className="text-white text-base">BSD City Kavling Edutown</p>
            <p className="text-white text-base">Jl. BSD Raya Utama, BSD City 15339</p>
            <p className="text-white text-base">Kabupaten Tangerang, Indonesia</p>
          </div>
        </div>

        <div className="col-span-1">
          <div className="mt-4 md:mt-16 flex flex-col text-left mx-4 mx-4 md:mx-16">
            <h1 className="text-2xl md:text-4xl text-white font-semibold">Contact Info</h1>

            <div className="mt-2 md:mt-8">
              <p className="text-white text-lg font-semibold">Call Info:</p>
              <p className="text-white">Telp. (+62) 123 456 89</p>
              <p className="text-white">Wa. 0812-3456-7890 </p>
              <p className="text-white">Email. igsp.id@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <hr className="border-gray-400 my-4 mx-4 md:mx-auto w-11/12 md:w-10/12" />
      <div className="bg-[#192F59] text-center py-8">
        <p className="text-white text-base md:text-md">
          &copy; {new Date().getFullYear()} IGSP. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default FooterSection;
