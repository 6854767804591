import React, { useState } from 'react';
import backgroundAbout from '../images/contactus.jpg';

const ContactUsScreen = () => {
  const heroSectionStyle = {
    position: 'relative',
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundAbout})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // height: '75vh',
  };

  const textStyle = {
    position: 'relative',
    zIndex: 1, // Ensure the text is displayed in front of the image
    color: 'white',
    textAlign: 'center',
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // You can send the data to a server or perform any other action
    console.log('Form submitted:', { name, email, phone, company, message });
  };

  return (
    <>
      <div className='mb-16'>
        <div className="hero-section relative h-[50vh]" style={heroSectionStyle}>
          <div className="flex items-center justify-center h-full">
            <div style={textStyle}>
              <h1 className="text-4xl md:text-6xl font-semibold">Contact Us</h1>
            </div>
          </div>
        </div>

        <div className="container mx-auto mt-8 p-4">
          <form className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
          <h1 className="mt-4 text-2xl md:text-3xl font-semibold text-primary-400 mb-4 text-left">Send us your message</h1>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="grid-cols-1">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="name">
                  Name *
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="grid-cols-1">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="email">
                  Email *
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="grid-cols-1">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="phone">
                  Phone Number *
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  type="text"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="grid-cols-1">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="company">
                  Company *
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="company"
                  type="text"
                  placeholder="Enter your company name"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>  
            </div>
            <div className="grid-cols-1 gap-4 mt-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="message">
                Message *
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message"
                rows="4"
                placeholder="Enter your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="flex items-center justify-between mt-4">
              <button
                className="bg-primary-400 hover:bg-[#1E89B9] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Send Message
              </button>
            </div>
          </form>
          
        </div>
      </div>
    </>
  );
};

export default ContactUsScreen;
