import React from "react";
import backgroundCollab from "../images/collab.png";
const MAX_CHARACTERS = 100;


function Collaboration() {
    const heroSectionStyle = {
        position: "relative",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundCollab})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        // height: "75vh",
    };

    const textStyle = {
        position: "relative",
        zIndex: 1, // Ensure the text is displayed in front of the image
        color: "white",
        textAlign: "center",
    };

    const prasmul = "Collaborated with prestigious business university in website development";
    const largo = "Collaborated in creating website for one of the innovative e-bike named";

    const prasmulText = prasmul.length > MAX_CHARACTERS ? `${prasmul.slice(0, MAX_CHARACTERS)}...` : prasmul;
    const largoText = largo.length > MAX_CHARACTERS ? `${largo.slice(0, MAX_CHARACTERS)}...` : largo;

    return (
        <>
            <div className="mb-16">

                <div>
                    <div className="hero-section relative h-[50vh]" style={heroSectionStyle}>
                        <div className="flex items-center justify-center h-full">
                            <div style={textStyle}>
                                <h1 className="text-4xl md:text-6xl font-semibold">Business Collaboration</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:mt-16 container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6">

                        <a href="https://www.prasetiyamulya.ac.id/" target="_blank" rel="noopener noreferrer">
                            <div className="rounded-2xl shadow-lg bg-gray-200 p-4 md:p-6">
                                <div className="relative h-[150px] md:h-[250px] w-full">
                                    <img src={require("../images/prasmul.jpg")} alt="Prasmul" className="absolute inset-0 object-cover w-full h-full rounded-xl" />
                                </div>
                                <h1 className="mt-8 text-2xl md:text-3xl font-semibold text-primary-400 mb-4 text-left">Universitas Prasetiya Mulya</h1>
                                <p className="text-justify text-base deskripsi">{prasmulText}</p>
                            </div>
                        </a>

                        <a href="/" target="_blank" rel="noopener noreferrer">
                            <div className="rounded-2xl shadow-lg bg-gray-200 p-4 md:p-6">
                                <div className="relative h-[150px] md:h-[250px] w-full">
                                    <img src={require("../images/largo.jpg")} alt="Largo" className="absolute inset-0 object-cover w-full h-full rounded-xl" />
                                </div>
                                <h1 className="mt-8 text-2xl md:text-3xl font-semibold text-primary-400 mb-4 text-left">Largo E-bike</h1>
                                <p className="text-justify text-base deskripsi">{largoText}</p>
                            </div>
                        </a>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Collaboration