import React from "react";
import backgroundAbout from "../images/about-us.jpg";
import OurTeams from "./our_teams";

function AboutUsScreen() {

    const heroSectionStyle = {
        position: "relative",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundAbout})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        // height: "75vh",
    };

    const textStyle = {
        position: "relative",
        zIndex: 1, // Ensure the text is displayed in front of the image
        color: "white",
        textAlign: "center",
    };

    return (
        <>
            <div className="mb-16">
                <div className="hero-section relative h-[50vh]" style={heroSectionStyle}>
                    <div className="flex items-center justify-center h-full">
                        <div style={textStyle}>
                            <h1 className="text-4xl md:text-6xl font-semibold">About Us</h1>
                        </div>
                    </div>
                </div>

                <div className="container mx-auto md:mt-8 p-6">
                    <div className="text-left text-justify">
                        <h2 className="text-2xl md:text-4xl text-primary-400 font-bold">About IGSP</h2>
                        <p className="mt-2 :mt-8 text-base md:text-[18px] text-gray-700">
                            Indonesia, a thriving archipelago in Southeast Asia, has rapidly ascended the global socioeconomic ladder, guided by strategic policies and the collective spirit of its people. Boasting a robust economy with steady GDP growth and a prime position in international trade, Indonesia seeks to sustain its economic momentum by fostering innovation, attracting foreign investments, and distributing wealth equitably to uplift its citizens' lives. Embracing environmental responsibility, the nation is committed to striking a balance between economic progress and sustainability, with initiatives to combat deforestation, promote renewable energy, and protect biodiversity. Indonesia's strength lies in its cultural diversity, promoting harmony and unity amid various ethnicities and religions. As a global model for environmental stewardship and social cohesion, Indonesia aspires to forge a prosperous and harmonious future for all its citizens and leave a positive impact on the global stage.
                        </p>
                    </div>
                </div>

                <OurTeams />

            </div>
        </>
    )
}

export default AboutUsScreen;