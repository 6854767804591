import React from "react";
import backgroundProject from "../images/collab.png";
import useMode from '../hooks/useMode'


function ProjectScreen() {
    const { darkMode, setMode } = useMode();

    const heroSectionStyle = {
        position: "relative",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${backgroundProject})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        // height: "75vh",
    };

    const textStyle = {
        position: "relative",
        zIndex: 1, 
        color: "white",
        textAlign: "center",
    };

    return (
        <>
            <div className="mb-16">
                <div className="hero-section relative h-[60vh] md:h-[50vh]" style={heroSectionStyle}>
                    <div className="flex items-center justify-center h-full p-6">
                        <div style={textStyle}>
                            <h1 className="text-4xl md:text-6xl font-semibold">Indonesia's Socio-Economic Development</h1>
                            <p className="mt-8 text-base md:text-[20px] text-white md:text-justify container mx-auto">
                                Welcome to Indonesia's Socio-Economic Development Research Center, your ultimate gateway to comprehensive knowledge and profound insights into the nation's socioeconomic landscape. Our user-centric website offers an array of powerful features designed to empower researchers, policymakers, students, and anyone passionate about driving positive change in Indonesia's socioeconomic sphere. Get ready to embark on a transformative journey as we present our remarkable features:
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-8 container mx-auto p-4">

                    <div className="mx-auto grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 mt-4">

                        <div className={`col-span-1 rounded-xl shadow-lg p-6 ${darkMode ? 'bg-[#404040]' : 'bg-white'} hover:${darkMode ? 'bg-[#297A75]' : 'bg-gray-100'}`}>
                            <a href="/" className="card shadow-lg w-full">
                                <div className="card-content items-center text-start p-4 group-hover:fill-[#297A75]">
                                    <div className="flex items-center gap-4">
                                        <svg className={`${darkMode ? 'fill-white' : 'fill-gray-700'} group-hover:fill-[#297A75]`} xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 640 512" fill="none">
                                            <path d="M64 64V352H576V64H64zM0 64C0 28.7 28.7 0 64 0H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM128 448H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
                                        </svg>

                                        <p className="text-lg font-semibold">Walkthrough</p>
                                    </div>
                                    <div className="mt-4">
                                        <p className="md:text-1xl text-justify">See our Indonesia's global socioeconomic walkthrough here.</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={`col-span-1 rounded-xl shadow-lg p-6 ${darkMode ? 'bg-[#404040]' : 'bg-white'} hover:${darkMode ? 'bg-[#297A75]' : 'bg-gray-100'}`}>
                            <a href="/explore" className="card shadow-lg w-full">
                                <div className="card-content items-center text-start p-4 ">
                                    <div className="flex items-center gap-4">
                                        <svg className={`${darkMode ? 'fill-white' : 'fill-gray-700'} group-hover:fill-[#297A75]`} xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 44 44" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M22 3.875C19.6198 3.875 17.2629 4.34382 15.0639 5.25468C12.8648 6.16555 10.8668 7.50063 9.18369 9.18369C7.50063 10.8668 6.16555 12.8648 5.25468 15.0639C4.34382 17.2629 3.875 19.6198 3.875 22C3.875 24.3802 4.34382 26.7371 5.25468 28.9361C6.16555 31.1352 7.50063 33.1332 9.18369 34.8163C10.8668 36.4994 12.8648 37.8344 15.0639 38.7453C17.2629 39.6562 19.6198 40.125 22 40.125C26.807 40.125 31.4172 38.2154 34.8163 34.8163C38.2154 31.4172 40.125 26.807 40.125 22C40.125 17.193 38.2154 12.5828 34.8163 9.18369C31.4172 5.78459 26.807 3.875 22 3.875ZM0.125 22C0.125 16.1984 2.42968 10.6344 6.53204 6.53204C10.6344 2.42968 16.1984 0.125 22 0.125C27.8016 0.125 33.3656 2.42968 37.468 6.53204C41.5703 10.6344 43.875 16.1984 43.875 22C43.875 27.8016 41.5703 33.3656 37.468 37.468C33.3656 41.5703 27.8016 43.875 22 43.875C16.1984 43.875 10.6344 41.5703 6.53204 37.468C2.42968 33.3656 0.125 27.8016 0.125 22Z" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M19.125 15.2725C16.7912 17.1919 15.2316 19.8918 14.735 22.8725L13.64 29.455C13.0725 32.875 17.0475 35.1675 19.7225 32.9675L24.875 28.7275C27.2089 26.8081 28.7684 24.1082 29.265 21.1275L30.36 14.545C30.9275 11.125 26.9525 8.8325 24.2775 11.0325L19.125 15.275V15.2725ZM22 18.25C21.0055 18.25 20.0516 18.6451 19.3484 19.3484C18.6451 20.0516 18.25 21.0054 18.25 22C18.25 22.9946 18.6451 23.9484 19.3484 24.6517C20.0516 25.3549 21.0055 25.75 22 25.75C22.9946 25.75 23.9484 25.3549 24.6517 24.6517C25.3549 23.9484 25.75 22.9946 25.75 22C25.75 21.0054 25.3549 20.0516 24.6517 19.3484C23.9484 18.6451 22.9946 18.25 22 18.25Z" />
                                        </svg>

                                        <p className="text-lg font-semibold">Explore</p>
                                    </div>
                                    <div className="mt-4">
                                        <p className="md:text-1xl text-justify">Explore Indonesia's Socio-economic development from a global perspective over time.</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={`col-span-1 rounded-xl shadow-lg p-6 ${darkMode ? 'bg-[#404040]' : 'bg-white'} hover:${darkMode ? 'bg-[#297A75]' : 'bg-gray-100'}`}>
                            <a href="/compare" className="card shadow-lg w-full">
                                <div className="card-content items-center text-start p-4 group-hover:fill-[#297A75]">

                                    <div className="flex items-center gap-4">
                                        <svg className={`${darkMode ? 'fill-white' : 'fill-gray-700'} group-hover:fill-[#297A75]`} xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 44 44" fill="none">
                                            <path d="M17.525 23H0V28H17.525V35.5L27.5 25.5L17.525 15.5V23ZM32.475 20.5V13H50V8H32.475V0.5L22.5 10.5L32.475 20.5Z" />
                                        </svg>
                                        <p className="text-lg font-semibold">Compare</p>
                                    </div>
                                    <div className="mt-4">
                                        <p className="md:text-1xl text-justify">Compare Indonesia's Socio-economic development to another country and/or region over time.</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={`col-span-1 rounded-xl shadow-lg p-6 ${darkMode ? 'bg-[#404040]' : 'bg-white'} hover:${darkMode ? 'bg-[#297A75]' : 'bg-gray-100'}`}>
                            <a href="/simulation" className="card shadow-lg w-full">
                                <div className="card-content items-center text-start p-4 group-hover:fill-[#297A75]">
                                    <div className="flex items-center gap-4">
                                        <svg className={`${darkMode ? 'fill-white' : 'fill-gray-700'} group-hover:fill-[#297A75]`} xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 44 44" fill="none">
                                            <path d="M6.25 3.25C6.25 1.52148 4.85352 0.125 3.125 0.125C1.39648 0.125 0 1.52148 0 3.25V36.0625C0 40.3789 3.49609 43.875 7.8125 43.875H46.875C48.6035 43.875 50 42.4785 50 40.75C50 39.0215 48.6035 37.625 46.875 37.625H7.8125C6.95312 37.625 6.25 36.9219 6.25 36.0625V3.25ZM45.957 11.707C47.1777 10.4863 47.1777 8.50391 45.957 7.2832C44.7363 6.0625 42.7539 6.0625 41.5332 7.2832L31.25 17.5762L25.6445 11.9707C24.4238 10.75 22.4414 10.75 21.2207 11.9707L10.2832 22.9082C9.0625 24.1289 9.0625 26.1113 10.2832 27.332C11.5039 28.5527 13.4863 28.5527 14.707 27.332L23.4375 18.6113L29.043 24.2168C30.2637 25.4375 32.2461 25.4375 33.4668 24.2168L45.9668 11.7168L45.957 11.707Z" />
                                        </svg>

                                        <p className="text-lg font-semibold">Simulate</p>
                                    </div>
                                    <div className="mt-4">
                                        <p className="md:text-1xl text-justify">Simulate Indonesia's Socio-economic development potential.</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className={`col-span-1 rounded-xl shadow-lg p-6 ${darkMode ? 'bg-[#404040]' : 'bg-white'} hover:${darkMode ? 'bg-[#297A75]' : 'bg-gray-100'}`}>
                            <a href="/datacenter" className="card shadow-lg w-full">
                                <div className="card-content items-center text-start p-4 group-hover:fill-[#297A75]">
                                    <div className="flex items-center gap-4">
                                        <svg className={`${darkMode ? 'fill-white' : 'fill-gray-700'} group-hover:fill-[#297A75]`} xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 448 512" fill="none">
                                            <path d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z" />
                                        </svg>

                                        <p className="text-lg font-semibold">Data Center</p>
                                    </div>
                                    <div className="mt-4">
                                        <p className="md:text-1xl text-justify">Get our data of Indonesia's Global Socio-economic here.</p>
                                    </div>
                                </div>
                            </a>
                        </div>



                    </div>

                </div>
            </div>
        </>
    )
}

export default ProjectScreen