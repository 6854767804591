import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { countriesData } from "./first-data";
import { regionsData, countries, regions, years } from "./second-data";
import { Link } from "react-router-dom";


const DownloadData = () => {
  const generateExcelData = () => {
    const workbook = XLSX.utils.book_new();

    const addSheetToWorkbook = (data, sheetName) => {
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    };

    addSheetToWorkbook(countriesData, "CountriesData");
    addSheetToWorkbook(regionsData, "RegionsData");
    addSheetToWorkbook(countries, "Countries");
    addSheetToWorkbook(regions, "Regions");
    addSheetToWorkbook(years, "Years");

    const excelFileName = "igsp-data.xlsx";
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(data, excelFileName);
  };

  return (
    <div>
      <div className="grid grid-cols-6 md:grid-cols-12 md:mt-16 mt-8 container mx-auto items-center text-left gap-8 p-4">
        <div className="col-span-6">
          <h1 className="font-bold text-5xl">Data Center</h1>
          <p className="text-base mt-2">All of the data from Indonesia's Global Socio-economic Position can be downloaded here for free. Contact us for more data.</p>
          <div className="flex space-x-4">
            <div className="flex space-x-4">
              <button className="text-white bg-primary-400 p-4 rounded-xl mt-8 hover:bg-[#1E89B9]" onClick={generateExcelData}>Download Data</button>
              <Link to="/contact" className="bg-[#1E89B9] text-white p-4 rounded-xl mt-8 hover:bg-primary-400">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className="col-span-6">
          <img src={require("../images/data.png")} alt="Data Download" className="w-full" />
        </div>
      </div>
    </div>

  );
};

export default DownloadData;