import React from "react";
// import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import './our_teams.css'
import "react-slick"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useMode from '../hooks/useMode'

import teams1 from "../images/marcus_marktanner.jpg";
import teams2 from "../images/SE_Permata.png";
import teams3 from "../images/SE_Eko.png";
import teams4 from "../images/SE_Sesaria.png";

import Slider from "react-slick";

function OurTeams() {

  const { darkMode, setMode } = useMode();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const teams = [
    {
      image: teams1,
      name: "Marcus Marktanner",
      role: "Chief Research Officer",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum scelerisque justo, quis mollis velit dignissim nec.",
      instagram: '',
      facebook: '',
      twitter: '',
      linkedin: '',
    },
    {
      image: teams2,
      name: "Permata N.M.R., Ph.D",
      role: "Team Leads",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum scelerisque justo, quis mollis velit dignissim nec.",
      instagram: '',
      facebook: '',
      twitter: '',
      linkedin: '',
    },
    {
      image: teams3,
      name: "Eko Ariawan, M.M.",
      role: "Team Leads",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum scelerisque justo, quis mollis velit dignissim nec.",
      instagram: '',
      facebook: '',
      twitter: '',
      linkedin: '',
    },
    {
      image: teams4,
      name: "Sesaria Kiki Tamara",
      role: "Team Leads",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum scelerisque justo, quis mollis velit dignissim nec.",
      instagram: '',
      facebook: '',
      twitter: '',
      linkedin: '',
    },
    // {
    //   image: teams5,
    //   name: "Valen Ishen",
    //   role: "Project Management",
    //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum scelerisque justo, quis mollis velit dignissim nec.",
    //   instagram: '',
    //   facebook: '',
    //   twitter: '',
    //   linkedin: '',
    // },
    // {
    //   image: teams6,
    //   name: "Kenny W. Saputra",
    //   role: "Designer",
    //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum scelerisque justo, quis mollis velit dignissim nec.",
    //   instagram: '',
    //   facebook: '',
    //   twitter: '',
    //   linkedin: '',
    // },
    // {
    //   image: teams7,
    //   name: "Partogi P. Simatupang",
    //   role: "Frontend Developer",
    //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum scelerisque justo, quis mollis velit dignissim nec.",
    //   instagram: '',
    //   facebook: '',
    //   twitter: '',
    //   linkedin: '',
    // },
    // {
    //   image: teams8,
    //   name: "Muhammad Afdhal",
    //   role: "Backend Developer",
    //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum scelerisque justo, quis mollis velit dignissim nec.",
    //   instagram: '',
    //   facebook: '',
    //   twitter: '',
    //   linkedin: '',
    // },
    // {
    //   image: teams9,
    //   name: "Muchamad Rizki Fadillah",
    //   role: "Mern Stack",
    //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vestibulum scelerisque justo, quis mollis velit dignissim nec.",
    //   instagram: '',
    //   facebook: '',
    //   twitter: '',
    //   linkedin: '',
    // },
  ]

  return (
    <div className="carousel m-10 pb-8 md:pb-20">
      <h1 className="text-2xl md:text-3xl font-bold text-primary-400 mb-8">Our Teams</h1>

      <Slider {...settings}>
        {teams.map((item, index) => (
          <div key={index} className="card flex justify-center items-center">
            <div className={`text-center items-center w-400 rounded-xl shadow-lg p-4 m-4 md:p-8 ${darkMode ? 'bg-[#404040]' : 'bg-gray-200'}`}>
              <div className="flex justify-center">
                <img src={item.image} alt="Profile" className="w-[100px] h-[100px] rounded-full"></img>
              </div>
              <div className="mt-6">
                <p className="text-md font-semibold">{item.name}</p>
                <p className="text-sm">{item.role}</p>

                {/* Description and Sosial Media */}
                {/* <p className="mt-4">{item.description}</p>
                <div className="w-full mx-auto mt-2 md:mt-4">
                  <div className="flex justify-around md:justify-arround md:ml-8 md:mr-8">
                    <a href={item.instagram} className="text-lg font-semibold leading-6 text-gray-900 text-[#192F59] bg-white rounded-lg p-2">
                      <FaInstagram className="text-black" />
                    </a>
                    <a href={item.facebook} className="text-lg font-semibold leading-6 text-gray-900 text-[#4267B2] bg-white rounded-lg p-2">
                      <FaFacebook className="text-black" />
                    </a>
                    <a href={item.twitter} className="text-lg font-semibold leading-6 text-gray-900 text-[#1DA1F2] bg-white rounded-lg p-2">
                      <FaTwitter className="text-black" />
                    </a>
                    <a href={item.linkedin} className="text-lg font-semibold leading-6 text-gray-900 text-[#0077B5] bg-white rounded-lg p-2">
                      <FaLinkedin className="text-black" />
                    </a>
                  </div>
                </div> */}

              </div>
            </div>
          </div>
        ))}

      </Slider>

    </div>
  )
}

export default OurTeams