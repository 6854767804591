import React, { Fragment, useState, useEffect } from 'react'
import { FaDatabase, FaTv, FaSun, FaMoon } from 'react-icons/fa';
import { MdOutlineExplore, MdOutlineCompareArrows } from 'react-icons/md';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    ChartPieIcon,
    ScaleIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import useMode from '../hooks/useMode'
import '../index.css'
import { Link } from 'react-router-dom'

const projects = [
    {
        name: 'Indonesia Socioeconomics Development', description: 'Get a better understanding of your traffic', href: '/project', icon: ChartPieIcon,
        submenu: [
            { name: 'Walkthrough', href: '#', icon: FaTv },
            { name: 'Explore', href: '/explore', icon: MdOutlineExplore },
            { name: 'Compare', href: '/compare', icon: MdOutlineCompareArrows },
            { name: 'Simulate', href: '/simulation', icon: PlayCircleIcon },
            { name: 'Data Center', href: '/datacenter', icon: FaDatabase },
        ]
    },
    { name: "WEF's Global Competitiveness Rating", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', href: '/globalbusiness', icon: ScaleIcon },
    // { name: "Indonesia's Future Development Potential", description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', href: '/globalbusiness', icon: ScaleIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function HeaderNavigation() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [isSubMenuOpen, setisSubMenuOpen] = useState(false)

    const toogleSubMenu = () => {
        setisSubMenuOpen(!isSubMenuOpen)
    }

    const toogleLink = () => {
        setisSubMenuOpen(false)
    }


    const { darkMode, setMode } = useMode();

    // onClick={() => setMobileMenuOpen(false)}
    return (
        <header>
            {/* <header className="bg-white"> */}
            <nav className={"mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 navbar"} aria-label="Global">
                <div className="flex lg:flex-1">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">IGSP</span>
                        {darkMode ? (
                            <img className="h-16 w-auto" src="igsp-logo-dark-mode.png" alt="" />
                        ) : (
                            <img className="h-16 w-auto" src="igsp-logo-light-mode.png" alt="" />
                        )}
                    </Link>
                </div>
                {/* <input type="text" className='lg:hidden' placeholder="testing" /> */}
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        {darkMode ? (
                            <>
                                <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />

                            </>
                        ) : (
                            <>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />

                            </>
                        )}
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-4">
                    <Link to="/" className='text-gray-900 hover:bg-[#297A75] hover:text-white px-3 py-2 rounded-md text-base font-medium'>
                        Home
                    </Link>


                    <Popover className="relative">
                        <Popover.Button className="text-gray-900 hover:bg-[#297A75] hover:text-white px-3 py-2 rounded-md text-base font-medium">
                            <div className="flex items-center">
                                <span>Economic Projects</span>
                                <ChevronDownIcon className={`w-5 h-5 ml-1 ${darkMode ? 'text-white' : 'text-black'}`} />
                            </div>
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                <div className="p-6">

                                    {projects.map((item) => (
                                        <div
                                            key={item.name}
                                            className="overflow-visible relative group"
                                        >
                                            <Link to={item.href} className={`block text-sm text-left font-semibold leading-6 text-gray-900 whitespace-nowrap p-4 ${darkMode && '!text-black'} hover:!text-primary-400`}>
                                                {item.name}
                                            </Link>

                                            <div className='hidden group-hover:absolute group-hover:block right-[-12rem] top-0'>

                                                {item.submenu && (

                                                    <div
                                                        className={classNames(
                                                            'block left-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-lg'
                                                        )}
                                                    >

                                                        {item.submenu.map((subItem) => (
                                                            <Link
                                                                key={subItem.name}
                                                                to={subItem.href}
                                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            >
                                                                <div className={`flex items-center ${darkMode && '!text-black'} hover:!text-primary-400`}>
                                                                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                                        <subItem.icon className="h-6 w-6 text-gray-600 hover:text-primary-400" aria-hidden="true" />
                                                                    </div>
                                                                    {subItem.name}
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                    <Link to="/collaboration" className='text-gray-900 hover:bg-[#297A75] hover:text-white px-3 py-2 rounded-md text-base font-medium'>
                        Business Collaboration
                    </Link>
                    <Link to="/about" className='text-gray-900 hover:bg-[#297A75] hover:text-white px-3 py-2 rounded-md text-base font-medium'>
                        About us
                    </Link>
                    <Link to="/contact" className='text-gray-900 hover:bg-[#297A75] hover:text-white px-3 py-2 rounded-md text-base font-medium'>
                        Contact us
                    </Link>

                    <button
                        type="button"
                        className='text-gray-900 hover:bg-[#297A75] hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center'
                        onClick={() => { setMode(!darkMode) }}
                    >
                        {darkMode ? (
                            <>
                                <FaSun className="h-6 w-6 text-white" aria-hidden="true" />
                                <span className="ml-2 text-base">Light Mode</span>
                            </>
                        ) : (
                            <>
                                <FaMoon className="h-6 w-6" aria-hidden="true" />
                                <span className="ml-2 text-base">Dark Mode</span>
                            </>
                        )}
                    </button>
                </Popover.Group>
            </nav>

            {/* Gray line in light mode */}
            {!darkMode && <div className="w-full h-1 bg-gray-200"></div>}
            {/* White line in dark mode */}
            {darkMode && <div className="w-full h-1 bg-white"></div>}

            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                {/* <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"> */}
                <Dialog.Panel className={`fixed inset-y-0 right-0 z-10 w-full overflow-y-auto ${darkMode ? 'bg-black' : 'bg-white'} px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10`}>
                    <div className="flex items-center justify-between">
                        <Link onClick={() => setMobileMenuOpen(false)} to="/" className={`-m-1.5 p-1.5 ${darkMode ? 'text-white' : ''}`}>
                            <span className="sr-only">IGSP</span>
                            {darkMode ? (
                                <img className="h-16 w-auto" src="igsp-logo-dark-mode.png" alt="" />
                            ) : (
                                <img className="h-16 w-auto" src="igsp-logo-light-mode.png" alt="" />
                            )}
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className={`h-6 w-6 ${darkMode ? 'text-white' : ''}`} aria-hidden="true" />
                        </button>
                    </div>
                    <hr className="mt-4 border-t border-gray-600" />
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">

                                <Link
                                    to="/"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:text-primary-400 ${darkMode ? 'text-white' : 'text-gray-900'}`}
                                >
                                    Home
                                </Link>
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className={` flex w-full items-center justify-between rounded-lg px-3 py-2 pl-3 text-base font-semibold leading-7 text-gray-900 ${darkMode ? 'text-white' : ''}`}>
                                                Economic Projects
                                                <ChevronDownIcon
                                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                    aria-hidden="true"
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-2 space-y-2">
                                                {projects.map((item) => (
                                                    <Disclosure.Button
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className={`block rounded-lg pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 ${darkMode ? 'text-white' : ''}`}
                                                    >
                                                        <Link to={item.href} onClick={toogleSubMenu} className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 ${darkMode && '!text-white'}`}>
                                                            {item.name}
                                                            {item.submenu && (
                                                                <ChevronDownIcon className={`w-5 h-5 ml-1 ${darkMode ? 'text-white' : 'text-black'}`} />
                                                            )}
                                                        </Link>

                                                        {isSubMenuOpen && (
                                                            <div className='right-[-12rem] top-0'>
                                                                {item.submenu && (
                                                                    <div
                                                                        className={classNames(
                                                                            'block left-0 mt-2'
                                                                        )}
                                                                    >

                                                                        {item.submenu.map((subItem) => (
                                                                            <Link
                                                                                key={subItem.name}
                                                                                to={subItem.href}
                                                                                onClick={() => setMobileMenuOpen(false)}
                                                                                className={`block ml-8 py-2 text-sm text-gray-700 ${darkMode ? 'text-white' : 'text-black'}`}
                                                                            >
                                                                                <div className={`flex items-center ${darkMode && '!text-white'}`}>
                                                                                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg">
                                                                                        <subItem.icon className={`h-6 w-6 text-gray-600 ${darkMode ? 'text-white' : 'text-black'}`} />
                                                                                    </div>
                                                                                    {subItem.name}
                                                                                </div>
                                                                            </Link>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}

                                                    </Disclosure.Button>
                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <Link
                                    to="/collaboration"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${darkMode ? 'text-white' : 'text-gray-900'}`}
                                >
                                    Business Collaboration
                                </Link>
                                <Link
                                    to="/about"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${darkMode ? 'text-white' : 'text-gray-900'}`}
                                >
                                    About us
                                </Link>
                                <Link
                                    to="/contact"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${darkMode ? 'text-white' : 'text-gray-900'}`}
                                >
                                    Contact us
                                </Link>
                            </div>
                            <div className='py-6'>
                                <button
                                    type="button"
                                    className={`text-gray-900 bg-[#297A75] px-3 py-2 rounded-md text-sm font-medium flex items-center ${darkMode ? 'text-white' : 'text-white'}`}
                                    onClick={() => { setMode(!darkMode) }}
                                >
                                    {darkMode ? (
                                        <>
                                            <FaSun onClick={() => setMobileMenuOpen(false)} className="h-6 w-6" aria-hidden="true" />
                                            <span className="ml-2">Light Mode</span>
                                        </>
                                    ) : (
                                        <>
                                            <FaMoon onClick={() => setMobileMenuOpen(false)} className="h-6 w-6" aria-hidden="true" />
                                            <span className="ml-2">Dark Mode</span>
                                        </>
                                    )}
                                </button>

                            </div>

                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}


export default HeaderNavigation;