let dataSet = [
    {
        "Country": "Albania",
        "Alpha2": "AL",
        "Alpha3": "ALB",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 51.87,
        "2nd pillar: Infrastructure": 57.69,
        "3rd pillar: ICT adoption": 52.88,
        "4th pillar: Macroeconomic stability": 70,
        "5th pillar: Health": 85.89,
        "6th pillar: Skills": 68.97,
        "7th pillar: Product market": 54.36,
        "8th pillar: Labour market": 65.26,
        "9th pillar: Financial system": 53.29,
        "10th pillar: Market size": 39.58,
        "11th pillar: Business dynamism": 61.8,
        "12th pillar: Innovation capability": 29.76
    },
    {
        "Country": "Algeria",
        "Alpha2": "DZ",
        "Alpha3": "DZA",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 45.53,
        "2nd pillar: Infrastructure": 63.79,
        "3rd pillar: ICT adoption": 52.72,
        "4th pillar: Macroeconomic stability": 71.2,
        "5th pillar: Health": 82.76,
        "6th pillar: Skills": 59.07,
        "7th pillar: Product market": 45.79,
        "8th pillar: Labour market": 47.1,
        "9th pillar: Financial system": 50.05,
        "10th pillar: Market size": 66.47,
        "11th pillar: Business dynamism": 56.2,
        "12th pillar: Innovation capability": 34.38
    },
    {
        "Country": "Angola",
        "Alpha2": "AO",
        "Alpha3": "AGO",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 37.62,
        "2nd pillar: Infrastructure": 40.2,
        "3rd pillar: ICT adoption": 30.55,
        "4th pillar: Macroeconomic stability": 40.61,
        "5th pillar: Health": 46.9,
        "6th pillar: Skills": 29.08,
        "7th pillar: Product market": 37.71,
        "8th pillar: Labour market": 46.83,
        "9th pillar: Financial system": 38.42,
        "10th pillar: Market size": 53.88,
        "11th pillar: Business dynamism": 36.75,
        "12th pillar: Innovation capability": 18.82
    },
    {
        "Country": "Argentina",
        "Alpha2": "AR",
        "Alpha3": "ARG",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 49.85,
        "2nd pillar: Infrastructure": 68.29,
        "3rd pillar: ICT adoption": 57.99,
        "4th pillar: Macroeconomic stability": 33.92,
        "5th pillar: Health": 83.8,
        "6th pillar: Skills": 72.25,
        "7th pillar: Product market": 46.96,
        "8th pillar: Labour market": 51.84,
        "9th pillar: Financial system": 52.85,
        "10th pillar: Market size": 68.6,
        "11th pillar: Business dynamism": 58.3,
        "12th pillar: Innovation capability": 41.75
    },
    {
        "Country": "Armenia",
        "Alpha2": "AM",
        "Alpha3": "ARM",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 56.25,
        "2nd pillar: Infrastructure": 69.41,
        "3rd pillar: ICT adoption": 62.02,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 80.72,
        "6th pillar: Skills": 66.78,
        "7th pillar: Product market": 59.1,
        "8th pillar: Labour market": 66.43,
        "9th pillar: Financial system": 60.16,
        "10th pillar: Market size": 37.52,
        "11th pillar: Business dynamism": 62.55,
        "12th pillar: Innovation capability": 39.39
    },
    {
        "Country": "Australia",
        "Alpha2": "AU",
        "Alpha3": "AUS",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 72.94,
        "2nd pillar: Infrastructure": 79.16,
        "3rd pillar: ICT adoption": 73.62,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 94.91,
        "6th pillar: Skills": 80.56,
        "7th pillar: Product market": 71.39,
        "8th pillar: Labour market": 69.07,
        "9th pillar: Financial system": 85.89,
        "10th pillar: Market size": 72.62,
        "11th pillar: Business dynamism": 75.29,
        "12th pillar: Innovation capability": 69.53
    },
    {
        "Country": "Austria",
        "Alpha2": "AT",
        "Alpha3": "AUT",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 73.54,
        "2nd pillar: Infrastructure": 89.03,
        "3rd pillar: ICT adoption": 65.63,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 95.12,
        "6th pillar: Skills": 79.35,
        "7th pillar: Product market": 66.07,
        "8th pillar: Labour market": 67.16,
        "9th pillar: Financial system": 74.96,
        "10th pillar: Market size": 64.63,
        "11th pillar: Business dynamism": 69.35,
        "12th pillar: Innovation capability": 74.47
    },
    {
        "Country": "Azerbaijan",
        "Alpha2": "AZ",
        "Alpha3": "AZE",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 58.47,
        "2nd pillar: Infrastructure": 77.36,
        "3rd pillar: ICT adoption": 55.07,
        "4th pillar: Macroeconomic stability": 70.04,
        "5th pillar: Health": 68.9,
        "6th pillar: Skills": 69.76,
        "7th pillar: Product market": 64.31,
        "8th pillar: Labour market": 69.44,
        "9th pillar: Financial system": 55.39,
        "10th pillar: Market size": 54.02,
        "11th pillar: Business dynamism": 71.52,
        "12th pillar: Innovation capability": 38.33
    },
    {
        "Country": "Bahrain",
        "Alpha2": "BH",
        "Alpha3": "BHR",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 62.9,
        "2nd pillar: Infrastructure": 78.39,
        "3rd pillar: ICT adoption": 67.19,
        "4th pillar: Macroeconomic stability": 68.3,
        "5th pillar: Health": 86.9,
        "6th pillar: Skills": 68.71,
        "7th pillar: Product market": 65.11,
        "8th pillar: Labour market": 66.43,
        "9th pillar: Financial system": 71.33,
        "10th pillar: Market size": 46.26,
        "11th pillar: Business dynamism": 64.31,
        "12th pillar: Innovation capability": 38.72
    },
    {
        "Country": "Bangladesh",
        "Alpha2": "BD",
        "Alpha3": "BGD",
        "Region": "South Asia",
        "1st pillar: Institutions": 45.94,
        "2nd pillar: Infrastructure": 51.14,
        "3rd pillar: ICT adoption": 39.14,
        "4th pillar: Macroeconomic stability": 72.78,
        "5th pillar: Health": 72.07,
        "6th pillar: Skills": 46.09,
        "7th pillar: Product market": 47.03,
        "8th pillar: Labour market": 51.22,
        "9th pillar: Financial system": 52.11,
        "10th pillar: Market size": 67.41,
        "11th pillar: Business dynamism": 49.89,
        "12th pillar: Innovation capability": 30.67
    },
    {
        "Country": "Barbados",
        "Alpha2": "BB",
        "Alpha3": "BRB",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 55.18,
        "2nd pillar: Infrastructure": 57.73,
        "3rd pillar: ICT adoption": 76.42,
        "4th pillar: Macroeconomic stability": 69.97,
        "5th pillar: Health": 87.15,
        "6th pillar: Skills": 64.99,
        "7th pillar: Product market": 43.75,
        "8th pillar: Labour market": 61.96,
        "9th pillar: Financial system": 71.34,
        "10th pillar: Market size": 18.91,
        "11th pillar: Business dynamism": 60.39,
        "12th pillar: Innovation capability": 39.06
    },
    {
        "Country": "Belgium",
        "Alpha2": "BE",
        "Alpha3": "BEL",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 69.46,
        "2nd pillar: Infrastructure": 87.26,
        "3rd pillar: ICT adoption": 67.02,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 92.58,
        "6th pillar: Skills": 79.28,
        "7th pillar: Product market": 62.56,
        "8th pillar: Labour market": 63.78,
        "9th pillar: Financial system": 79.48,
        "10th pillar: Market size": 69.28,
        "11th pillar: Business dynamism": 74.44,
        "12th pillar: Innovation capability": 71.42
    },
    {
        "Country": "Benin",
        "Alpha2": "BJ",
        "Alpha3": "BEN",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 48.04,
        "2nd pillar: Infrastructure": 40.17,
        "3rd pillar: ICT adoption": 23.35,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 49.5,
        "6th pillar: Skills": 43.27,
        "7th pillar: Product market": 54.57,
        "8th pillar: Labour market": 53.72,
        "9th pillar: Financial system": 44.47,
        "10th pillar: Market size": 35.65,
        "11th pillar: Business dynamism": 53.75,
        "12th pillar: Innovation capability": 28.4
    },
    {
        "Country": "Bolivia, Plurinational State of",
        "Alpha2": "BO",
        "Alpha3": "BOL",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 38.46,
        "2nd pillar: Infrastructure": 57.14,
        "3rd pillar: ICT adoption": 51.45,
        "4th pillar: Macroeconomic stability": 74.2,
        "5th pillar: Health": 73.73,
        "6th pillar: Skills": 57.88,
        "7th pillar: Product market": 44.47,
        "8th pillar: Labour market": 46.08,
        "9th pillar: Financial system": 58.01,
        "10th pillar: Market size": 45.9,
        "11th pillar: Business dynamism": 46.6,
        "12th pillar: Innovation capability": 27.68
    },
    {
        "Country": "Bosnia and Herzegovina",
        "Alpha2": "BA",
        "Alpha3": "BIH",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 44.38,
        "2nd pillar: Infrastructure": 63.02,
        "3rd pillar: ICT adoption": 51.61,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 80.33,
        "6th pillar: Skills": 59.96,
        "7th pillar: Product market": 49.61,
        "8th pillar: Labour market": 53.25,
        "9th pillar: Financial system": 57.86,
        "10th pillar: Market size": 42.29,
        "11th pillar: Business dynamism": 51.1,
        "12th pillar: Innovation capability": 28.35
    },
    {
        "Country": "Botswana",
        "Alpha2": "BW",
        "Alpha3": "BWA",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 54.24,
        "2nd pillar: Infrastructure": 53.7,
        "3rd pillar: ICT adoption": 45.5,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 59.04,
        "6th pillar: Skills": 56.83,
        "7th pillar: Product market": 52.17,
        "8th pillar: Labour market": 60.24,
        "9th pillar: Financial system": 59.72,
        "10th pillar: Market size": 39.23,
        "11th pillar: Business dynamism": 53.84,
        "12th pillar: Innovation capability": 31.44
    },
    {
        "Country": "Brazil",
        "Alpha2": "BR",
        "Alpha3": "BRA",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 48.05,
        "2nd pillar: Infrastructure": 65.45,
        "3rd pillar: ICT adoption": 58.06,
        "4th pillar: Macroeconomic stability": 69.38,
        "5th pillar: Health": 79.38,
        "6th pillar: Skills": 56.43,
        "7th pillar: Product market": 45.88,
        "8th pillar: Labour market": 53.46,
        "9th pillar: Financial system": 64.62,
        "10th pillar: Market size": 81.31,
        "11th pillar: Business dynamism": 60.22,
        "12th pillar: Innovation capability": 48.91
    },
    {
        "Country": "Brunei Darussalam",
        "Alpha2": "BN",
        "Alpha3": "BRN",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 58.28,
        "2nd pillar: Infrastructure": 70.07,
        "3rd pillar: ICT adoption": 75.38,
        "4th pillar: Macroeconomic stability": 74.25,
        "5th pillar: Health": 81.78,
        "6th pillar: Skills": 67.03,
        "7th pillar: Product market": 60.42,
        "8th pillar: Labour market": 66.97,
        "9th pillar: Financial system": 55.12,
        "10th pillar: Market size": 38.16,
        "11th pillar: Business dynamism": 61.84,
        "12th pillar: Innovation capability": 43.81
    },
    {
        "Country": "Bulgaria",
        "Alpha2": "BG",
        "Alpha3": "BGR",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 56.8,
        "2nd pillar: Infrastructure": 71.33,
        "3rd pillar: ICT adoption": 73.39,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 77.73,
        "6th pillar: Skills": 67.94,
        "7th pillar: Product market": 55.69,
        "8th pillar: Labour market": 64.59,
        "9th pillar: Financial system": 59.59,
        "10th pillar: Market size": 54.88,
        "11th pillar: Business dynamism": 61.86,
        "12th pillar: Innovation capability": 44.95
    },
    {
        "Country": "Burkina Faso",
        "Alpha2": "BF",
        "Alpha3": "BFA",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 48.53,
        "2nd pillar: Infrastructure": 34.82,
        "3rd pillar: ICT adoption": 26.8,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 41.97,
        "6th pillar: Skills": 31.53,
        "7th pillar: Product market": 50.31,
        "8th pillar: Labour market": 52.36,
        "9th pillar: Financial system": 46.19,
        "10th pillar: Market size": 38.87,
        "11th pillar: Business dynamism": 49.87,
        "12th pillar: Innovation capability": 24.81
    },
    {
        "Country": "Burundi",
        "Alpha2": "BI",
        "Alpha3": "BDI",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 40.73,
        "2nd pillar: Infrastructure": 39.17,
        "3rd pillar: ICT adoption": 14.84,
        "4th pillar: Macroeconomic stability": 61.86,
        "5th pillar: Health": 43.12,
        "6th pillar: Skills": 36.57,
        "7th pillar: Product market": 47.88,
        "8th pillar: Labour market": 50.71,
        "9th pillar: Financial system": 47.54,
        "10th pillar: Market size": 22.48,
        "11th pillar: Business dynamism": 53.7,
        "12th pillar: Innovation capability": 24.42
    },
    {
        "Country": "Cape Verde",
        "Alpha2": "CV",
        "Alpha3": "CPV",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 51.24,
        "2nd pillar: Infrastructure": 53.69,
        "3rd pillar: ICT adoption": 44.72,
        "4th pillar: Macroeconomic stability": 70,
        "5th pillar: Health": 80.8,
        "6th pillar: Skills": 53.18,
        "7th pillar: Product market": 50.15,
        "8th pillar: Labour market": 61.42,
        "9th pillar: Financial system": 58.51,
        "10th pillar: Market size": 17.51,
        "11th pillar: Business dynamism": 43.95,
        "12th pillar: Innovation capability": 24.82
    },
    {
        "Country": "Cambodia",
        "Alpha2": "KH",
        "Alpha3": "KHM",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 41.91,
        "2nd pillar: Infrastructure": 54.89,
        "3rd pillar: ICT adoption": 55.39,
        "4th pillar: Macroeconomic stability": 74.95,
        "5th pillar: Health": 64.82,
        "6th pillar: Skills": 42.66,
        "7th pillar: Product market": 48.46,
        "8th pillar: Labour market": 60.27,
        "9th pillar: Financial system": 56.39,
        "10th pillar: Market size": 47.79,
        "11th pillar: Business dynamism": 46.55,
        "12th pillar: Innovation capability": 30.88
    },
    {
        "Country": "Cameroon",
        "Alpha2": "CM",
        "Alpha3": "CMR",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 43.73,
        "2nd pillar: Infrastructure": 40.08,
        "3rd pillar: ICT adoption": 24.8,
        "4th pillar: Macroeconomic stability": 74.91,
        "5th pillar: Health": 45.28,
        "6th pillar: Skills": 47.75,
        "7th pillar: Product market": 48.51,
        "8th pillar: Labour market": 52.07,
        "9th pillar: Financial system": 45.37,
        "10th pillar: Market size": 46.5,
        "11th pillar: Business dynamism": 52.43,
        "12th pillar: Innovation capability": 30.79
    },
    {
        "Country": "Canada",
        "Alpha2": "CA",
        "Alpha3": "CAN",
        "Region": "North America",
        "1st pillar: Institutions": 74.08,
        "2nd pillar: Infrastructure": 80.78,
        "3rd pillar: ICT adoption": 70.29,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 95.35,
        "6th pillar: Skills": 81.06,
        "7th pillar: Product market": 63.8,
        "8th pillar: Labour market": 75.18,
        "9th pillar: Financial system": 87.11,
        "10th pillar: Market size": 76.9,
        "11th pillar: Business dynamism": 76.51,
        "12th pillar: Innovation capability": 74.05
    },
    {
        "Country": "Chad",
        "Alpha2": "TD",
        "Alpha3": "TCD",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 35.42,
        "2nd pillar: Infrastructure": 30.53,
        "3rd pillar: ICT adoption": 10.77,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 35.87,
        "6th pillar: Skills": 29.04,
        "7th pillar: Product market": 35.43,
        "8th pillar: Labour market": 42.22,
        "9th pillar: Financial system": 37.31,
        "10th pillar: Market size": 37.09,
        "11th pillar: Business dynamism": 29.67,
        "12th pillar: Innovation capability": 22.66
    },
    {
        "Country": "Chile",
        "Alpha2": "CL",
        "Alpha3": "CHL",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 63.89,
        "2nd pillar: Infrastructure": 76.29,
        "3rd pillar: ICT adoption": 63.13,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 89.65,
        "6th pillar: Skills": 69.81,
        "7th pillar: Product market": 67.97,
        "8th pillar: Labour market": 62.8,
        "9th pillar: Financial system": 81.98,
        "10th pillar: Market size": 63.18,
        "11th pillar: Business dynamism": 65.26,
        "12th pillar: Innovation capability": 42.48
    },
    {
        "Country": "China",
        "Alpha2": "CN",
        "Alpha3": "CHN",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 56.78,
        "2nd pillar: Infrastructure": 77.91,
        "3rd pillar: ICT adoption": 78.49,
        "4th pillar: Macroeconomic stability": 98.78,
        "5th pillar: Health": 87.79,
        "6th pillar: Skills": 64.1,
        "7th pillar: Product market": 57.56,
        "8th pillar: Labour market": 59.24,
        "9th pillar: Financial system": 74.96,
        "10th pillar: Market size": 100,
        "11th pillar: Business dynamism": 66.39,
        "12th pillar: Innovation capability": 64.82
    },
    {
        "Country": "Colombia",
        "Alpha2": "CO",
        "Alpha3": "COL",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 49.25,
        "2nd pillar: Infrastructure": 64.31,
        "3rd pillar: ICT adoption": 49.89,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 94.99,
        "6th pillar: Skills": 60.48,
        "7th pillar: Product market": 52.7,
        "8th pillar: Labour market": 59.16,
        "9th pillar: Financial system": 64.63,
        "10th pillar: Market size": 66.72,
        "11th pillar: Business dynamism": 64.2,
        "12th pillar: Innovation capability": 36.44
    },
    {
        "Country": "Congo, the Democratic Republic of the",
        "Alpha2": "CD",
        "Alpha3": "COD",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 32.78,
        "2nd pillar: Infrastructure": 29.23,
        "3rd pillar: ICT adoption": 19.11,
        "4th pillar: Macroeconomic stability": 31.39,
        "5th pillar: Health": 41.6,
        "6th pillar: Skills": 42.3,
        "7th pillar: Product market": 44.73,
        "8th pillar: Labour market": 48.3,
        "9th pillar: Financial system": 42.53,
        "10th pillar: Market size": 43.27,
        "11th pillar: Business dynamism": 40.47,
        "12th pillar: Innovation capability": 17.97
    },
    {
        "Country": "Costa Rica",
        "Alpha2": "CR",
        "Alpha3": "CRI",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 57.13,
        "2nd pillar: Infrastructure": 68.74,
        "3rd pillar: ICT adoption": 59.97,
        "4th pillar: Macroeconomic stability": 74.35,
        "5th pillar: Health": 93.23,
        "6th pillar: Skills": 68.95,
        "7th pillar: Product market": 59.38,
        "8th pillar: Labour market": 59.09,
        "9th pillar: Financial system": 60.06,
        "10th pillar: Market size": 46.54,
        "11th pillar: Business dynamism": 56.33,
        "12th pillar: Innovation capability": 40.3
    },
    {
        "Country": "C�te d'Ivoire",
        "Alpha2": "CI",
        "Alpha3": "CIV",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 42.36,
        "2nd pillar: Infrastructure": 47.88,
        "3rd pillar: ICT adoption": 41.28,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 44.23,
        "6th pillar: Skills": 40.59,
        "7th pillar: Product market": 51.75,
        "8th pillar: Labour market": 52.84,
        "9th pillar: Financial system": 45.14,
        "10th pillar: Market size": 48.38,
        "11th pillar: Business dynamism": 57.57,
        "12th pillar: Innovation capability": 30.73
    },
    {
        "Country": "Croatia",
        "Alpha2": "HR",
        "Alpha3": "HRV",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 51.77,
        "2nd pillar: Infrastructure": 78.22,
        "3rd pillar: ICT adoption": 60.69,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 85.69,
        "6th pillar: Skills": 63.47,
        "7th pillar: Product market": 53.16,
        "8th pillar: Labour market": 55.96,
        "9th pillar: Financial system": 61.92,
        "10th pillar: Market size": 49.97,
        "11th pillar: Business dynamism": 54.65,
        "12th pillar: Innovation capability": 37.76
    },
    {
        "Country": "Cyprus",
        "Alpha2": "CY",
        "Alpha3": "CYP",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 63.99,
        "2nd pillar: Infrastructure": 74.91,
        "3rd pillar: ICT adoption": 62.04,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 95.87,
        "6th pillar: Skills": 72.18,
        "7th pillar: Product market": 61.1,
        "8th pillar: Labour market": 66.14,
        "9th pillar: Financial system": 58.17,
        "10th pillar: Market size": 39.68,
        "11th pillar: Business dynamism": 66.23,
        "12th pillar: Innovation capability": 46.31
    },
    {
        "Country": "Czech Republic",
        "Alpha2": "CZ",
        "Alpha3": "CZE",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 60.89,
        "2nd pillar: Infrastructure": 83.81,
        "3rd pillar: ICT adoption": 68.38,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 85.64,
        "6th pillar: Skills": 72.88,
        "7th pillar: Product market": 57.34,
        "8th pillar: Labour market": 63.3,
        "9th pillar: Financial system": 67.57,
        "10th pillar: Market size": 64.84,
        "11th pillar: Business dynamism": 68.66,
        "12th pillar: Innovation capability": 56.92
    },
    {
        "Country": "Denmark",
        "Alpha2": "DK",
        "Alpha3": "DNK",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 77.4,
        "2nd pillar: Infrastructure": 87.09,
        "3rd pillar: ICT adoption": 83.3,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 92.59,
        "6th pillar: Skills": 85.69,
        "7th pillar: Product market": 66.91,
        "8th pillar: Labour market": 78.24,
        "9th pillar: Financial system": 86.81,
        "10th pillar: Market size": 59.87,
        "11th pillar: Business dynamism": 79.99,
        "12th pillar: Innovation capability": 76.21
    },
    {
        "Country": "Dominican Republic",
        "Alpha2": "DO",
        "Alpha3": "DOM",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 50.06,
        "2nd pillar: Infrastructure": 64.92,
        "3rd pillar: ICT adoption": 51.79,
        "4th pillar: Macroeconomic stability": 74.93,
        "5th pillar: Health": 75.71,
        "6th pillar: Skills": 58.68,
        "7th pillar: Product market": 53.7,
        "8th pillar: Labour market": 62.88,
        "9th pillar: Financial system": 61.55,
        "10th pillar: Market size": 53.79,
        "11th pillar: Business dynamism": 57.1,
        "12th pillar: Innovation capability": 34.62
    },
    {
        "Country": "Ecuador",
        "Alpha2": "EC",
        "Alpha3": "ECU",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 47.78,
        "2nd pillar: Infrastructure": 69.13,
        "3rd pillar: ICT adoption": 47.62,
        "4th pillar: Macroeconomic stability": 73.67,
        "5th pillar: Health": 85.04,
        "6th pillar: Skills": 61.39,
        "7th pillar: Product market": 43.32,
        "8th pillar: Labour market": 51.84,
        "9th pillar: Financial system": 56.34,
        "10th pillar: Market size": 53.97,
        "11th pillar: Business dynamism": 45.72,
        "12th pillar: Innovation capability": 33.01
    },
    {
        "Country": "Egypt",
        "Alpha2": "EG",
        "Alpha3": "EGY",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 51.33,
        "2nd pillar: Infrastructure": 73.05,
        "3rd pillar: ICT adoption": 40.57,
        "4th pillar: Macroeconomic stability": 44.72,
        "5th pillar: Health": 65.02,
        "6th pillar: Skills": 54.2,
        "7th pillar: Product market": 50.74,
        "8th pillar: Labour market": 49.5,
        "9th pillar: Financial system": 56.11,
        "10th pillar: Market size": 73.57,
        "11th pillar: Business dynamism": 56.08,
        "12th pillar: Innovation capability": 39.62
    },
    {
        "Country": "El Salvador",
        "Alpha2": "SV",
        "Alpha3": "SLV",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 39.84,
        "2nd pillar: Infrastructure": 61.02,
        "3rd pillar: ICT adoption": 40.64,
        "4th pillar: Macroeconomic stability": 69.74,
        "5th pillar: Health": 78.12,
        "6th pillar: Skills": 48.44,
        "7th pillar: Product market": 53.94,
        "8th pillar: Labour market": 53.41,
        "9th pillar: Financial system": 62.25,
        "10th pillar: Market size": 42.85,
        "11th pillar: Business dynamism": 52.69,
        "12th pillar: Innovation capability": 27.92
    },
    {
        "Country": "Estonia",
        "Alpha2": "EE",
        "Alpha3": "EST",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 70.23,
        "2nd pillar: Infrastructure": 75.77,
        "3rd pillar: ICT adoption": 78.77,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 84.46,
        "6th pillar: Skills": 79.36,
        "7th pillar: Product market": 61.97,
        "8th pillar: Labour market": 70.23,
        "9th pillar: Financial system": 65.21,
        "10th pillar: Market size": 42.83,
        "11th pillar: Business dynamism": 69.94,
        "12th pillar: Innovation capability": 52.1
    },
    {
        "Country": "Ethiopia",
        "Alpha2": "ET",
        "Alpha3": "ETH",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 41.75,
        "2nd pillar: Infrastructure": 43.4,
        "3rd pillar: ICT adoption": 20.12,
        "4th pillar: Macroeconomic stability": 63.54,
        "5th pillar: Health": 61.04,
        "6th pillar: Skills": 32.46,
        "7th pillar: Product market": 40.82,
        "8th pillar: Labour market": 49.99,
        "9th pillar: Financial system": 50.61,
        "10th pillar: Market size": 55.15,
        "11th pillar: Business dynamism": 45.28,
        "12th pillar: Innovation capability": 28.33
    },
    {
        "Country": "Finland",
        "Alpha2": "FI",
        "Alpha3": "FIN",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 81.22,
        "2nd pillar: Infrastructure": 83.4,
        "3rd pillar: ICT adoption": 80.39,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 93.05,
        "6th pillar: Skills": 85.7,
        "7th pillar: Product market": 66.31,
        "8th pillar: Labour market": 71.52,
        "9th pillar: Financial system": 89.65,
        "10th pillar: Market size": 57.76,
        "11th pillar: Business dynamism": 78.15,
        "12th pillar: Innovation capability": 75.81
    },
    {
        "Country": "France",
        "Alpha2": "FR",
        "Alpha3": "FRA",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 70.04,
        "2nd pillar: Infrastructure": 89.74,
        "3rd pillar: ICT adoption": 73.66,
        "4th pillar: Macroeconomic stability": 99.85,
        "5th pillar: Health": 99.21,
        "6th pillar: Skills": 71.94,
        "7th pillar: Product market": 62.23,
        "8th pillar: Labour market": 62.93,
        "9th pillar: Financial system": 85.87,
        "10th pillar: Market size": 81.65,
        "11th pillar: Business dynamism": 71.38,
        "12th pillar: Innovation capability": 77.18
    },
    {
        "Country": "Gabon",
        "Alpha2": "GA",
        "Alpha3": "GAB",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 40.85,
        "2nd pillar: Infrastructure": 46.16,
        "3rd pillar: ICT adoption": 50.78,
        "4th pillar: Macroeconomic stability": 74.93,
        "5th pillar: Health": 58.55,
        "6th pillar: Skills": 51.06,
        "7th pillar: Product market": 41.47,
        "8th pillar: Labour market": 49.17,
        "9th pillar: Financial system": 43.46,
        "10th pillar: Market size": 37.79,
        "11th pillar: Business dynamism": 46.35,
        "12th pillar: Innovation capability": 28.96
    },
    {
        "Country": "Gambia",
        "Alpha2": "GM",
        "Alpha3": "GMB",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 48.53,
        "2nd pillar: Infrastructure": 47.36,
        "3rd pillar: ICT adoption": 31.42,
        "4th pillar: Macroeconomic stability": 65.44,
        "5th pillar: Health": 52.25,
        "6th pillar: Skills": 45.03,
        "7th pillar: Product market": 54.24,
        "8th pillar: Labour market": 55.03,
        "9th pillar: Financial system": 49.66,
        "10th pillar: Market size": 20.58,
        "11th pillar: Business dynamism": 51.04,
        "12th pillar: Innovation capability": 30.48
    },
    {
        "Country": "Georgia",
        "Alpha2": "GE",
        "Alpha3": "GEO",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 60.98,
        "2nd pillar: Infrastructure": 67.59,
        "3rd pillar: ICT adoption": 63.73,
        "4th pillar: Macroeconomic stability": 74.42,
        "5th pillar: Health": 74.38,
        "6th pillar: Skills": 69.85,
        "7th pillar: Product market": 58.41,
        "8th pillar: Labour market": 65.34,
        "9th pillar: Financial system": 56.17,
        "10th pillar: Market size": 41.6,
        "11th pillar: Business dynamism": 62.19,
        "12th pillar: Innovation capability": 32.68
    },
    {
        "Country": "Germany",
        "Alpha2": "DE",
        "Alpha3": "DEU",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 72.39,
        "2nd pillar: Infrastructure": 90.21,
        "3rd pillar: ICT adoption": 69.98,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 92.33,
        "6th pillar: Skills": 84.18,
        "7th pillar: Product market": 68.2,
        "8th pillar: Labour market": 72.76,
        "9th pillar: Financial system": 79.11,
        "10th pillar: Market size": 86.04,
        "11th pillar: Business dynamism": 79.53,
        "12th pillar: Innovation capability": 86.83
    },
    {
        "Country": "Ghana",
        "Alpha2": "GH",
        "Alpha3": "GHA",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 54.39,
        "2nd pillar: Infrastructure": 46.64,
        "3rd pillar: ICT adoption": 49.09,
        "4th pillar: Macroeconomic stability": 59.52,
        "5th pillar: Health": 53.25,
        "6th pillar: Skills": 52.17,
        "7th pillar: Product market": 53.21,
        "8th pillar: Labour market": 56.03,
        "9th pillar: Financial system": 48.82,
        "10th pillar: Market size": 54.24,
        "11th pillar: Business dynamism": 54.13,
        "12th pillar: Innovation capability": 32.86
    },
    {
        "Country": "Greece",
        "Alpha2": "GR",
        "Alpha3": "GRC",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 50.51,
        "2nd pillar: Infrastructure": 77.66,
        "3rd pillar: ICT adoption": 64.72,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 93.54,
        "6th pillar: Skills": 70.49,
        "7th pillar: Product market": 53.82,
        "8th pillar: Labour market": 52.73,
        "9th pillar: Financial system": 48.98,
        "10th pillar: Market size": 59.58,
        "11th pillar: Business dynamism": 58.78,
        "12th pillar: Innovation capability": 45.14
    },
    {
        "Country": "Guatemala",
        "Alpha2": "GT",
        "Alpha3": "GTM",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 42.45,
        "2nd pillar: Infrastructure": 55.85,
        "3rd pillar: ICT adoption": 37.73,
        "4th pillar: Macroeconomic stability": 74.81,
        "5th pillar: Health": 73.96,
        "6th pillar: Skills": 51.38,
        "7th pillar: Product market": 58.99,
        "8th pillar: Labour market": 50.93,
        "9th pillar: Financial system": 57.52,
        "10th pillar: Market size": 51.2,
        "11th pillar: Business dynamism": 55.81,
        "12th pillar: Innovation capability": 31.55
    },
    {
        "Country": "Guinea",
        "Alpha2": "GN",
        "Alpha3": "GIN",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 46.07,
        "2nd pillar: Infrastructure": 41.68,
        "3rd pillar: ICT adoption": 28.66,
        "4th pillar: Macroeconomic stability": 65.82,
        "5th pillar: Health": 39.9,
        "6th pillar: Skills": 36.95,
        "7th pillar: Product market": 54.63,
        "8th pillar: Labour market": 56.96,
        "9th pillar: Financial system": 53.5,
        "10th pillar: Market size": 36.35,
        "11th pillar: Business dynamism": 58.17,
        "12th pillar: Innovation capability": 34.93
    },
    {
        "Country": "Haiti",
        "Alpha2": "HT",
        "Alpha3": "HTI",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 30.87,
        "2nd pillar: Infrastructure": 26.88,
        "3rd pillar: ICT adoption": 28.14,
        "4th pillar: Macroeconomic stability": 60.16,
        "5th pillar: Health": 50.82,
        "6th pillar: Skills": 41.48,
        "7th pillar: Product market": 37.81,
        "8th pillar: Labour market": 49.07,
        "9th pillar: Financial system": 44.02,
        "10th pillar: Market size": 33.92,
        "11th pillar: Business dynamism": 14.07,
        "12th pillar: Innovation capability": 18.9
    },
    {
        "Country": "Honduras",
        "Alpha2": "HN",
        "Alpha3": "HND",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 43.79,
        "2nd pillar: Infrastructure": 57.4,
        "3rd pillar: ICT adoption": 30.17,
        "4th pillar: Macroeconomic stability": 74.67,
        "5th pillar: Health": 77.83,
        "6th pillar: Skills": 49.52,
        "7th pillar: Product market": 55.32,
        "8th pillar: Labour market": 55.95,
        "9th pillar: Financial system": 59.79,
        "10th pillar: Market size": 42.49,
        "11th pillar: Business dynamism": 53.96,
        "12th pillar: Innovation capability": 30.64
    },
    {
        "Country": "Hong Kong",
        "Alpha2": "HK",
        "Alpha3": "HKG",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 77.58,
        "2nd pillar: Infrastructure": 94.05,
        "3rd pillar: ICT adoption": 89.43,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 100,
        "6th pillar: Skills": 77.57,
        "7th pillar: Product market": 81.61,
        "8th pillar: Labour market": 75.82,
        "9th pillar: Financial system": 91.41,
        "10th pillar: Market size": 71.4,
        "11th pillar: Business dynamism": 75.4,
        "12th pillar: Innovation capability": 63.44
    },
    {
        "Country": "Hungary",
        "Alpha2": "HU",
        "Alpha3": "HUN",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 55.67,
        "2nd pillar: Infrastructure": 80.68,
        "3rd pillar: ICT adoption": 64.2,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 80.58,
        "6th pillar: Skills": 69.02,
        "7th pillar: Product market": 52.44,
        "8th pillar: Labour market": 58.64,
        "9th pillar: Financial system": 61.48,
        "10th pillar: Market size": 62.71,
        "11th pillar: Business dynamism": 58.08,
        "12th pillar: Innovation capability": 47.4
    },
    {
        "Country": "Iceland",
        "Alpha2": "IS",
        "Alpha3": "ISL",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 74.1,
        "2nd pillar: Infrastructure": 76.36,
        "3rd pillar: ICT adoption": 85.31,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 97.69,
        "6th pillar: Skills": 83.43,
        "7th pillar: Product market": 59.03,
        "8th pillar: Labour market": 74.92,
        "9th pillar: Financial system": 71.33,
        "10th pillar: Market size": 32.27,
        "11th pillar: Business dynamism": 77.06,
        "12th pillar: Innovation capability": 65.1
    },
    {
        "Country": "India",
        "Alpha2": "IN",
        "Alpha3": "IND",
        "Region": "South Asia",
        "1st pillar: Institutions": 56.75,
        "2nd pillar: Infrastructure": 68.09,
        "3rd pillar: ICT adoption": 32.11,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 60.53,
        "6th pillar: Skills": 50.45,
        "7th pillar: Product market": 50.39,
        "8th pillar: Labour market": 53.91,
        "9th pillar: Financial system": 69.48,
        "10th pillar: Market size": 93.67,
        "11th pillar: Business dynamism": 60.04,
        "12th pillar: Innovation capability": 50.94
    },
    {
        "Country": "Indonesia",
        "Alpha2": "ID",
        "Alpha3": "IDN",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 58.1,
        "2nd pillar: Infrastructure": 67.73,
        "3rd pillar: ICT adoption": 55.37,
        "4th pillar: Macroeconomic stability": 89.98,
        "5th pillar: Health": 70.79,
        "6th pillar: Skills": 64.01,
        "7th pillar: Product market": 58.23,
        "8th pillar: Labour market": 57.68,
        "9th pillar: Financial system": 63.97,
        "10th pillar: Market size": 82.39,
        "11th pillar: Business dynamism": 69.6,
        "12th pillar: Innovation capability": 37.7
    },
    {
        "Country": "Iran, Islamic Republic of",
        "Alpha2": "IR",
        "Alpha3": "IRN",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 42.53,
        "2nd pillar: Infrastructure": 64.76,
        "3rd pillar: ICT adoption": 50.85,
        "4th pillar: Macroeconomic stability": 52.22,
        "5th pillar: Health": 80.43,
        "6th pillar: Skills": 57.87,
        "7th pillar: Product market": 41.62,
        "8th pillar: Labour market": 41.33,
        "9th pillar: Financial system": 47.52,
        "10th pillar: Market size": 74.15,
        "11th pillar: Business dynamism": 44.33,
        "12th pillar: Innovation capability": 37.98
    },
    {
        "Country": "Ireland",
        "Alpha2": "IE",
        "Alpha3": "IRL",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 72.98,
        "2nd pillar: Infrastructure": 76.98,
        "3rd pillar: ICT adoption": 66.6,
        "4th pillar: Macroeconomic stability": 99.99,
        "5th pillar: Health": 94.85,
        "6th pillar: Skills": 77.21,
        "7th pillar: Product market": 60.87,
        "8th pillar: Labour market": 76.05,
        "9th pillar: Financial system": 68.77,
        "10th pillar: Market size": 64.63,
        "11th pillar: Business dynamism": 76.92,
        "12th pillar: Innovation capability": 65.55
    },
    {
        "Country": "Israel",
        "Alpha2": "IL",
        "Alpha3": "ISR",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 65.64,
        "2nd pillar: Infrastructure": 83.04,
        "3rd pillar: ICT adoption": 67.56,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 98.07,
        "6th pillar: Skills": 79.63,
        "7th pillar: Product market": 61.8,
        "8th pillar: Labour market": 71.11,
        "9th pillar: Financial system": 80.55,
        "10th pillar: Market size": 59.8,
        "11th pillar: Business dynamism": 79.55,
        "12th pillar: Innovation capability": 74.17
    },
    {
        "Country": "Italy",
        "Alpha2": "IT",
        "Alpha3": "ITA",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 58.55,
        "2nd pillar: Infrastructure": 84.09,
        "3rd pillar: ICT adoption": 64.49,
        "4th pillar: Macroeconomic stability": 84.66,
        "5th pillar: Health": 99.58,
        "6th pillar: Skills": 70.4,
        "7th pillar: Product market": 61.88,
        "8th pillar: Labour market": 56.57,
        "9th pillar: Financial system": 67.57,
        "10th pillar: Market size": 79.27,
        "11th pillar: Business dynamism": 65.73,
        "12th pillar: Innovation capability": 65.55
    },
    {
        "Country": "Jamaica",
        "Alpha2": "JM",
        "Alpha3": "JAM",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 49.33,
        "2nd pillar: Infrastructure": 62.51,
        "3rd pillar: ICT adoption": 47.61,
        "4th pillar: Macroeconomic stability": 69.92,
        "5th pillar: Health": 79.98,
        "6th pillar: Skills": 63.36,
        "7th pillar: Product market": 52.31,
        "8th pillar: Labour market": 67.47,
        "9th pillar: Financial system": 67.11,
        "10th pillar: Market size": 36.21,
        "11th pillar: Business dynamism": 68.32,
        "12th pillar: Innovation capability": 34.92
    },
    {
        "Country": "Japan",
        "Alpha2": "JP",
        "Alpha3": "JPN",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 71.67,
        "2nd pillar: Infrastructure": 93.16,
        "3rd pillar: ICT adoption": 86.2,
        "4th pillar: Macroeconomic stability": 94.89,
        "5th pillar: Health": 100,
        "6th pillar: Skills": 73.28,
        "7th pillar: Product market": 70.36,
        "8th pillar: Labour market": 71.54,
        "9th pillar: Financial system": 85.94,
        "10th pillar: Market size": 86.86,
        "11th pillar: Business dynamism": 75.03,
        "12th pillar: Innovation capability": 78.31
    },
    {
        "Country": "Jordan",
        "Alpha2": "JO",
        "Alpha3": "JOR",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 59.82,
        "2nd pillar: Infrastructure": 67.45,
        "3rd pillar: ICT adoption": 51.04,
        "4th pillar: Macroeconomic stability": 69.85,
        "5th pillar: Health": 86.65,
        "6th pillar: Skills": 67.15,
        "7th pillar: Product market": 55.83,
        "8th pillar: Labour market": 57.71,
        "9th pillar: Financial system": 71.62,
        "10th pillar: Market size": 48.81,
        "11th pillar: Business dynamism": 56.56,
        "12th pillar: Innovation capability": 38.78
    },
    {
        "Country": "Kazakhstan",
        "Alpha2": "KZ",
        "Alpha3": "KAZ",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 55.62,
        "2nd pillar: Infrastructure": 68.33,
        "3rd pillar: ICT adoption": 67.99,
        "4th pillar: Macroeconomic stability": 86.21,
        "5th pillar: Health": 70.98,
        "6th pillar: Skills": 67.49,
        "7th pillar: Product market": 55.7,
        "8th pillar: Labour market": 67.82,
        "9th pillar: Financial system": 53.07,
        "10th pillar: Market size": 63.45,
        "11th pillar: Business dynamism": 66.63,
        "12th pillar: Innovation capability": 32
    },
    {
        "Country": "Kenya",
        "Alpha2": "KE",
        "Alpha3": "KEN",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 54.65,
        "2nd pillar: Infrastructure": 53.6,
        "3rd pillar: ICT adoption": 35.68,
        "4th pillar: Macroeconomic stability": 71.75,
        "5th pillar: Health": 55.07,
        "6th pillar: Skills": 56.29,
        "7th pillar: Product market": 52.86,
        "8th pillar: Labour market": 58.86,
        "9th pillar: Financial system": 58.05,
        "10th pillar: Market size": 52.68,
        "11th pillar: Business dynamism": 63.93,
        "12th pillar: Innovation capability": 36.31
    },
    {
        "Country": "Korea, Republic of",
        "Alpha2": "KR",
        "Alpha3": "KOR",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 65.76,
        "2nd pillar: Infrastructure": 92.09,
        "3rd pillar: ICT adoption": 92.84,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 99,
        "6th pillar: Skills": 74,
        "7th pillar: Product market": 56.06,
        "8th pillar: Labour market": 62.89,
        "9th pillar: Financial system": 84.37,
        "10th pillar: Market size": 78.9,
        "11th pillar: Business dynamism": 70.47,
        "12th pillar: Innovation capability": 79.05
    },
    {
        "Country": "Kuwait",
        "Alpha2": "KW",
        "Alpha3": "KWT",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 55.58,
        "2nd pillar: Infrastructure": 68.37,
        "3rd pillar: ICT adoption": 69.57,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 96.08,
        "6th pillar: Skills": 61.33,
        "7th pillar: Product market": 57.86,
        "8th pillar: Labour market": 54.35,
        "9th pillar: Financial system": 71.61,
        "10th pillar: Market size": 60.11,
        "11th pillar: Business dynamism": 56.09,
        "12th pillar: Innovation capability": 30.3
    },
    {
        "Country": "Kyrgyzstan",
        "Alpha2": "KG",
        "Alpha3": "KGZ",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 49.02,
        "2nd pillar: Infrastructure": 55.82,
        "3rd pillar: ICT adoption": 58.82,
        "4th pillar: Macroeconomic stability": 74.98,
        "5th pillar: Health": 73.27,
        "6th pillar: Skills": 58.58,
        "7th pillar: Product market": 48.03,
        "8th pillar: Labour market": 58.35,
        "9th pillar: Financial system": 50.03,
        "10th pillar: Market size": 36.27,
        "11th pillar: Business dynamism": 58.59,
        "12th pillar: Innovation capability": 26.19
    },
    {
        "Country": "Lao People's Democratic Republic",
        "Alpha2": "LA",
        "Alpha3": "LAO",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 42.76,
        "2nd pillar: Infrastructure": 59.24,
        "3rd pillar: ICT adoption": 44.16,
        "4th pillar: Macroeconomic stability": 69.71,
        "5th pillar: Health": 60.87,
        "6th pillar: Skills": 51.32,
        "7th pillar: Product market": 54.15,
        "8th pillar: Labour market": 56.99,
        "9th pillar: Financial system": 55.2,
        "10th pillar: Market size": 42.07,
        "11th pillar: Business dynamism": 36.78,
        "12th pillar: Innovation capability": 27.99
    },
    {
        "Country": "Latvia",
        "Alpha2": "LV",
        "Alpha3": "LVA",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 59.28,
        "2nd pillar: Infrastructure": 76.01,
        "3rd pillar: ICT adoption": 79.71,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 76.82,
        "6th pillar: Skills": 76.25,
        "7th pillar: Product market": 58.61,
        "8th pillar: Labour market": 67.27,
        "9th pillar: Financial system": 57.1,
        "10th pillar: Market size": 44.4,
        "11th pillar: Business dynamism": 65.89,
        "12th pillar: Innovation capability": 42.42
    },
    {
        "Country": "Lebanon",
        "Alpha2": "LB",
        "Alpha3": "LBN",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 44.39,
        "2nd pillar: Infrastructure": 61.27,
        "3rd pillar: ICT adoption": 46.66,
        "4th pillar: Macroeconomic stability": 66.51,
        "5th pillar: Health": 82.05,
        "6th pillar: Skills": 64.23,
        "7th pillar: Product market": 51.21,
        "8th pillar: Labour market": 54.4,
        "9th pillar: Financial system": 64.71,
        "10th pillar: Market size": 48.56,
        "11th pillar: Business dynamism": 53,
        "12th pillar: Innovation capability": 38.48
    },
    {
        "Country": "Lesotho",
        "Alpha2": "LS",
        "Alpha3": "LSO",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 42.99,
        "2nd pillar: Infrastructure": 33.26,
        "3rd pillar: ICT adoption": 43.03,
        "4th pillar: Macroeconomic stability": 73.81,
        "5th pillar: Health": 21.73,
        "6th pillar: Skills": 48.32,
        "7th pillar: Product market": 50.33,
        "8th pillar: Labour market": 61.28,
        "9th pillar: Financial system": 43.38,
        "10th pillar: Market size": 24.82,
        "11th pillar: Business dynamism": 50.1,
        "12th pillar: Innovation capability": 21.81
    },
    {
        "Country": "Lithuania",
        "Alpha2": "LT",
        "Alpha3": "LTU",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 63.26,
        "2nd pillar: Infrastructure": 77.02,
        "3rd pillar: ICT adoption": 81.53,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 76.04,
        "6th pillar: Skills": 75.68,
        "7th pillar: Product market": 56.33,
        "8th pillar: Labour market": 68.84,
        "9th pillar: Financial system": 58.28,
        "10th pillar: Market size": 50.51,
        "11th pillar: Business dynamism": 65.65,
        "12th pillar: Innovation capability": 47.07
    },
    {
        "Country": "Luxembourg",
        "Alpha2": "LU",
        "Alpha3": "LUX",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 75.9,
        "2nd pillar: Infrastructure": 85.04,
        "3rd pillar: ICT adoption": 78.13,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 92.82,
        "6th pillar: Skills": 79.3,
        "7th pillar: Product market": 67.71,
        "8th pillar: Labour market": 74.21,
        "9th pillar: Financial system": 87.03,
        "10th pillar: Market size": 50.01,
        "11th pillar: Business dynamism": 65.79,
        "12th pillar: Innovation capability": 68.4
    },
    {
        "Country": "Macedonia, the former Yugoslav Republic of",
        "Alpha2": "MK",
        "Alpha3": "MKD",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 50.68,
        "2nd pillar: Infrastructure": 66.85,
        "3rd pillar: ICT adoption": 57.6,
        "4th pillar: Macroeconomic stability": 74.76,
        "5th pillar: Health": 81.29,
        "6th pillar: Skills": 59.84,
        "7th pillar: Product market": 48.6,
        "8th pillar: Labour market": 58.35,
        "9th pillar: Financial system": 57.33,
        "10th pillar: Market size": 39.69,
        "11th pillar: Business dynamism": 61.17,
        "12th pillar: Innovation capability": 31.75
    },
    {
        "Country": "Madagascar",
        "Alpha2": "MG",
        "Alpha3": "MDG",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 39.95,
        "2nd pillar: Infrastructure": 31.41,
        "3rd pillar: ICT adoption": 21.47,
        "4th pillar: Macroeconomic stability": 69.4,
        "5th pillar: Health": 48.28,
        "6th pillar: Skills": 38.53,
        "7th pillar: Product market": 47.91,
        "8th pillar: Labour market": 53.93,
        "9th pillar: Financial system": 46.73,
        "10th pillar: Market size": 40.11,
        "11th pillar: Business dynamism": 51.33,
        "12th pillar: Innovation capability": 25.28
    },
    {
        "Country": "Malawi",
        "Alpha2": "MW",
        "Alpha3": "MWI",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 45.74,
        "2nd pillar: Infrastructure": 35.56,
        "3rd pillar: ICT adoption": 25.23,
        "4th pillar: Macroeconomic stability": 66.14,
        "5th pillar: Health": 46.98,
        "6th pillar: Skills": 38.2,
        "7th pillar: Product market": 47.88,
        "8th pillar: Labour market": 60.08,
        "9th pillar: Financial system": 48.78,
        "10th pillar: Market size": 34.22,
        "11th pillar: Business dynamism": 48.77,
        "12th pillar: Innovation capability": 26.86
    },
    {
        "Country": "Malaysia",
        "Alpha2": "MY",
        "Alpha3": "MYS",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 68.57,
        "2nd pillar: Infrastructure": 78.03,
        "3rd pillar: ICT adoption": 71.6,
        "4th pillar: Macroeconomic stability": 99.98,
        "5th pillar: Health": 81.17,
        "6th pillar: Skills": 72.55,
        "7th pillar: Product market": 64.76,
        "8th pillar: Labour market": 70.17,
        "9th pillar: Financial system": 85.32,
        "10th pillar: Market size": 73.42,
        "11th pillar: Business dynamism": 74.63,
        "12th pillar: Innovation capability": 55.02
    },
    {
        "Country": "Mali",
        "Alpha2": "ML",
        "Alpha3": "MLI",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 41.48,
        "2nd pillar: Infrastructure": 43.87,
        "3rd pillar: ICT adoption": 27.88,
        "4th pillar: Macroeconomic stability": 74.92,
        "5th pillar: Health": 41.01,
        "6th pillar: Skills": 32.77,
        "7th pillar: Product market": 48.06,
        "8th pillar: Labour market": 46.08,
        "9th pillar: Financial system": 46.33,
        "10th pillar: Market size": 39.93,
        "11th pillar: Business dynamism": 51.78,
        "12th pillar: Innovation capability": 28.99
    },
    {
        "Country": "Malta",
        "Alpha2": "MT",
        "Alpha3": "MLT",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 61.34,
        "2nd pillar: Infrastructure": 75.02,
        "3rd pillar: ICT adoption": 75.46,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 93.17,
        "6th pillar: Skills": 72.16,
        "7th pillar: Product market": 59.56,
        "8th pillar: Labour market": 66.64,
        "9th pillar: Financial system": 72.15,
        "10th pillar: Market size": 37.16,
        "11th pillar: Business dynamism": 59.39,
        "12th pillar: Innovation capability": 50.5
    },
    {
        "Country": "Mauritania",
        "Alpha2": "MR",
        "Alpha3": "MRT",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 36.42,
        "2nd pillar: Infrastructure": 32.38,
        "3rd pillar: ICT adoption": 36.44,
        "4th pillar: Macroeconomic stability": 70,
        "5th pillar: Health": 65.32,
        "6th pillar: Skills": 34.76,
        "7th pillar: Product market": 36.57,
        "8th pillar: Labour market": 43.74,
        "9th pillar: Financial system": 37.35,
        "10th pillar: Market size": 32.91,
        "11th pillar: Business dynamism": 38.86,
        "12th pillar: Innovation capability": 26.29
    },
    {
        "Country": "Mauritius",
        "Alpha2": "MU",
        "Alpha3": "MUS",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 64.7,
        "2nd pillar: Infrastructure": 68.7,
        "3rd pillar: ICT adoption": 68.27,
        "4th pillar: Macroeconomic stability": 89.43,
        "5th pillar: Health": 77.43,
        "6th pillar: Skills": 60.64,
        "7th pillar: Product market": 64.46,
        "8th pillar: Labour market": 59.03,
        "9th pillar: Financial system": 77.19,
        "10th pillar: Market size": 37.22,
        "11th pillar: Business dynamism": 66.13,
        "12th pillar: Innovation capability": 38.06
    },
    {
        "Country": "Mexico",
        "Alpha2": "MX",
        "Alpha3": "MEX",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 48.29,
        "2nd pillar: Infrastructure": 72.45,
        "3rd pillar: ICT adoption": 55.03,
        "4th pillar: Macroeconomic stability": 97.82,
        "5th pillar: Health": 81.96,
        "6th pillar: Skills": 58.25,
        "7th pillar: Product market": 57.69,
        "8th pillar: Labour market": 55.83,
        "9th pillar: Financial system": 61.78,
        "10th pillar: Market size": 80.84,
        "11th pillar: Business dynamism": 65.82,
        "12th pillar: Innovation capability": 43.58
    },
    {
        "Country": "Moldova, Republic of",
        "Alpha2": "MD",
        "Alpha3": "MDA",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 51.37,
        "2nd pillar: Infrastructure": 66.19,
        "3rd pillar: ICT adoption": 66.79,
        "4th pillar: Macroeconomic stability": 73.44,
        "5th pillar: Health": 71.88,
        "6th pillar: Skills": 61.47,
        "7th pillar: Product market": 54.99,
        "8th pillar: Labour market": 61.92,
        "9th pillar: Financial system": 46.84,
        "10th pillar: Market size": 36.05,
        "11th pillar: Business dynamism": 60.14,
        "12th pillar: Innovation capability": 29.89
    },
    {
        "Country": "Mongolia",
        "Alpha2": "MN",
        "Alpha3": "MNG",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 49.76,
        "2nd pillar: Infrastructure": 56.56,
        "3rd pillar: ICT adoption": 46.55,
        "4th pillar: Macroeconomic stability": 66.7,
        "5th pillar: Health": 63.3,
        "6th pillar: Skills": 56.55,
        "7th pillar: Product market": 50.03,
        "8th pillar: Labour market": 64.02,
        "9th pillar: Financial system": 50.52,
        "10th pillar: Market size": 41.78,
        "11th pillar: Business dynamism": 53.29,
        "12th pillar: Innovation capability": 32.33
    },
    {
        "Country": "Montenegro",
        "Alpha2": "ME",
        "Alpha3": "MNE",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 57.27,
        "2nd pillar: Infrastructure": 63.58,
        "3rd pillar: ICT adoption": 62.95,
        "4th pillar: Macroeconomic stability": 70,
        "5th pillar: Health": 81.2,
        "6th pillar: Skills": 68.68,
        "7th pillar: Product market": 59.37,
        "8th pillar: Labour market": 67.69,
        "9th pillar: Financial system": 67.98,
        "10th pillar: Market size": 28.83,
        "11th pillar: Business dynamism": 64.01,
        "12th pillar: Innovation capability": 38.3
    },
    {
        "Country": "Morocco",
        "Alpha2": "MA",
        "Alpha3": "MAR",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 60.02,
        "2nd pillar: Infrastructure": 72.63,
        "3rd pillar: ICT adoption": 46.2,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 72.33,
        "6th pillar: Skills": 48.62,
        "7th pillar: Product market": 55.99,
        "8th pillar: Labour market": 51.48,
        "9th pillar: Financial system": 67.46,
        "10th pillar: Market size": 60.46,
        "11th pillar: Business dynamism": 59.8,
        "12th pillar: Innovation capability": 35.12
    },
    {
        "Country": "Mozambique",
        "Alpha2": "MZ",
        "Alpha3": "MOZ",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 39.31,
        "2nd pillar: Infrastructure": 35.15,
        "3rd pillar: ICT adoption": 23.07,
        "4th pillar: Macroeconomic stability": 42.34,
        "5th pillar: Health": 33.14,
        "6th pillar: Skills": 30.25,
        "7th pillar: Product market": 46.73,
        "8th pillar: Labour market": 43.15,
        "9th pillar: Financial system": 48.44,
        "10th pillar: Market size": 41.14,
        "11th pillar: Business dynamism": 46.77,
        "12th pillar: Innovation capability": 27.43
    },
    {
        "Country": "Namibia",
        "Alpha2": "NA",
        "Alpha3": "NAM",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 56.82,
        "2nd pillar: Infrastructure": 58.5,
        "3rd pillar: ICT adoption": 48.14,
        "4th pillar: Macroeconomic stability": 72.17,
        "5th pillar: Health": 53.41,
        "6th pillar: Skills": 54.57,
        "7th pillar: Product market": 53.55,
        "8th pillar: Labour market": 63.72,
        "9th pillar: Financial system": 69.09,
        "10th pillar: Market size": 36.73,
        "11th pillar: Business dynamism": 51.21,
        "12th pillar: Innovation capability": 35.64
    },
    {
        "Country": "Nepal",
        "Alpha2": "NP",
        "Alpha3": "NPL",
        "Region": "South Asia",
        "1st pillar: Institutions": 47.89,
        "2nd pillar: Infrastructure": 51.79,
        "3rd pillar: ICT adoption": 38.6,
        "4th pillar: Macroeconomic stability": 73.92,
        "5th pillar: Health": 65.94,
        "6th pillar: Skills": 49.33,
        "7th pillar: Product market": 43.01,
        "8th pillar: Labour market": 49.14,
        "9th pillar: Financial system": 66.37,
        "10th pillar: Market size": 47.73,
        "11th pillar: Business dynamism": 55.76,
        "12th pillar: Innovation capability": 29.4
    },
    {
        "Country": "Netherlands",
        "Alpha2": "NL",
        "Alpha3": "NLD",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 78.57,
        "2nd pillar: Infrastructure": 94.34,
        "3rd pillar: ICT adoption": 76.29,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 94.25,
        "6th pillar: Skills": 84.62,
        "7th pillar: Product market": 69.93,
        "8th pillar: Labour market": 74.9,
        "9th pillar: Financial system": 84.61,
        "10th pillar: Market size": 74.31,
        "11th pillar: Business dynamism": 80.58,
        "12th pillar: Innovation capability": 76.31
    },
    {
        "Country": "New Zealand",
        "Alpha2": "NZ",
        "Alpha3": "NZL",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 78.78,
        "2nd pillar: Infrastructure": 75.53,
        "3rd pillar: ICT adoption": 77.71,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 90.78,
        "6th pillar: Skills": 82.11,
        "7th pillar: Product market": 71.96,
        "8th pillar: Labour market": 76.74,
        "9th pillar: Financial system": 76.74,
        "10th pillar: Market size": 54.18,
        "11th pillar: Business dynamism": 75.78,
        "12th pillar: Innovation capability": 60.65
    },
    {
        "Country": "Nicaragua",
        "Alpha2": "NI",
        "Alpha3": "NIC",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 41.89,
        "2nd pillar: Infrastructure": 55.6,
        "3rd pillar: ICT adoption": 35.88,
        "4th pillar: Macroeconomic stability": 73.51,
        "5th pillar: Health": 90.01,
        "6th pillar: Skills": 46.8,
        "7th pillar: Product market": 51.39,
        "8th pillar: Labour market": 53.24,
        "9th pillar: Financial system": 53.13,
        "10th pillar: Market size": 39.15,
        "11th pillar: Business dynamism": 49.83,
        "12th pillar: Innovation capability": 27.85
    },
    {
        "Country": "Nigeria",
        "Alpha2": "NG",
        "Alpha3": "NGA",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 41.43,
        "2nd pillar: Infrastructure": 39.71,
        "3rd pillar: ICT adoption": 33.39,
        "4th pillar: Macroeconomic stability": 60.29,
        "5th pillar: Health": 47.2,
        "6th pillar: Skills": 40.1,
        "7th pillar: Product market": 51.58,
        "8th pillar: Labour market": 60.23,
        "9th pillar: Financial system": 44.16,
        "10th pillar: Market size": 71.13,
        "11th pillar: Business dynamism": 58.55,
        "12th pillar: Innovation capability": 32.17
    },
    {
        "Country": "Norway",
        "Alpha2": "NO",
        "Alpha3": "NOR",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 76.92,
        "2nd pillar: Infrastructure": 75.8,
        "3rd pillar: ICT adoption": 83.12,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 94.49,
        "6th pillar: Skills": 83.77,
        "7th pillar: Product market": 60.87,
        "8th pillar: Labour market": 73.32,
        "9th pillar: Financial system": 82.04,
        "10th pillar: Market size": 61.41,
        "11th pillar: Business dynamism": 76.9,
        "12th pillar: Innovation capability": 68.02
    },
    {
        "Country": "Oman",
        "Alpha2": "OM",
        "Alpha3": "OMN",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 62.35,
        "2nd pillar: Infrastructure": 80.5,
        "3rd pillar: ICT adoption": 58.11,
        "4th pillar: Macroeconomic stability": 67.41,
        "5th pillar: Health": 80.68,
        "6th pillar: Skills": 71.54,
        "7th pillar: Product market": 63.12,
        "8th pillar: Labour market": 55.75,
        "9th pillar: Financial system": 63.9,
        "10th pillar: Market size": 55.86,
        "11th pillar: Business dynamism": 62.82,
        "12th pillar: Innovation capability": 41.24
    },
    {
        "Country": "Pakistan",
        "Alpha2": "PK",
        "Alpha3": "PAK",
        "Region": "South Asia",
        "1st pillar: Institutions": 47.7,
        "2nd pillar: Infrastructure": 55.55,
        "3rd pillar: ICT adoption": 25.21,
        "4th pillar: Macroeconomic stability": 68.73,
        "5th pillar: Health": 56.25,
        "6th pillar: Skills": 40.76,
        "7th pillar: Product market": 45.49,
        "8th pillar: Labour market": 51.27,
        "9th pillar: Financial system": 55.03,
        "10th pillar: Market size": 71.24,
        "11th pillar: Business dynamism": 63.3,
        "12th pillar: Innovation capability": 35.77
    },
    {
        "Country": "Panama",
        "Alpha2": "PA",
        "Alpha3": "PAN",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 51.41,
        "2nd pillar: Infrastructure": 69.47,
        "3rd pillar: ICT adoption": 50.06,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 92.01,
        "6th pillar: Skills": 58.46,
        "7th pillar: Product market": 59.23,
        "8th pillar: Labour market": 56.28,
        "9th pillar: Financial system": 67.63,
        "10th pillar: Market size": 49.04,
        "11th pillar: Business dynamism": 58.75,
        "12th pillar: Innovation capability": 37.31
    },
    {
        "Country": "Paraguay",
        "Alpha2": "PY",
        "Alpha3": "PRY",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 44.28,
        "2nd pillar: Infrastructure": 59.83,
        "3rd pillar: ICT adoption": 45.68,
        "4th pillar: Macroeconomic stability": 74.81,
        "5th pillar: Health": 81.4,
        "6th pillar: Skills": 50.8,
        "7th pillar: Product market": 54.61,
        "8th pillar: Labour market": 55.2,
        "9th pillar: Financial system": 56.05,
        "10th pillar: Market size": 47.32,
        "11th pillar: Business dynamism": 51.24,
        "12th pillar: Innovation capability": 22.41
    },
    {
        "Country": "Peru",
        "Alpha2": "PE",
        "Alpha3": "PER",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 48.86,
        "2nd pillar: Infrastructure": 62.31,
        "3rd pillar: ICT adoption": 45.7,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 94.63,
        "6th pillar: Skills": 60.2,
        "7th pillar: Product market": 57.09,
        "8th pillar: Labour market": 59.02,
        "9th pillar: Financial system": 61.44,
        "10th pillar: Market size": 62.19,
        "11th pillar: Business dynamism": 55.8,
        "12th pillar: Innovation capability": 32.73
    },
    {
        "Country": "Philippines",
        "Alpha2": "PH",
        "Alpha3": "PHL",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 49.98,
        "2nd pillar: Infrastructure": 57.83,
        "3rd pillar: ICT adoption": 49.69,
        "4th pillar: Macroeconomic stability": 89.95,
        "5th pillar: Health": 65.58,
        "6th pillar: Skills": 63.74,
        "7th pillar: Product market": 57.75,
        "8th pillar: Labour market": 64.94,
        "9th pillar: Financial system": 68.31,
        "10th pillar: Market size": 70.97,
        "11th pillar: Business dynamism": 65.73,
        "12th pillar: Innovation capability": 37.96
    },
    {
        "Country": "Poland",
        "Alpha2": "PL",
        "Alpha3": "POL",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 56.43,
        "2nd pillar: Infrastructure": 81.15,
        "3rd pillar: ICT adoption": 65.41,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 83.8,
        "6th pillar: Skills": 72.11,
        "7th pillar: Product market": 58.12,
        "8th pillar: Labour market": 59.9,
        "9th pillar: Financial system": 64.06,
        "10th pillar: Market size": 74.07,
        "11th pillar: Business dynamism": 62.02,
        "12th pillar: Innovation capability": 49.65
    },
    {
        "Country": "Portugal",
        "Alpha2": "PT",
        "Alpha3": "PRT",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 64.51,
        "2nd pillar: Infrastructure": 83.6,
        "3rd pillar: ICT adoption": 71.24,
        "4th pillar: Macroeconomic stability": 85,
        "5th pillar: Health": 94.16,
        "6th pillar: Skills": 69.99,
        "7th pillar: Product market": 59.75,
        "8th pillar: Labour market": 63.17,
        "9th pillar: Financial system": 70.04,
        "10th pillar: Market size": 60.49,
        "11th pillar: Business dynamism": 69.71,
        "12th pillar: Innovation capability": 53.69
    },
    {
        "Country": "Qatar",
        "Alpha2": "QA",
        "Alpha3": "QAT",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 63.22,
        "2nd pillar: Infrastructure": 81.64,
        "3rd pillar: ICT adoption": 83.78,
        "4th pillar: Macroeconomic stability": 98.64,
        "5th pillar: Health": 88.6,
        "6th pillar: Skills": 70.54,
        "7th pillar: Product market": 66.85,
        "8th pillar: Labour market": 63.38,
        "9th pillar: Financial system": 81.34,
        "10th pillar: Market size": 60.45,
        "11th pillar: Business dynamism": 65.96,
        "12th pillar: Innovation capability": 50.03
    },
    {
        "Country": "Romania",
        "Alpha2": "RO",
        "Alpha3": "ROU",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 58.07,
        "2nd pillar: Infrastructure": 71.68,
        "3rd pillar: ICT adoption": 72.01,
        "4th pillar: Macroeconomic stability": 89.65,
        "5th pillar: Health": 77.21,
        "6th pillar: Skills": 62.49,
        "7th pillar: Product market": 55.39,
        "8th pillar: Labour market": 61.58,
        "9th pillar: Financial system": 56.98,
        "10th pillar: Market size": 65.19,
        "11th pillar: Business dynamism": 59.67,
        "12th pillar: Innovation capability": 42.33
    },
    {
        "Country": "Russian Federation",
        "Alpha2": "RU",
        "Alpha3": "RUS",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 52.57,
        "2nd pillar: Infrastructure": 73.84,
        "3rd pillar: ICT adoption": 77.03,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 69.25,
        "6th pillar: Skills": 68.3,
        "7th pillar: Product market": 52.93,
        "8th pillar: Labour market": 61.01,
        "9th pillar: Financial system": 55.66,
        "10th pillar: Market size": 84.19,
        "11th pillar: Business dynamism": 63.11,
        "12th pillar: Innovation capability": 52.95
    },
    {
        "Country": "Rwanda",
        "Alpha2": "RW",
        "Alpha3": "RWA",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 63.21,
        "2nd pillar: Infrastructure": 52,
        "3rd pillar: ICT adoption": 37.63,
        "4th pillar: Macroeconomic stability": 72.67,
        "5th pillar: Health": 61.39,
        "6th pillar: Skills": 40.13,
        "7th pillar: Product market": 55.31,
        "8th pillar: Labour market": 63.58,
        "9th pillar: Financial system": 56.34,
        "10th pillar: Market size": 35.08,
        "11th pillar: Business dynamism": 65.58,
        "12th pillar: Innovation capability": 30.93
    },
    {
        "Country": "Saudi Arabia",
        "Alpha2": "SA",
        "Alpha3": "SAU",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 63.19,
        "2nd pillar: Infrastructure": 78.06,
        "3rd pillar: ICT adoption": 69.3,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 82.25,
        "6th pillar: Skills": 75.35,
        "7th pillar: Product market": 64.92,
        "8th pillar: Labour market": 56.62,
        "9th pillar: Financial system": 70.68,
        "10th pillar: Market size": 76.27,
        "11th pillar: Business dynamism": 53.12,
        "12th pillar: Innovation capability": 50.55
    },
    {
        "Country": "Senegal",
        "Alpha2": "SN",
        "Alpha3": "SEN",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 53.07,
        "2nd pillar: Infrastructure": 51.35,
        "3rd pillar: ICT adoption": 35.76,
        "4th pillar: Macroeconomic stability": 70,
        "5th pillar: Health": 58.9,
        "6th pillar: Skills": 41.14,
        "7th pillar: Product market": 52.29,
        "8th pillar: Labour market": 52.72,
        "9th pillar: Financial system": 50.43,
        "10th pillar: Market size": 43,
        "11th pillar: Business dynamism": 55.6,
        "12th pillar: Innovation capability": 31.95
    },
    {
        "Country": "Serbia",
        "Alpha2": "RS",
        "Alpha3": "SRB",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 52.45,
        "2nd pillar: Infrastructure": 73.84,
        "3rd pillar: ICT adoption": 52.59,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 78.99,
        "6th pillar: Skills": 68.18,
        "7th pillar: Product market": 54.58,
        "8th pillar: Labour market": 62.13,
        "9th pillar: Financial system": 57.4,
        "10th pillar: Market size": 51.77,
        "11th pillar: Business dynamism": 63.11,
        "12th pillar: Innovation capability": 40.18
    },
    {
        "Country": "Seychelles",
        "Alpha2": "SC",
        "Alpha3": "SYC",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 61.02,
        "2nd pillar: Infrastructure": 62.33,
        "3rd pillar: ICT adoption": 59.41,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 77.99,
        "6th pillar: Skills": 71.71,
        "7th pillar: Product market": 60.01,
        "8th pillar: Labour market": 65.76,
        "9th pillar: Financial system": 57.21,
        "10th pillar: Market size": 17.12,
        "11th pillar: Business dynamism": 61.49,
        "12th pillar: Innovation capability": 46.15
    },
    {
        "Country": "Singapore",
        "Alpha2": "SG",
        "Alpha3": "SGP",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 80.36,
        "2nd pillar: Infrastructure": 95.45,
        "3rd pillar: ICT adoption": 87.11,
        "4th pillar: Macroeconomic stability": 99.74,
        "5th pillar: Health": 100,
        "6th pillar: Skills": 78.78,
        "7th pillar: Product market": 81.21,
        "8th pillar: Labour market": 81.23,
        "9th pillar: Financial system": 91.27,
        "10th pillar: Market size": 71.54,
        "11th pillar: Business dynamism": 75.56,
        "12th pillar: Innovation capability": 75.15
    },
    {
        "Country": "Slovakia",
        "Alpha2": "SK",
        "Alpha3": "SVK",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 56.28,
        "2nd pillar: Infrastructure": 78.55,
        "3rd pillar: ICT adoption": 69.19,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 82.28,
        "6th pillar: Skills": 69.88,
        "7th pillar: Product market": 52.7,
        "8th pillar: Labour market": 60.66,
        "9th pillar: Financial system": 64.42,
        "10th pillar: Market size": 58.2,
        "11th pillar: Business dynamism": 62.84,
        "12th pillar: Innovation capability": 46.27
    },
    {
        "Country": "Slovenia",
        "Alpha2": "SI",
        "Alpha3": "SVN",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 63.35,
        "2nd pillar: Infrastructure": 78.08,
        "3rd pillar: ICT adoption": 69.17,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 89.81,
        "6th pillar: Skills": 74.93,
        "7th pillar: Product market": 61.9,
        "8th pillar: Labour market": 64.5,
        "9th pillar: Financial system": 63.82,
        "10th pillar: Market size": 48.45,
        "11th pillar: Business dynamism": 70.15,
        "12th pillar: Innovation capability": 58.25
    },
    {
        "Country": "South Africa",
        "Alpha2": "ZA",
        "Alpha3": "ZAF",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 57.07,
        "2nd pillar: Infrastructure": 68.13,
        "3rd pillar: ICT adoption": 49.67,
        "4th pillar: Macroeconomic stability": 88.41,
        "5th pillar: Health": 53.29,
        "6th pillar: Skills": 58.06,
        "7th pillar: Product market": 54.81,
        "8th pillar: Labour market": 60.94,
        "9th pillar: Financial system": 83.25,
        "10th pillar: Market size": 68.56,
        "11th pillar: Business dynamism": 61.86,
        "12th pillar: Innovation capability": 45.17
    },
    {
        "Country": "Spain",
        "Alpha2": "ES",
        "Alpha3": "ESP",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 65.07,
        "2nd pillar: Infrastructure": 90.31,
        "3rd pillar: ICT adoption": 78.21,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 100,
        "6th pillar: Skills": 71.56,
        "7th pillar: Product market": 61,
        "8th pillar: Labour market": 61.09,
        "9th pillar: Financial system": 77.5,
        "10th pillar: Market size": 76.99,
        "11th pillar: Business dynamism": 67.31,
        "12th pillar: Innovation capability": 64.32
    },
    {
        "Country": "Sri Lanka",
        "Alpha2": "LK",
        "Alpha3": "LKA",
        "Region": "South Asia",
        "1st pillar: Institutions": 51.61,
        "2nd pillar: Infrastructure": 69.23,
        "3rd pillar: ICT adoption": 40.27,
        "4th pillar: Macroeconomic stability": 68.02,
        "5th pillar: Health": 87.09,
        "6th pillar: Skills": 63.75,
        "7th pillar: Product market": 43.25,
        "8th pillar: Labour market": 51.75,
        "9th pillar: Financial system": 56.95,
        "10th pillar: Market size": 58.41,
        "11th pillar: Business dynamism": 60.03,
        "12th pillar: Innovation capability": 34.9
    },
    {
        "Country": "Swaziland",
        "Alpha2": "SZ",
        "Alpha3": "SWZ",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 47.8,
        "2nd pillar: Infrastructure": 53.75,
        "3rd pillar: ICT adoption": 33.27,
        "4th pillar: Macroeconomic stability": 71.32,
        "5th pillar: Health": 35.4,
        "6th pillar: Skills": 52.63,
        "7th pillar: Product market": 50.1,
        "8th pillar: Labour market": 56.39,
        "9th pillar: Financial system": 53.81,
        "10th pillar: Market size": 27.51,
        "11th pillar: Business dynamism": 50.74,
        "12th pillar: Innovation capability": 24.47
    },
    {
        "Country": "Sweden",
        "Alpha2": "SE",
        "Alpha3": "SWE",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 75.2,
        "2nd pillar: Infrastructure": 84.01,
        "3rd pillar: ICT adoption": 87.78,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 96.64,
        "6th pillar: Skills": 83.73,
        "7th pillar: Product market": 66.29,
        "8th pillar: Labour market": 69.37,
        "9th pillar: Financial system": 88.03,
        "10th pillar: Market size": 65.37,
        "11th pillar: Business dynamism": 79.42,
        "12th pillar: Innovation capability": 79.1
    },
    {
        "Country": "Switzerland",
        "Alpha2": "CH",
        "Alpha3": "CHE",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 77.51,
        "2nd pillar: Infrastructure": 93.16,
        "3rd pillar: ICT adoption": 78.58,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 99.94,
        "6th pillar: Skills": 86.72,
        "7th pillar: Product market": 63.8,
        "8th pillar: Labour market": 79.48,
        "9th pillar: Financial system": 89.72,
        "10th pillar: Market size": 66.23,
        "11th pillar: Business dynamism": 71.55,
        "12th pillar: Innovation capability": 81.2
    },
    {
        "Country": "Taiwan, Province of China",
        "Alpha2": "TW",
        "Alpha3": "TWN",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 68.63,
        "2nd pillar: Infrastructure": 86.69,
        "3rd pillar: ICT adoption": 82.29,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 93.54,
        "6th pillar: Skills": 76.22,
        "7th pillar: Product market": 66.34,
        "8th pillar: Labour market": 72.74,
        "9th pillar: Financial system": 88.44,
        "10th pillar: Market size": 74.72,
        "11th pillar: Business dynamism": 73.06,
        "12th pillar: Innovation capability": 80.23
    },
    {
        "Country": "Tajikistan",
        "Alpha2": "TJ",
        "Alpha3": "TJK",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 51.68,
        "2nd pillar: Infrastructure": 60.61,
        "3rd pillar: ICT adoption": 31.77,
        "4th pillar: Macroeconomic stability": 72.41,
        "5th pillar: Health": 66.61,
        "6th pillar: Skills": 63.16,
        "7th pillar: Product market": 54.64,
        "8th pillar: Labour market": 59.82,
        "9th pillar: Financial system": 48.82,
        "10th pillar: Market size": 36.44,
        "11th pillar: Business dynamism": 54.81,
        "12th pillar: Innovation capability": 27.96
    },
    {
        "Country": "Tanzania, United Republic of",
        "Alpha2": "TZ",
        "Alpha3": "TZA",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 48.33,
        "2nd pillar: Infrastructure": 44.92,
        "3rd pillar: ICT adoption": 23.74,
        "4th pillar: Macroeconomic stability": 74.28,
        "5th pillar: Health": 56.97,
        "6th pillar: Skills": 40.72,
        "7th pillar: Product market": 49.7,
        "8th pillar: Labour market": 57.04,
        "9th pillar: Financial system": 49.34,
        "10th pillar: Market size": 52.22,
        "11th pillar: Business dynamism": 53.34,
        "12th pillar: Innovation capability": 27.69
    },
    {
        "Country": "Thailand",
        "Alpha2": "TH",
        "Alpha3": "THA",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 54.83,
        "2nd pillar: Infrastructure": 67.84,
        "3rd pillar: ICT adoption": 60.15,
        "4th pillar: Macroeconomic stability": 90,
        "5th pillar: Health": 88.94,
        "6th pillar: Skills": 62.32,
        "7th pillar: Product market": 53.46,
        "8th pillar: Labour market": 63.38,
        "9th pillar: Financial system": 85.07,
        "10th pillar: Market size": 75.54,
        "11th pillar: Business dynamism": 71.96,
        "12th pillar: Innovation capability": 43.88
    },
    {
        "Country": "Trinidad and Tobago",
        "Alpha2": "TT",
        "Alpha3": "TTO",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 47.93,
        "2nd pillar: Infrastructure": 58.01,
        "3rd pillar: ICT adoption": 60.44,
        "4th pillar: Macroeconomic stability": 88.89,
        "5th pillar: Health": 78.08,
        "6th pillar: Skills": 61.43,
        "7th pillar: Product market": 46.38,
        "8th pillar: Labour market": 59.04,
        "9th pillar: Financial system": 67.9,
        "10th pillar: Market size": 40.54,
        "11th pillar: Business dynamism": 56.66,
        "12th pillar: Innovation capability": 34.36
    },
    {
        "Country": "Tunisia",
        "Alpha2": "TN",
        "Alpha3": "TUN",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 52.97,
        "2nd pillar: Infrastructure": 62.74,
        "3rd pillar: ICT adoption": 50.92,
        "4th pillar: Macroeconomic stability": 65.65,
        "5th pillar: Health": 85.34,
        "6th pillar: Skills": 59.6,
        "7th pillar: Product market": 52.44,
        "8th pillar: Labour market": 46.43,
        "9th pillar: Financial system": 55.72,
        "10th pillar: Market size": 53.5,
        "11th pillar: Business dynamism": 58.97,
        "12th pillar: Innovation capability": 32.64
    },
    {
        "Country": "Turkey",
        "Alpha2": "TR",
        "Alpha3": "TUR",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 53.89,
        "2nd pillar: Infrastructure": 74.27,
        "3rd pillar: ICT adoption": 57.82,
        "4th pillar: Macroeconomic stability": 61.26,
        "5th pillar: Health": 87.12,
        "6th pillar: Skills": 60.81,
        "7th pillar: Product market": 54.11,
        "8th pillar: Labour market": 52.88,
        "9th pillar: Financial system": 61.19,
        "10th pillar: Market size": 78.97,
        "11th pillar: Business dynamism": 58.81,
        "12th pillar: Innovation capability": 44.51
    },
    {
        "Country": "Uganda",
        "Alpha2": "UG",
        "Alpha3": "UGA",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 48.03,
        "2nd pillar: Infrastructure": 47.88,
        "3rd pillar: ICT adoption": 29.35,
        "4th pillar: Macroeconomic stability": 74.16,
        "5th pillar: Health": 53.01,
        "6th pillar: Skills": 42.26,
        "7th pillar: Product market": 49.06,
        "8th pillar: Labour market": 59.96,
        "9th pillar: Financial system": 50.3,
        "10th pillar: Market size": 47.37,
        "11th pillar: Business dynamism": 56.35,
        "12th pillar: Innovation capability": 29.54
    },
    {
        "Country": "Ukraine",
        "Alpha2": "UA",
        "Alpha3": "UKR",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 47.85,
        "2nd pillar: Infrastructure": 70.34,
        "3rd pillar: ICT adoption": 51.85,
        "4th pillar: Macroeconomic stability": 57.92,
        "5th pillar: Health": 65.59,
        "6th pillar: Skills": 69.92,
        "7th pillar: Product market": 56.52,
        "8th pillar: Labour market": 61.38,
        "9th pillar: Financial system": 42.28,
        "10th pillar: Market size": 62.99,
        "11th pillar: Business dynamism": 57.16,
        "12th pillar: Innovation capability": 40.11
    },
    {
        "Country": "United Arab Emirates",
        "Alpha2": "AE",
        "Alpha3": "ARE",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 73.26,
        "2nd pillar: Infrastructure": 88.49,
        "3rd pillar: ICT adoption": 91.87,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 72.18,
        "6th pillar: Skills": 70.61,
        "7th pillar: Product market": 71.74,
        "8th pillar: Labour market": 66.16,
        "9th pillar: Financial system": 73.84,
        "10th pillar: Market size": 70.34,
        "11th pillar: Business dynamism": 69.27,
        "12th pillar: Innovation capability": 52.34
    },
    {
        "Country": "United Kingdom",
        "Alpha2": "GB",
        "Alpha3": "GBR",
        "Region": "Europe and Central Asia",
        "1st pillar: Institutions": 74.42,
        "2nd pillar: Infrastructure": 88.89,
        "3rd pillar: ICT adoption": 72.99,
        "4th pillar: Macroeconomic stability": 100,
        "5th pillar: Health": 91.57,
        "6th pillar: Skills": 81.91,
        "7th pillar: Product market": 64.61,
        "8th pillar: Labour market": 74.98,
        "9th pillar: Financial system": 88.14,
        "10th pillar: Market size": 81.78,
        "11th pillar: Business dynamism": 77,
        "12th pillar: Innovation capability": 78.15
    },
    {
        "Country": "United States",
        "Alpha2": "US",
        "Alpha3": "USA",
        "Region": "North America",
        "1st pillar: Institutions": 71.17,
        "2nd pillar: Infrastructure": 87.9,
        "3rd pillar: ICT adoption": 74.35,
        "4th pillar: Macroeconomic stability": 99.77,
        "5th pillar: Health": 83.02,
        "6th pillar: Skills": 82.47,
        "7th pillar: Product market": 68.55,
        "8th pillar: Labour market": 77.98,
        "9th pillar: Financial system": 90.99,
        "10th pillar: Market size": 99.53,
        "11th pillar: Business dynamism": 84.21,
        "12th pillar: Innovation capability": 84.15
    },
    {
        "Country": "Uruguay",
        "Alpha2": "UY",
        "Alpha3": "URY",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 62.33,
        "2nd pillar: Infrastructure": 68.67,
        "3rd pillar: ICT adoption": 79.73,
        "4th pillar: Macroeconomic stability": 85.62,
        "5th pillar: Health": 85.01,
        "6th pillar: Skills": 66.83,
        "7th pillar: Product market": 55.16,
        "8th pillar: Labour market": 58.95,
        "9th pillar: Financial system": 58.12,
        "10th pillar: Market size": 44.74,
        "11th pillar: Business dynamism": 58.12,
        "12th pillar: Innovation capability": 38.34
    },
    {
        "Country": "Venezuela, Bolivarian Republic of",
        "Alpha2": "VE",
        "Alpha3": "VEN",
        "Region": "Latin America and the Caribbean",
        "1st pillar: Institutions": 25.72,
        "2nd pillar: Infrastructure": 46.18,
        "3rd pillar: ICT adoption": 46.74,
        "4th pillar: Macroeconomic stability": 0,
        "5th pillar: Health": 81.91,
        "6th pillar: Skills": 63.7,
        "7th pillar: Product market": 36.42,
        "8th pillar: Labour market": 46.14,
        "9th pillar: Financial system": 38.73,
        "10th pillar: Market size": 56.93,
        "11th pillar: Business dynamism": 28.59,
        "12th pillar: Innovation capability": 30.89
    },
    {
        "Country": "Viet Nam",
        "Alpha2": "VN",
        "Alpha3": "VNM",
        "Region": "East Asia and the Pacific",
        "1st pillar: Institutions": 49.82,
        "2nd pillar: Infrastructure": 65.92,
        "3rd pillar: ICT adoption": 69.03,
        "4th pillar: Macroeconomic stability": 75,
        "5th pillar: Health": 80.55,
        "6th pillar: Skills": 56.96,
        "7th pillar: Product market": 53.99,
        "8th pillar: Labour market": 58.24,
        "9th pillar: Financial system": 63.86,
        "10th pillar: Market size": 71.84,
        "11th pillar: Business dynamism": 56.5,
        "12th pillar: Innovation capability": 36.8
    },
    {
        "Country": "Yemen",
        "Alpha2": "YE",
        "Alpha3": "YEM",
        "Region": "Middle East and North Africa",
        "1st pillar: Institutions": 29.02,
        "2nd pillar: Infrastructure": 33.85,
        "3rd pillar: ICT adoption": 17.63,
        "4th pillar: Macroeconomic stability": 34.42,
        "5th pillar: Health": 52.27,
        "6th pillar: Skills": 35.62,
        "7th pillar: Product market": 46.09,
        "8th pillar: Labour market": 40.9,
        "9th pillar: Financial system": 29.03,
        "10th pillar: Market size": 44.52,
        "11th pillar: Business dynamism": 37.38,
        "12th pillar: Innovation capability": 25.3
    },
    {
        "Country": "Zambia",
        "Alpha2": "ZM",
        "Alpha3": "ZMB",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 45.17,
        "2nd pillar: Infrastructure": 43.27,
        "3rd pillar: ICT adoption": 34.23,
        "4th pillar: Macroeconomic stability": 64.06,
        "5th pillar: Health": 47.28,
        "6th pillar: Skills": 47.63,
        "7th pillar: Product market": 48.58,
        "8th pillar: Labour market": 49.73,
        "9th pillar: Financial system": 47.84,
        "10th pillar: Market size": 45.37,
        "11th pillar: Business dynamism": 56.44,
        "12th pillar: Innovation capability": 28.57
    },
    {
        "Country": "Zimbabwe",
        "Alpha2": "ZW",
        "Alpha3": "ZWE",
        "Region": "Sub Saharan Africa",
        "1st pillar: Institutions": 41.82,
        "2nd pillar: Infrastructure": 39.77,
        "3rd pillar: ICT adoption": 37.36,
        "4th pillar: Macroeconomic stability": 72.56,
        "5th pillar: Health": 41.1,
        "6th pillar: Skills": 48.83,
        "7th pillar: Product market": 38.27,
        "8th pillar: Labour market": 52.01,
        "9th pillar: Financial system": 47.98,
        "10th pillar: Market size": 38.23,
        "11th pillar: Business dynamism": 45.81,
        "12th pillar: Innovation capability": 27.17
    }
]


let pillars = [
    '1st pillar: Institutions',
    '2nd pillar: Infrastructure',
    '3rd pillar: ICT adoption',
    '4th pillar: Macroeconomic stability',
    '5th pillar: Health',
    '6th pillar: Skills',
    '7th pillar: Product market',
    '8th pillar: Labour market',
    '9th pillar: Financial system',
    '10th pillar: Market size',
    '11th pillar: Business dynamism',
    '12th pillar: Innovation capability'
]

let countries = [
    "Albania",
    "Algeria",
    "Angola",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belgium",
    "Benin",
    "Bolivia, Plurinational State of",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cape Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Congo, the Democratic Republic of the",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Estonia",
    "Ethiopia",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Guatemala",
    "Guinea",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Lithuania",
    "Luxembourg",
    "Macedonia, the former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova, Republic of",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Panama",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "Spain",
    "Sri Lanka",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Venezuela, Bolivarian Republic of",
    "Viet Nam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
]

let Alpha2 = [
    "AL",
    "DZ",
    "AO",
    "AR",
    "AM",
    "AU",
    "AT",
    "AZ",
    "BH",
    "BD",
    "BB",
    "BE",
    "BJ",
    "BO",
    "BA",
    "BW",
    "BR",
    "BN",
    "BG",
    "BF",
    "BI",
    "CV",
    "KH",
    "CM",
    "CA",
    "TD",
    "CL",
    "CN",
    "CO",
    "CD",
    "CR",
    "CI",
    "HR",
    "CY",
    "CZ",
    "DK",
    "DO",
    "EC",
    "EG",
    "SV",
    "EE",
    "ET",
    "FI",
    "FR",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GR",
    "GT",
    "GN",
    "HT",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IE",
    "IL",
    "IT",
    "JM",
    "JP",
    "JO",
    "KZ",
    "KE",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LT",
    "LU",
    "MK",
    "MG",
    "MW",
    "MY",
    "ML",
    "MT",
    "MR",
    "MU",
    "MX",
    "MD",
    "MN",
    "ME",
    "MA",
    "MZ",
    "NA",
    "NP",
    "NL",
    "NZ",
    "NI",
    "NG",
    "NO",
    "OM",
    "PK",
    "PA",
    "PY",
    "PE",
    "PH",
    "PL",
    "PT",
    "QA",
    "RO",
    "RU",
    "RW",
    "SA",
    "SN",
    "RS",
    "SC",
    "SG",
    "SK",
    "SI",
    "ZA",
    "ES",
    "LK",
    "SZ",
    "SE",
    "CH",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TT",
    "TN",
    "TR",
    "UG",
    "UA",
    "AE",
    "GB",
    "US",
    "UY",
    "VE",
    "VN",
    "YE",
    "ZM",
    "ZW"
]

let regions = [
    "East Asia and the Pacific",
    "Europe and Central Asia",
    "Latin America and the Caribbean",
    "Middle East and North Africa",
    "North America",
    "South Asia",
    "Sub Saharan Africa"
]

export { dataSet, pillars, countries, Alpha2, regions}